import React, {useReducer} from 'react';
import { useHistory } from "react-router-dom";
import gql from 'graphql-tag';
import { isMobile } from "react-device-detect";


import {
  USERS_CONNECTION_QUERY
} from '../api/homepage'

import { handleArtistClick } from '../utils'
import { colors, fontSizes} from '../styles/defaultTheme';
import ErrorPage from '../components/ErrorPage';
import Header from '../components/Header';
import RecommendedUser from '../components/RecommendedUser';
import Footer from '../components/Footer';
import Button from '../components/Button';
import Input from '../components/Input'


import WindowSizeListener from 'react-window-size-listener'
import { Helmet } from "react-helmet";
import MailchimpSubscribe from "react-mailchimp-subscribe"

import { useQuery, useMutation } from 'react-apollo-hooks';

import '../styles/App.scss'
import fullLogo from '../images/full-logo.svg'
import loadingIcon from '../images/load-disk.png';
import { quotes } from '../quotes'
import logoSingle from '../images/BT_Filled_Secondary_Logo_Black.svg'
import twitter from '../images/twitter-blk.svg'
import instagram from '../images/instagram-blk.svg'
import copyIcon from '../images/copy-icon.svg';





const reducer = (state, action) => {
  switch (action.type) {
    default:
      return { ...state, ...action.payload };
  }
};

const initialState = () => ({
  about: true,
  renderShareModal: true,
  urlCopied: false
})

const LandingPage = ({pathname, masterPassword, handleAccessRedirect, currentUser, allSkills}) => {

  const [state, dispatch] = useReducer(reducer, initialState())

  let history = useHistory();

  const url = process.env.REACT_APP_MAILCHIMP_URL;

  const SimpleForm = () => <MailchimpSubscribe url={url}/>

  return (
    <div className="default-app">
      <div className="default-app__social-wrapper social-header">
        <a rel="noreferrer" target="_blank" href="https://twitter.com/rapchr"><img className="default-app__icon" alt="twitter" src={twitter} /></a>
        <a rel="noreferrer" target="_blank" href="https://www.instagram.com/rapchr.app/"><img className="default-app__icon" alt="instagram" src={instagram} /></a>
      </div>
      <img className="default-app__logo" alt="Rapchr" src={logoSingle} />
      {isMobile && <img className="default-app__gif" alt="rapchr" src="https://res.cloudinary.com/rapchr/image/upload/v1622161596/IMG_9015_zkmc4l.gif" />}
      {!isMobile && <div className="default-app__video__wrapper">
        <video className="default-app__video" autoPlay="autoplay" loop="loop" muted>
          <source src="https://res.cloudinary.com/rapchr/video/upload/v1621250325/IMG_9015_lexnde.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>}
      {state.about &&
        <div className="default-app__about-section">
          <div className="default-app__about-header">
            A digital space <span>dedicated to rap</span>
          </div>
          <div className="default-app__about-header bottom">
            Now<span> In Beta</span>
          </div>
          <p className="default-app__about-header__beta-message">
            If you're interested in testing Rapchr, <a rel="noreferrer" target="_blank"  href="https://rapchr.typeform.com/to/FwNPMtRm">please sign up here!</a>
          </p>
          <p className="default-app__about-msg">
            Rapchr is a new social platform for hip-hop fans to explore and express their love for the art form. Discuss and debate with like-minded fans, enjoy new ways to champion your favorite artists, and chronicle your music listening journey, all in one place!
            <b> Sign up here for launch updates!</b>
          </p>
        </div>
      }
      {!state.about && <p className="default-app__message">sign up for launch updates</p>}
      <div className="default-app__subscribe-wrapper">
        <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => {
              return (
                <div>
                  <SimpleForm url={url} onSubmitted={formData => subscribe(formData)} />
                  {status === "sending" && <div style={{ color: "#c77d4a", marginBottom: "20px" }}>sending...</div>}
                  {status === "error" && <div style={{ color: "#722a1f" + 10, marginBottom: "20px" }} dangerouslySetInnerHTML={{__html: message}}/>}
                  {status === "success" && <div style={{ color: "#c77d4a", marginBottom: "20px" }}>Subscribed !</div>}
                </div>
              )
            }}
          />
      </div>
      {!state.about &&
      <div>
        <hr style={{width: "260px"}}></hr>
        <p className="default-app__access-message">or get access to our sneak peek with the password</p>
        <Input
          placeholder="Access Password"
          customClass="default-app__input"
          onChange={(e) => handleAccessRedirect(e)}
          value={masterPassword}
          inputType="password"
        />
      </div>}
      <div className="default-app__footer">
        <div className="default-app__social-wrapper social-footer">
          <a rel="noreferrer" target="_blank" href="https://twitter.com/rapchr"><img className="default-app__icon" alt="twitter" src={twitter} /></a>
          <a rel="noreferrer" target="_blank" href="https://www.instagram.com/rapchr.app/"><img className="default-app__icon" alt="instagram" src={instagram} /></a>
        </div>
        <p onClick={() => dispatch({payload: {about: !state.about}})} className="default-app__about-link default-app__footer-link">{state.about ? "Get Access" : "About"}</p>
        <a className="default-app__contact default-app__footer-link" href="mailto:support@rapchr.app">Contact Us</a>
      </div>
    </div>
  )

}


export default LandingPage
