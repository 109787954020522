import React, { useState } from 'react';
import gql from 'graphql-tag'
import { useMutation } from 'react-apollo-hooks';

import Input from './Input'
import Icon from './Icon/Icon'
import Button from './Button'

import copyIcon from '../images/copy-icon.svg';

import { isMobile } from "react-device-detect";
import { useAlert } from 'react-alert'


const handleClose = (closeModal, setName, setMessage, setEmail, setSubmitted) => {
  closeModal()
  setName("")
  setMessage("")
  setEmail("")
  setSubmitted(false)
}

const handleSubmit = (contactEmailMutation, name, email, message, setSubmitted, closeModal, alert) => {
  if(message.length < 1) {
    alert.show("Please add a message.")
  } else {
    contactEmailMutation({
      variables: {
        name,
        email,
        message
      },
    })
    .then(function (res) {
      setSubmitted(true)
    })
    .catch(function (error) {
      alert.show("Error: Please reload and try again!")
    });
  }
}

const renderSubmitMsg = () => {
  return (
    <div className="contact-modal__submit-msg__wrapper">
      <p className="contact-modal__submit-msg__text">Your Message Was Submitted!</p>
    </div>
  )
}

const ContactModal = ({
  currentUser,
  state,
  dispatch,
  closeModal
}) => {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const alert = useAlert();

  const [contactEmailMutation] = useMutation(CONTACT_EMAIL_MUTATION);


  return (
    <div className="contact-modal__bg">
      <div className={`contact-modal__wrapper`}>
        <Icon
          customClass="contact-modal__close-icon"
          color="#f1e3ce"
          width="20px"
          height="20px"
          type="close"
          style={{opacity: isMobile && "1"}}
          handleclick={() => handleClose(closeModal, setName, setMessage, setEmail, setSubmitted)}
        />
        {submitted && renderSubmitMsg()}
        {!submitted && <div>
          <h3 className="contact-modal__title">Contact Us</h3>
          <p className="contact-modal__text">Want to get in touch or get involved?</p>
          <Input
            placeholder="name"
            customClass="contact-modal__input"
            wrapperCustomeClass="contact-modal__input-wrapper"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            placeholder="email"
            customClass="contact-modal__input"
            wrapperCustomeClass="contact-modal__input-wrapper"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
            <textarea
              className="contact-modal__text-area"
              rows="6"
              placeholder="Message..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            >
            </textarea>
            <Button
              customClass={`contact-modal__submit-btn`}
              handleclick={() => handleSubmit(contactEmailMutation, name, email, message, setSubmitted, closeModal, alert)}
            >
              Submit
            </Button>
        </div>}
        <div className="contact-modal__ctas">
          <a className="contact-modal__cta-link" href="mailto:contact@rapchr.app">contact@rapchr.app</a>
          <a href="https://t7slfuzmqd8.typeform.com/to/QxUSW7j4" target="_blank" className="contact-modal__cta-link survery-link">survey</a>
        </div>
      </div>
      {isMobile && <div className="bump"></div>}
    </div>
  )
}

const CONTACT_EMAIL_MUTATION = gql`
  mutation sendContactEmail($email: String, $name: String, $message: String!){
    sendContactEmail(email: $email, name: $name, message: $message)
  }
`


export default ContactModal;
