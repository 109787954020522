import React from 'react';

const MenuIcon = ({
  height,
  width,
  color,
  style,
  handleclick,
  ...rest
}) => (
  <svg { ...rest } onClick={handleclick} style={style} width={width} height={height} viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width={width} height="2" rx="1" fill={color}/>
    <rect y="9" width={width} height="2" rx="1" fill={color}/>
    <rect y="18" width={width} height="2" rx="1" fill={color}/>
  </svg>
);

export default MenuIcon;
