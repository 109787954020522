import React, { createRef, useCallback, useState, useEffect } from 'react'
import { useMutation, useQuery } from 'react-apollo-hooks';
import ReactGA from 'react-ga';

import Button from '../components/Button';
import Icon from '../components/Icon/Icon';
import useLongPress from "../components/useLongPress";

// import fullLogo from '../images/full-logo.svg'
import loadingIcon from '../images/load-disk.png';
import { colors } from '../styles/defaultTheme';
import { formatUserName, productionConsoleCheck } from '../utils'

import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon
} from "react-share";
import { useScreenshot } from 'use-react-screenshot'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Switch from "react-switch";
import useOnclickOutside from "react-cool-onclickoutside";
import { toPng, toJpeg, toBlob } from 'html-to-image';
import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from 'react-component-export-image';
import axios from 'axios';


import {
  USER_RATING_QUERY,
  UPDATE_USER_SHARES_MUTATION
} from '../api/singleArtist'

import {
  userUpdateShares
} from '../mutations/singleArtist'


const abbreviateNumber = (num, fixed) => {
  if (num === null) { return null; } // terminate early
  if (num === 0) { return '0'; } // terminate early
  fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show
  var b = (num).toPrecision(2).split("e"), // get power
      k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
      c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3) ).toFixed(1 + fixed), // divide by power
      d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
      e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power
  return e;
}

async function toDataURL(url) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function() {
    var reader = new FileReader();
    reader.onloadend = async function() {
       return await reader.result
    }
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
}

const renderRatings = (ratings, groupName, isMobile) => {
  return (
    <div className={`rating-graphic__rating-group ${groupName}`}>
      {ratings.map((rating, index) => {

        if(groupName !== "top-ratings") {
          return (
            <div key={index} className={`rating-graphic__rating-wrapper ${groupName}`}>
              <p className={`rating-graphic__skill-name`}>{rating.skillName}</p>
              <p className="rating-graphic__rating">{rating.rating}</p>
            </div>
          )
        }

        return (
          <div key={index} className={`rating-graphic__rating-wrapper ${groupName}`}>
            <p className={`rating-graphic__skill-name`}>{rating.skillName}</p>
            <p className={`rating-graphic__rating`}>{rating.rating}</p>
              <CircularProgressbar
                style={{fontFamily: "Comfortaa", width: "4vw"}}
                value={Math.round(rating.rating * 10)}
                strokeWidth={5}
                counterClockwise
              />
            </div>
        )
      })}
    </div>
  )
}


const renderUserInfo = (artist, currentUserRatings, isMobile, logo) => {
  const sortedUserRatings = currentUserRatings.ratings.sort((function(a, b) { return b.rating - a.rating;}))

  const topRatings = sortedUserRatings.slice(0, 3)
  const mediumRatings = sortedUserRatings.slice(3, 6)
  const bottomRatings = sortedUserRatings.slice(6, 10)

  return (
    <div className="rating-graphic__user-info">
      <ul className={`rating-graphic__header`}>
        <li className={`rating-graphic__header-item`}>
          <h3 className="rating-graphic__user-name">My</h3>
        </li>
        <li className={`rating-graphic__header-item`}>
          <h2 className={`rating-graphic__artist-name${artist.name.length >= 15 || (artist.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") !== artist.name) ? " rating-graphic__large-name" : ""}${artist.name.length >= 20 || (artist.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") !== artist.name) ? " rating-graphic__xlarge-name" : ""}`}>{artist.name}</h2>
        </li>
        <li className={`rating-graphic__title-section rating-graphic__header-item`}>
          <h3 className={`rating-graphic__text hidden`}>Rapchr</h3>
          <img className={`rating-graphic__logo`} alt="Rapchr" src={logo} />
          <h3 className="rating-graphic__text">Ratings</h3>
        </li>
      </ul>
      <div className={`rating-graphic__ratings`}>
        {renderRatings(topRatings, "top-ratings", isMobile)}
        {renderRatings(mediumRatings, "medium-ratings", isMobile)}
        {renderRatings(bottomRatings, "bottom-ratings", isMobile)}
      </div>
    </div>
  )
}

const dataURItoBlob = (dataURI) => {
  var mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
  var binary = atob(dataURI.split(',')[1]);
  var array = [];
  for (var i = 0; i < binary.length; i++) {
     array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], {type: mime});
}

const handleGraphicClose = (artistSlug, dispatch, history, ratingGroupId) => {
  dispatch({payload: {renderRatingGraphic: false}})
  if(!ratingGroupId) {
    history.push(`/rate/rapper/${artistSlug}`)
  }
}

const handleNodeGrab = (setGraphicNode) => {
  const graphic = document.getElementById('divToPrint')
  setGraphicNode(graphic)
}

const handleGraphicClick = (isMobile, graphicAction) => {
  if(isMobile) {
    graphicAction(true)
  }
}

const GraphicImage = ({
  artist,
  currentUserRatings,
  userUpdateMutation,
  currentUser,
  artistName,
  isMobile,
  artistImage,
  logo,
}) => {

  const ref = createRef(null)
  const imageRef = createRef(null)

  const graphic = document.getElementById('divToPrint')

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 100,
  };

  return (
    <div
      ref={ref}
      id="divToPrint"
      className={`rating-graphic__container${isMobile ? " graphic-mobile" : ""}`}
    >
      {renderUserInfo(artist, currentUserRatings, isMobile, logo)}
      <div  className="rating-graphic__artist-image__wrapper">
        <div
          style={{backgroundImage: `url(${artistImage ? artistImage : artist.image})`}}
          className="rating-graphic__artist-image"
          id="background"
        >
        </div>
        <div className="rating-graphic__bg__filled"></div>
        <div className="rating-graphic__bg"></div>
      </div>
    </div>
  )
}


export default GraphicImage
