import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import ContactModal from './ContactModal';


class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = { showModal: false};
  }

  render() {

    let sBrowser, sUsrAg = navigator.userAgent;

    return (
      <div style={this.props.style} className={`footer__wrapper ${this.props.customClass ? this.props.customClass : ""}`}>
        {this.state.showModal &&
          <ContactModal
            showMsg={() => this.setState({showSubmitMsg: true})}
            closeModal={() => this.setState({showModal: false})}
          />
        }
        <div className="footer__ctas">
          <a onClick={() => this.setState({showModal: !this.state.showModal})} className="footer__cta left-cta-footer">Contact Us</a>
          <Link to="/privacy-policy" className="footer__cta left-cta-footer" >Privacy Policy</Link>
          <Link to="/terms-of-use" className="footer__cta left-cta-footer" >Terms of Use</Link>
          {(sUsrAg.indexOf("Chrome") > -1) && <a target="_blank" href="https://chrome.google.com/webstore/detail/rapchr-rating-extension/pgemoonbplcgidchpkonfnoplofnilai" className="footer__cta left-cta-footer" >Chrome Extension</a>}
        </div>
      </div>
    )
  }
}

export default withRouter(Footer)
