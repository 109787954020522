import React from 'react';

const UpArrow = ({
  height,
  width,
  color,
  style,
  handleclick,
  ...rest
}) => (
  <svg onClick={handleclick} style={style} width={width ? width : "15"} height={height ? height : "15"} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M7.21716 0.282842C7.37337 0.126632 7.62663 0.126633 7.78284 0.282842L14.3172 6.81716C14.5691 7.06914 14.3907 7.5 14.0343 7.5L11.65 7.5C11.4291 7.5 11.25 7.67909 11.25 7.9L11.25 14.6C11.25 14.8209 11.0709 15 10.85 15L4.15 15C3.92909 15 3.75 14.8209 3.75 14.6L3.75 7.9C3.75 7.67909 3.57091 7.5 3.35 7.5L0.965685 7.5C0.609323 7.5 0.430856 7.06914 0.682842 6.81716L7.21716 0.282842Z" fill={color ? color : "url(#paint0_linear)"}/>
    <defs>
    <linearGradient id="paint0_linear" x1="7.38461" y1="-2.58589e-07" x2="7.38461" y2="15" gradientUnits="userSpaceOnUse">
    <stop stopColor="#F89C56"/>
    <stop offset="1" stopColor="#F8914C"/>
    </linearGradient>
    </defs>
  </svg>
);

export default UpArrow;
