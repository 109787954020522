import React from "react";

import Input from "./Input"

import usePlacesAutocomplete from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";

const LocationAutocomplete = ({
  onLocationSelect,
  state,
  dispatch
}) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ["geocode"]
    },
    debounce: 300,
  });

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
    dispatch({payload: {location: e.target.value}})
  };

  const handleSelect = ({ description }) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter as "false"
    setValue(description, false);
    onLocationSelect(description)
    clearSuggestions();

  };

  const renderSuggestions = () =>
    data.map((suggestion, index) => {
      const {
        id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li className="onboarding__location__city" key={index} onClick={handleSelect(suggestion)}>
          {suggestion.description}
        </li>
      );
    });

  return (
    <div className="onboarding__location__wrapper" ref={ref}>
      <Input
        placeholder="location"
        icon="location"
        iconClass="onboarding__icon"
        customClass="onboarding__input form-control my-2"
        iconStyle={{position: "relative", top: "3px", right: "1px"}}
        wrapperCustomeClass={`onboarding__input-wrapper ${status === "OK" && "suggestions"}`}
        onChange={handleInput}
        value={state.location}
        disabled={!ready}
      />
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === "OK" && <ul className="onboarding__location__suggestions">{renderSuggestions()}</ul>}
    </div>
  );
};

export default LocationAutocomplete;
