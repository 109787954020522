import React from 'react';

const BackIcon = ({
  height,
  width,
  color,
  style,
  handleclick,
  opacity,
  ...rest
}) => (
  <svg viewBox="0 0 56 56" onClick={handleclick} style={style} width={width} height={height} { ...rest } xmlns="http://www.w3.org/2000/svg">
   <g>
    <rect fill="none" id="canvas_background" height="3.202003" width="3.202003" y="-1" x="-1"/>
   </g>
   <g>
    <path fill={color} id="svg_3" d="m24,47.759l-0.823,-1.191c-0.619,-0.897 -15.177,-22.069 -15.177,-30.568c0,-8.822 7.178,-16 16,-16s16,7.178 16,16c0,8.499 -14.558,29.671 -15.177,30.568l-0.823,1.191zm0,-45.759c-7.72,0 -14,6.28 -14,14c0,6.787 10.885,23.555 14,28.214c3.115,-4.659 14,-21.427 14,-28.214c0,-7.72 -6.28,-14 -14,-14z"/>
    <path fill={color} id="svg_5" d="m24,23c-3.859,0 -7,-3.14 -7,-7s3.141,-7 7,-7s7,3.14 7,7s-3.141,7 -7,7zm0,-12c-2.757,0 -5,2.243 -5,5s2.243,5 5,5s5,-2.243 5,-5s-2.243,-5 -5,-5z"/>
   </g>
  </svg>
);

export default BackIcon;
