import React, { useEffect } from 'react';
import { useQuery, useMutation } from 'react-apollo-hooks';
import gql from 'graphql-tag'
import { useAlert } from 'react-alert'
import { isMobile } from "react-device-detect";
import styled, { keyframes } from 'styled-components'

import Slider, { Handle } from 'rc-slider';
import Icon from '../components/Icon/Icon';
import Button from '../components/Button';
import Tooltip from '../components/Tooltip';
import RatingGraphic from '../components/RatingGraphic';
import ErrorPage from '../components/ErrorPage'
import UserRatingList from '../components/UserRatingList'

import sliderArrows from '../images/slider-arrows.svg'

import {
  VOTE_MUTATION,
  RATE_ARTIST_MUTATION,
  SINGLE_ARTIST_QUERY,
  UPDATE_RATING_MUTATION,
  VOTE_TOGGLE_MUTATION,
  USER_RATING_QUERY
} from '../api/singleArtist'

import {
  updateCacheAfterVote,
  updateCacheAfterRating
} from '../cache/singleArtist'

import {
  submitArtistRating,
  submitRatingUpdate,
  submitVote,
  updateVoteToggle
} from '../mutations/singleArtist'

import 'rc-slider/assets/index.css';

import { colors } from '../styles/defaultTheme';
import { handleModalClose, handleExcludes } from '../utils';

import deceasedIcon from '../images/rip.svg';
import loadingIcon from '../images/load-disk.png';
import shareIcon from '../images/share-icon.svg';

const widthSlide = (score) => keyframes`
  from {
    width: 0;
  }

  to {
    width: ${score + "%"};
  }
`

const Bar = styled.div`
  animation: ${props => widthSlide(props.score)} ease-in-out;
  animation-duration: 1s;
  width: ${props => props.score ? props.score + "%" : "0"};
`

const formatSkillAverage = (skillAverage, artist) => {
  if(artist.hideRatings) {
    return 100
  } else if((skillAverage.average && skillAverage.average === 0) || (skillAverage.rating && skillAverage.rating === 0)) {
    return "N/R"
  } else if(skillAverage.average) {
    return Math.round(skillAverage.average * 10)
  } else if(skillAverage.rating) {
    return Math.round(skillAverage.rating * 10)
  } else {
    return "N/R"
  }
}

const formatSkillRating = (value, skillAverage, state, dispatch) => {
  let userArtistRatings = state.userArtistRatings;
  let formattedRating = {
    skillId: skillAverage.skill.id,
    skillName: skillAverage.skill.name,
    rating: value
  }
  const foundRating = userArtistRatings.filter(ratingObj => ratingObj.skillId === skillAverage.skill.id);
  const foundRatingIndex = userArtistRatings.findIndex(({ skillId }) => skillId === skillAverage.skill.id);

  if(foundRatingIndex < 0) {
    userArtistRatings.push(formattedRating)
    dispatch({payload: {userArtistRatings: userArtistRatings}})
  } else {
    userArtistRatings[foundRatingIndex] = formattedRating;
    dispatch({payload: {userArtistRatings: userArtistRatings}})
  }

}

const formatLiveRating = (state, skillAverage, userSkillRating) => {
  const currentSkill = state.userArtistRatings.filter(skill => skill.skillId === skillAverage.skill.id)

  if(currentSkill[0] && currentSkill[0].rating > 0) {
    return currentSkill[0].rating
  } else if(userSkillRating) {
    return userSkillRating
  } else return 0
  // return currentSkill[0] ? currentSkill[0].rating : 0
}

const renderSingleArtistRatings = (artist, state, dispatch, allSkills, profile, homepage, userRatings, currentUser) => {
  let sortedArtistSkillAverages = artist.skillAverages.sort((function(a, b) { return b.average - a.average;}))

  if(artist.currentUserRatings) {
    return sortedArtistSkillAverages.map((skillAverage, index) => {
      const userSkillRating = artist.currentUserRatings.ratings.filter(skill => skill.skillId === skillAverage.skill.id)
      return (
        <div key={index} className={`skill-rating__wrapper__with-user${state.rateArtistView ? " rating-view" : ""}`}>
          <div className="skill-rating__info-wrapper__with-user">
            <p className="skill-rating__skill-name__with-user">
              {skillAverage.skill.name}
              {state.rateArtistView && currentUser &&
                <Tooltip parentDispatch={dispatch} allSkills={allSkills} customClass="rating" skill={skillAverage.skill} smallScreensize={state.smallScreensize} />
              }
            </p>
            {state.rateArtistView &&
              <p className="skill-rating__skill-average">{formatLiveRating(state, skillAverage, userSkillRating[0].rating)}</p>
            }
            {!state.rateArtistView &&
              <div className="skill-rating__skill-average-wrapper__with-user">
                <p className="skill-rating__skill-average__with-user">{formatSkillAverage(skillAverage, artist)}</p>
                <p className="skill-rating__skill-average__with-user user-rating">{userSkillRating[0] ? userSkillRating[0].rating : "N/R"}</p>
              </div>
            }
          </div>
          {!state.rateArtistView && <div className="skill-rating__score-wrapper__with-user">
            <Bar
              score={artist.hideRatings ? 100 : skillAverage.average * 10}
              className={`skill-rating__score__with-user${index > 2 && !artist.hideRatings ? " bottom" : ""}`}
            ></Bar>
            <Bar
              className="skill-rating__score__with-user user-rating"
              score={userSkillRating[0] ? userSkillRating[0].rating * 10 : 0}
              ></Bar>
          </div>}
          {state.rateArtistView &&
            <Slider
              min={0}
              max={10}
              key={index}
              step={.5}
              defaultValue={userSkillRating[0].rating}
              onChange={(value) => formatSkillRating(value, skillAverage, state, dispatch)}
              handle={ (handleProps) => {
                const { dragging, ...restProps } = handleProps;
                return (
                  <Handle dragging={dragging.toString()} key={index} { ...restProps }>
                    <img className="rc-slider-slider-arrows" src={sliderArrows} />
                  </Handle>
                )
              }}
            />
          }
        </div>
      )
    })
  } else if(profile && userRatings) {
    return sortedArtistSkillAverages.map((skillAverage, index) => {
      const userSkillRating = userRatings.filter(skill => skill.skillId === skillAverage.skill.id)
      return (
        <div key={index} className={`skill-rating__wrapper__with-user${state.rateArtistView ? " rating-view" : ""}`}>
          <div className="skill-rating__info-wrapper__with-user">
            <p className="skill-rating__skill-name__with-user">
              {skillAverage.skill.name}
            </p>
            {!state.rateArtistView &&
              <div className="skill-rating__skill-average-wrapper__with-user">
                <p className="skill-rating__skill-average__with-user">{formatSkillAverage(skillAverage, artist)}</p>
                <p className="skill-rating__skill-average__with-user user-rating">{userSkillRating[0] ? userSkillRating[0].rating : "N/R"}</p>
              </div>
            }
          </div>
          {!state.rateArtistView && <div className="skill-rating__score-wrapper__with-user">
            <Bar
              score={artist.hideRatings ? 100 : skillAverage.average * 10}
              className={`skill-rating__score__with-user${index > 2 && !artist.hideRatings ? " bottom" : ""}`}
            ></Bar>
            <Bar
              className="skill-rating__score__with-user user-rating"
              score={userSkillRating[0] ? userSkillRating[0].rating * 10 : 0}
              ></Bar>
          </div>}
        </div>
      )
    })
  } else {
    return sortedArtistSkillAverages.map((skillAverage, index) => {
      const userSkillRating = state.userArtistRatings.filter(skill => skill.skillId === skillAverage.skill.id)
      return (
        <div key={index} className={`skill-rating__wrapper`}>
          <div className="skill-rating__info-wrapper">
            <p className="skill-rating__skill-name">{skillAverage.skill.name}
            {state.rateArtistView && currentUser &&
              <Tooltip parentDispatch={dispatch} allSkills={allSkills} customClass="rating" skill={skillAverage.skill} smallScreensize={state.smallScreensize} />
            }
            </p>
            {!state.rateArtistView && <p className="skill-rating__skill-average">{formatSkillAverage(skillAverage, artist)}</p>}
            {state.rateArtistView &&
              <p className="skill-rating__skill-average">{formatLiveRating(state, skillAverage)}</p>
            }
          </div>
          {!state.rateArtistView && <div className="skill-rating__score-wrapper">
            <Bar score={artist.hideRatings ? 100 : skillAverage.average * 10} className={`skill-rating__score${index > 2 && !artist.hideRatings ? " bottom" : ""}`}
            // style={{width: `${artist.hideRatings ? 100 : skillAverage.average * 10}%`}}
            ></Bar>
          </div>}
          {state.rateArtistView &&
            <Slider
              min={0}
              max={10}
              step={.5}
              defaultValue={userSkillRating[0] ? userSkillRating[0].rating :0}
              onChange={(value) => formatSkillRating(value, skillAverage, state, dispatch)}
              handle={ (handleProps) => {
                return (
                  <Handle { ...handleProps }>
                    <img className="rc-slider-slider-arrows" src={sliderArrows} />
                  </Handle>
                )
              }}
            />
          }
        </div>
      )
    })
  }
}

const renderArtistImage = (artist, loading) => {

  return (
    <div className="rating-artist__image-wrapper">
      <div
        style={{backgroundImage: `url(${artist.scaledImage ? artist.scaledImage : artist.image})`}}
        className={`rating-artist__image${!artist.image ? " default-profile" : ""}${loading ? " loading-profile" : ""}`}
      >
      </div>
      <div className="rating-artist__name">
        {artist.name}
        {artist.deceased && <img className="rating-artist__deceased-icon" src={deceasedIcon} alt="rip" />}
      </div>
    </div>
  )
}

const renderButtons = (
  artist,
  state,
  dispatch,
  history,
  profile,
  homepage,
  currentMutation,
  artistSlug,
  currentUser,
  alert,
  formatMutationSkillAverages,
  updateCacheAfterRating,
  ratingMutation,
  openSignup,
  userRatings
) => {

  return (
    <div className="rating-artist__cta-wrapper">
      {homepage && <Button
        handleclick={history.location.pathname.includes("rapper") ? () => history.push('/') : () => openSignup()}
        customClass="rating-artist__rate-btn"
        gradient={true}
      >
        Sign Up To Rate & Explore
      </Button>}
      {profile && <Button
        handleclick={() => history.push({pathname: `/rate/rapper/${artist.slug}`, state: { from: profile, data: artist.currentUserRatings && artist.currentUserRatings.ratings }})}
        customClass="rating-artist__rate-btn"
        gradient={true}
      >
        {artist.currentUserRatings ? "Update Ratings" : "Rate Rapper"}
      </Button>}
      {!profile && !homepage && <Button
        handleclick={ state.rateArtistView ? () => ratingMutation(currentMutation, artist, artistSlug, currentUser, state, dispatch, alert, formatMutationSkillAverages, updateCacheAfterRating, history)
          : () => handleRateViewSwitch(artist, state, dispatch)}
        customClass="rating-artist__rate-btn"
        disabled={state.loadingBarProgress > 0 || (state.rateArtistView && (state.userArtistRatings.length < 10 && !artist.currentUserRatings))}
        gradient={!state.rateArtistView || (state.rateArtistView && (state.userArtistRatings.length === 10 || (artist.currentUserRatings && artist.currentUserRatings.ratings && artist.currentUserRatings.ratings.length === 10)))}
        style={{width: artist.hideRatings && !state.rateArtistView && "90%", margin: artist.hideRatings && !state.rateArtistView && "0 auto"}}
      >
        { artist.currentUserRatings ? "Update Rating" : "Rate Rapper" }
      </Button>}
      {(!artist.hideRatings || (artist.hideRatings && state.rateArtistView)) && <Button
        handleclick={() => state.rateArtistView ? dispatch({payload: {rateArtistView: false}}) : dispatch({payload: {compareSearch: true, originalArtist: artist}})}
        customClass="rating-artist__compare-btn"
        disabled={state.loadingBarProgress > 0}
      >
        {state.rateArtistView || artist.hideRatings ? "Back" :  "Compare"}
      </Button>}
    </div>
  )
}

const handleGraphicClick = (artistSlug, dispatch, currentUser, history, artist) => {
  dispatch({payload: {renderRatingGraphic: true}})
  const slug = artist && artist.slug ? artist.slug : artistSlug
  if(window.location.pathname.includes("user")) {
    history.push(`/user/${currentUser.id}/graphic/${slug}/${artist.currentUserRatings.id}`)
  } else {
    history.push(`/rate/graphic/${currentUser.id}/${slug}`)
  }
}

const renderRatingLabels = (state, dispatch, currentUser, artist, history, artistSlug, profile, homepage) => {
  const artistRatingLabel = artist.currentUserRatings ? "Community" : "Top 3 Skill"
  const ratingCount = artist && artist.skillAverages && artist.skillAverages[0] && artist.skillAverages[0].ratingCount ? artist.skillAverages[0].ratingCount : artist.ratedCount

  return (
    <div className="rating-artist__label-wrapper">
        <div className="rating-artist__label-container">
          {currentUser && <Tooltip howItWorks={true} customClass={`hiw${state.compareArtist && state.compareArtist.id ? " compare" : ""}`} smallScreensize={state.smallScreensize} />}
          {!artist.currentUserRatings && artist.ratedCount < 1 && !artist.hideRatings && <p className="rating-artist__label">
            <span className="rating-artist__label-color community">
            </span>
            No Community Ratings Yet
          </p>}
          {!artist.currentUserRatings && (artist.ratedCount > 0 || artist.hideRatings) && <p onClick={() => dispatch({payload: {userListModal: true}})} className="rating-artist__label ratings-label">
            Community Ratings {!homepage && <span className="rating-artist__rated-count">{`(${ratingCount})`}</span>}
          </p>}
          {artist.currentUserRatings && <p onClick={() => dispatch({payload: {userListModal: true}})} className="rating-artist__label  ratings-label">
            <span className="rating-artist__label-color">
            </span>
            {artistRatingLabel} {!homepage && <span className="rating-artist__rated-count">{`(${ratingCount})`}</span>}
          </p>}
          {artist.currentUserRatings && <p className="rating-artist__label">
            <span className="rating-artist__label-color community">
            </span>
            {profile ? "Your Current Ratings" : "Your Ratings"}
          </p>}
        </div>
        {artist.currentUserRatings && !profile &&
          <div onClick={() => handleGraphicClick(artistSlug, dispatch, currentUser, history, artist)} className="rating-artist__share-wrapper">
            <img className="rating-artist__share-icon" alt="share" src={shareIcon} />
            <p className="rating-artist__share-text">Share Rating</p>
        </div>}
    </div>
  )
}

const renderUserList = (state, dispatch, artist, history, artistSlug, profile) => {
  if(state.userListModal) {
    return <UserRatingList profile={profile} history={history} artistSlug={artistSlug} artist={artist} dispatch={dispatch} state={state} />
  }
}

const formatMutationSkillAverages = (skillAverages) => {
  let formattedAverages = []
  skillAverages.map((skillAverage) => {
    formattedAverages.push({
      id: skillAverage.id,
      skillId: skillAverage.skill.id,
    })
  })
  return formattedAverages
}

const handleRateViewSwitch = (artist, state, dispatch) => {
  if(artist.currentUserRatings) {
    const currentUserRatings = artist.currentUserRatings
    let sortedArtistSkillAverages = artist.skillAverages.sort((function(a, b) { return b.average - a.average;}))

    sortedArtistSkillAverages.map((skillAverage, index) => {
      const userSkillRating = artist.currentUserRatings.ratings.filter(skill => skill.skillId === skillAverage.skill.id)
      formatSkillRating(userSkillRating[0].rating, skillAverage, state, dispatch)
    })
  }
  dispatch({payload: {rateArtistView: true}})
}


const SingleArtist = ({
  state,
  dispatch,
  allSkills,
  artistSlug,
  artistId,
  userId,
  history,
  currentUser,
  sendData,
  profile,
  homepage,
  openSignup,
  appState,
  userRatings,
  executeScroll,
  postPage
}) => {
  const [rateArtistMutation] = useMutation(RATE_ARTIST_MUTATION)
  const [updateRatingMutation] = useMutation(UPDATE_RATING_MUTATION)
  const [createVoteMutation] = useMutation(VOTE_MUTATION)
  const [voteToggleMutation] = useMutation(VOTE_TOGGLE_MUTATION)

  const alert = useAlert();

  const {
    data: artistData,
    loading: artistLoading,
    error: artistError
  } = useQuery(SINGLE_ARTIST_QUERY, {
   variables: { artistId: state.originalArtist.id && state.originalArtist.id, slug: artistSlug && artistSlug }
  });

  useEffect(() => {
    if(!state.originalArtist.id && artistData && artistData.singleArtist) {
      sendData(artistData)
    }

  }, [artistData])

  if(artistLoading && state.originalArtist.id) {
    return (
      <div className="rating-artist__bg">
        <div className="rating-artist__wrapper">
          <Icon
            customClass="rating-artist__exit-icon"
            color={isMobile ? colors.beachHouse : colors.beachHouseLight}
            type="close"
            width="24px"
            height="24px"
            style={{opacity: isMobile && "1", zIndex: "10"}}
            handleclick={() => handleModalClose(dispatch, history, userId)}
          />
          {renderArtistImage(state.originalArtist, true)}
          <div className="rating-artist__rating-wrapper">
            <div className="loading__wrapper">
              <img className="loading__icon" alt="loading" src={loadingIcon} />
            </div>
          </div>
        </div>
      </div>
    )
  } else if(artistLoading) {
    return (
      <div className="rating-artist__bg">
        <div className="rating-artist__wrapper">
          <Icon
            customClass="rating-artist__exit-icon"
            color={isMobile ? colors.beachHouse : colors.beachHouseLight}
            type="close"
            width="24px"
            height="24px"
            style={{opacity: isMobile && "1", zIndex: "10"}}
            handleclick={() => handleModalClose(dispatch, history, userId)}
          />
          <div className="loading__wrapper">
            <img className="loading__icon" alt="loading" src={loadingIcon} />
          </div>
        </div>
      </div>
    )
  }


  if((artistError || (artistData && !artistData.singleArtist)) && state.originalArtist.id) {
    return (
      <div className="rating-artist__bg">
        <div className="rating-artist__wrapper">
          <Icon
            customClass="rating-artist__exit-icon"
            color={isMobile ? colors.beachHouse : colors.beachHouseLight}
            type="close"
            width="24px"
            height="24px"
            style={{opacity: isMobile && "1", zIndex: "10"}}
            handleclick={() => handleModalClose(dispatch, history, userId)}
          />
          <div className="rating-artist__icon-wrapper">
            <Icon
              customClass="rating-artist__exit-icon"
              color={isMobile ? colors.beachHouse : colors.beachHouseLight}
              type="close"
              width="24px"
              height="24px"
              style={{opacity: isMobile && "1", zIndex: "10"}}
              handleclick={() => handleModalClose(dispatch, history, userId)}
            />
          </div>
          {renderArtistImage(state.originalArtist)}
          <div className="rating-artist__rating-wrapper">
            <div className="loading__wrapper">
              <p className="get-artist-msg">Error Getting Artist</p>
            </div>
          </div>
        </div>
      </div>
    )
  } else if(artistError || (artistData && !artistData.singleArtist)) {
    return (
      <div className="rating-artist__bg">
        <Icon
          customClass="rating-artist__exit-icon"
          color={isMobile ? colors.beachHouse : colors.beachHouseLight}
          type="close"
          width="24px"
          height="24px"
          style={{opacity: isMobile && "1", zIndex: "10"}}
          handleclick={() => handleModalClose(dispatch, history, userId)}
        />
        <div className="rating-artist__wrapper">
          <div className="loading__wrapper">
            <p className="get-artist-msg">Error Getting Artist</p>
          </div>
        </div>
      </div>
    )
  }

  if(artistError || (artistData && !artistData.singleArtist)) {
    return (<div className="rating-artist__bg">
      <Icon
        customClass="rating-artist__exit-icon"
        color={isMobile ? colors.beachHouse : colors.beachHouseLight}
        type="close"
        width="24px"
        height="24px"
        style={{opacity: isMobile && "1", zIndex: "10"}}
        handleclick={() => handleModalClose(dispatch, history, userId)}
      />
      <div className="rating-artist__wrapper">
        <div className="loading__wrapper">
          <p className="get-artist-msg">Error Getting Artist</p>
        </div>
      </div>
    </div>)
  }

  const artist = artistData.singleArtist || state.originalArtist
  const ratingMutation = artist && artist.currentUserRatings ? submitRatingUpdate : submitArtistRating;
  const currentMutation = artist.currentUserRatings ? updateRatingMutation : rateArtistMutation

  return (
    <div className="rating-artist__bg">
      {state.renderRatingGraphic &&
        <RatingGraphic
           state={state}
           userId={userId}
           dispatch={dispatch}
           artist={artist}
           artistSlug={artistSlug}
           userArtistRatings={artist.currentUserRatings}
           userListChoice={state.userListChoice}
           onVoteClick={(e, ratingGroup, positive) => submitVote(e, createVoteMutation, artist, ratingGroup, artistSlug, userId, positive, state, dispatch, alert, currentUser, updateCacheAfterVote)}
           onSwitchToggle={(switchOn, setShowVotes) => updateVoteToggle(voteToggleMutation, artist, artistSlug, switchOn, state, dispatch, alert, setShowVotes)}
           history={history}
           currentUser={currentUser}
           artistName={artist.name}
           isMobile={isMobile}
           appState={appState}
           artistImage={artist.smallImage ? artist.smallImage : artist.image}
           artistId={artist.id}
           postPage={postPage}
         />
      }
      <div className="rating-artist__wrapper">
        <div className="rating-artist__icon-wrapper">
          <Icon
            customClass="rating-artist__exit-icon"
            color={isMobile ? colors.beachHouse : colors.beachHouseLight}
            type="close"
            width="24px"
            height="24px"
            style={{opacity: isMobile && "1"}}
            handleclick={() => handleModalClose(dispatch, history, userId)}
          />
        </div>
        {renderArtistImage(artist)}
        {renderUserList(state, dispatch, artist, history, artistSlug, profile)}
        {renderRatingLabels(state, dispatch, currentUser, artist, history, artistSlug, profile, homepage)}
        <div className={`rating-artist__rating-wrapper${artist.hideRatings ? " hide-ratings" : ""}${state.tooltipModal ? " modal-showing" : ""}${state.rateArtistView ? " rating-view" : ""}`}>
          {renderSingleArtistRatings(artist, state, dispatch, allSkills, profile, homepage, userRatings, currentUser)}
        </div>
        <div className="rating-artist__bottom-gradient"></div>
        {renderButtons(artist, state, dispatch, history, profile, homepage, currentMutation, artistSlug, currentUser, alert, formatMutationSkillAverages, updateCacheAfterRating, ratingMutation, openSignup)}
      </div>
    </div>
  )
}


export default SingleArtist;
