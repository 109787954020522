import React from 'react';

import Input from './Input'
import Icon from './Icon/Icon'

import copyIcon from '../images/copy-icon.svg';

import { isMobile } from "react-device-detect";
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon
} from "react-share";

const SharePrompt = ({
  currentUser,
  state,
  dispatch
}) => {

  const parentUser = currentUser && currentUser.signedUpThrough;
  const shareURL = currentUser && `https://www.joinrapchr.com/get-access/${currentUser.id}`

  return (
    <div className="share-prompt__bg">
      <div className={`share-prompt__wrapper${(!parentUser || (parentUser && currentUser.pageVisits >= 15)) ? " no-parent" : ""}`}>
        <Icon
          customClass="share-prompt__close-icon"
          color="#f1e3ce"
          width="20px"
          height="20px"
          type="close"
          style={{opacity: isMobile && "1"}}
          handleclick={() => dispatch({payload: {renderShareModal: false}})}
        />
        {(!parentUser || (parentUser && currentUser.pageVisits >= 15)) && <h3 className="share-prompt__user-title"><span className="share-prompt__highlight">Spread the word!</span></h3>}
        {parentUser && currentUser.pageVisits < 15 &&
        <div className="share-prompt__user-wrapper">
          <h3 className="share-prompt__user-title"><span className="share-prompt__highlight">Shout out</span> to</h3>
          <div className="share-prompt__user">
            <div
              style={{backgroundImage: `url(${parentUser.smallImage ? parentUser.smallImage : parentUser.image})`}}
              className="share-prompt__user-image"
            >
            </div>
            <p className="share-prompt__user-name">{parentUser.name}</p>
            {parentUser.favoriteArtists && <div className="share-prompt__user-artists">
              {parentUser.favoriteArtists.map((artist) => {
                return (
                  <div
                    style={{backgroundImage: `url(${artist.image && artist.image})`}}
                    className="share-prompt__artist-image"
                    index={artist.id}
                  >
                  </div>
                )
              })}
            </div>}
          </div>
          <p className="share-prompt__user-text">For putting you on!</p>
        </div>}
        <p className={`share-prompt__message${!parentUser ? " no-parent" : ""}`}>
          Get as many friends as possible to sign up through your
          <b> personal link</b> for a chance at winning a personalized package!
        </p>
        <Input
          placeholder="Personal Link"
          customClass="share-prompt__input"
          wrapperCustomeClass="share-prompt__input-wrapper"
          value={`https://www.joinrapchr.com/get-access/${currentUser.id}`}
        />
        <div className="share-prompt__cta-wrapper">
          <FacebookShareButton
            url={shareURL}
            quote={`Join the growing Rapchr community! #rapchr`}
            imageUrl={currentUser.image}
          >
            <FacebookIcon size={34}
              round />
          </FacebookShareButton >
          <TwitterShareButton
            url={shareURL}
            title={`Join the growing Rapchr community! @rapchr`}
            imageUrl={currentUser.image}
          >
            <TwitterIcon size={32}
              round />
          </TwitterShareButton >
          <div className="share-prompt__copy-icon__wrapper">
            <img
              className={`share-prompt__copy-icon__image${state.urlCopied ? " copied" : ""}`}
              alt="copy graphic link"
              src={copyIcon}
              onClick={() => {dispatch({payload: {urlCopied: true}});navigator.clipboard.writeText(`https://www.joinrapchr.com/get-access/${currentUser.id}`)}}
            />
          </div>
        </div>
        <a href="https://t7slfuzmqd8.typeform.com/to/QxUSW7j4" target="_blank" className="share-prompt__cta-link survery-link">survey</a>
      </div>
      {isMobile && <div className="bump"></div>}
    </div>
  )
}


export default SharePrompt;
