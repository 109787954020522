import React, {useState} from 'react';

import Button from './Button'
import Icon from './Icon/Icon'
import Tooltip from './Tooltip'

import gradientElipse from '../images/gradient-elipse.svg';
import dragIcon from '../images/drag-icon.svg';
import mobileSkillStepMessage from '../images/other-users-skills-mobile.svg';
import mobileFavArtistStepMessage from '../images/other-users-artists-mobile.svg';
import userMsgIconDown from '../images/carrot-down.svg';
import userMsgIconUp from '../images/carrot-up.svg';

import { colors } from '../styles/defaultTheme';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


const handleOnDragEnd = (result: object, data: object, setData: () => void) => {
  const { destination, source, draggableId } = result;

  if (!destination) return;
  if (
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  ) {
    return;
  }

  const start = data.areas[source.droppableId]
  const finish = data.areas[destination.droppableId]

  if(start !== finish) {
    const skillIds = Array.from(start.skillIds);
    skillIds.splice(source.index, 1);
    if(finish.skill && finish.skill.id) {
      skillIds.push(finish.skill.id)
    }

    const newStart = {
      ...start,
      skillIds: skillIds
    }
    const newFinish = {
      ...finish,
      skill: data.skills[draggableId]
    }
    const newState = {
      areaOrder: data.areaOrder,
      skills: data.skills,
      areas: {
        ...data.areas,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish
      }
    }
    setData(newState)
  }
}

const handleRemoveSkill = (skillChoice, area, data, setData) => {

    if(!skillChoice.name) {
      return
    }
    const newOptions = data.areas.options.skillIds;
    newOptions.push(skillChoice.id)

    const newOptionsArea = {
      id: 'options',
      skillIds: newOptions
    }

    const newChoiceArea = {
      ...area,
      skill: {}
    }

    const newState = {
      areaOrder: data.areaOrder,
      skills: data.skills,
      areas: {
        ...data.areas,
        options: newOptionsArea,
        [area.id]: newChoiceArea
      }
    }
    setData(newState)
}


const onDragUpdate = (update, setDragIsActive) => {
  const { destination } = update;
  destination && setDragIsActive(true)
}



const renderDropArea = (skillChoice, index, area, data, setData, dragStarted) => {
  return (
    <Droppable key={area.id} droppableId={area.id}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          onClick={() => handleRemoveSkill(skillChoice, area, data, setData)}
          className={`chosen-skill__wrapper${skillChoice.name ? " with-skill" : ""}${snapshot.isDraggingOver ? " active" : ""}`}
        >
          {snapshot.isDraggingOver || skillChoice.name
            && <img className="droppable-elipse" alt={skillChoice.name ? skillChoice.name : "skill space"} src={gradientElipse} />
          }
          {skillChoice.name
            ? <p className="chosen-skill__name">{skillChoice.name}</p>
            : <p className="chosen-skill__name">{index + 1}</p>
          }
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

const renderDraggableSkill= (skillOption, index, allSkills, smallScreensize, setTooltipModal) => {
  return (
    <Draggable key={skillOption.id} draggableId={skillOption.id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`skill-option__wrapper${snapshot.isDragging ? " active" : ""}`}
        >
          <img alt="drag" src={dragIcon} className="skill-option__drag-icon" />
          <p className="skill-option__name">{skillOption.name}</p>
          <Tooltip allSkills={allSkills} skill={skillOption} smallScreensize={smallScreensize} />
        </div>
      )}
     </Draggable>
  )
}

const renderMobileSkill = (skillOption, index, smallScreensize, mobileSkills, setMobileSkills, allSkills, setTooltipModal) => {

  return (
    <div
      onClick={() => !mobileSkills.includes(skillOption.id) && mobileSkills.length < 3 ? setMobileSkills(mobileSkills.concat([skillOption.id])) : setMobileSkills(mobileSkills.filter(skillId => skillId !== skillOption.id))}
      key={skillOption.id}
      className={`skill-option__wrapper${checkMobileSkill(skillOption, mobileSkills) ? " active" : ""}`}
    >
      <p className="skill-option__name">{skillOption.name}</p>
      <Tooltip setTooltipModal={setTooltipModal} handleclick={(e) => e.stopPropagation()} allSkills={allSkills} skill={skillOption} smallScreensize={smallScreensize} />
    </div>
  )
}
// <p className="skill-option__tool-tip">?</p>


const checkMobileSkill = (skillOption, mobileSkills) => {
  const skillExists = mobileSkills.filter(skill => skill === skillOption.id)
  if(skillExists.length > 0) {
    return true
  }
}

const toggleSkill = (skillOption, mobileSkills, setMobileSkills) => {
  const skillExists = mobileSkills.filter(skill => skill === skillOption.id)

  if(skillExists.length > 0) {
    const index = mobileSkills.indexOf(skillOption.id);
    if (index > -1) {
      mobileSkills.splice(index, 1);
    }
    setMobileSkills(mobileSkills)
  } else if(mobileSkills.length < 3) {
    mobileSkills.push(skillOption.id)
    setMobileSkills(mobileSkills)
  }
}


const formatChosenSkills = (data) => {
  let chosenSkills = []
  if(data.areas) {
    const dataArray = Object.keys(data.areas).map(area => data.areas[area])

    dataArray.map((area) => {
      if(area && area.skill && area.skill.id) {
        chosenSkills.push(area.skill.id)
      }
    })

    return chosenSkills
  } else if(data.skillRankings && data.skillRankings.skillRankings && data.skillRankings.skillRankings.length > 0) {
    data.skillRankings.skillRankings.map((skill) => {
      if(skill.id) {
        chosenSkills.push(skill.id)
      }
    })
    return chosenSkills
  } else return []

}

const formatSkills = (allSkills) => {
  let formattedSkills = {}
  allSkills.map((skill, index) => {
     formattedSkills[skill.id] = skill
  })
  return formattedSkills;
}

const TopSkillStep = ({
  allSkills,
  updateUserSkills,
  skipPage,
  showUsers,
  setShowUsers,
  smallScreensize,
  disableButtons,
  currentUser,
  recentUsersLoading,
  recentUsersError
}) => {

  const formattedSkills = formatSkills(allSkills)

  const initialData = {
    skills: formattedSkills,
    areas: {
      "options": {
        id: "options",
        skillIds: Object.keys(formattedSkills).map(skill => formattedSkills[skill].id)
      },
      "pick-1": {
        id: "pick-1",
        skill: {}
      },
      "pick-2": {
        id: "pick-2",
        skill: {}
      },
      "pick-3": {
        id: "pick-3",
        skill: {}
      }
    },
    areaOrder: ['pick-1', 'pick-2', 'pick-3']
  }
  const [data, setData] = useState(initialData);
  const [dragStarted, setDragStarted] = useState(false);
  const [dragIsActive, setDragIsActive] = useState(false);
  const [mobileSkills, setMobileSkills] = useState(formatChosenSkills(currentUser));
  const [tooltipModal, setTooltipModal] = useState(false);


  if(currentUser) {
    return (
      <div className="skill-step__wrapper">
        <div className="skill-step__title-section">
          <h2 className="skill-step__title">What <span className="skill-step__highlight">Skills</span> Do You <span className="skill-step__highlight"> enjoy</span> the most?</h2>
          <p className="skill-step__message">{smallScreensize ? "Pick 3 skills!" : "Drag 3 skills into the bubbles!"}</p>
        </div>
        <div>
        <DragDropContext
          onDragEnd={(result) => handleOnDragEnd(result, data, setData, setDragStarted, setDragIsActive)}
          onDragStart={() => setDragStarted(true)}
          onDragUpdate={(update) => onDragUpdate(update, setDragIsActive)}
          className="skill-step__mobile-options-wrapper"
        >
          <div className="chosen-skills__wrapper">
            {!smallScreensize && data.areaOrder.map((areaId, index) => {
                const area = data.areas[areaId]
                const skillChoice = area.skill
                return renderDropArea(skillChoice, index, area, data, setData, dragStarted)
              })}
            </div>
            <Droppable direction={smallScreensize ? "vertical" : "horizontal"} droppableId={data.areas.options.id}>
              {(provided) => (
                <div className={`skill-options__container ${tooltipModal ? " modal-showing" : ""}`}>
                  <div className="skill-options__wrapper" areaname={data.areas.skills} {...provided.droppableProps} ref={provided.innerRef}>
                    {data.areas.options.skillIds.map((skillId, index) => {
                      const skillOption = skillId === data.skills[skillId].id && data.skills[skillId]
                      if(smallScreensize) {
                        return renderMobileSkill(skillOption, index, smallScreensize, mobileSkills, setMobileSkills, allSkills, setTooltipModal)
                      } else {
                        return renderDraggableSkill(skillOption, index, allSkills, smallScreensize)
                      }
                    })}
                    {provided.placeholder}
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div className="skill-step__cta-wrapper">
            <Button gradient={formatChosenSkills(data).length === 3 || mobileSkills.length === 3} disabled={smallScreensize ? disableButtons || mobileSkills.length < 3 : disableButtons || formatChosenSkills(data).length < 3} customClass="skill-step__continue" handleclick={() => smallScreensize ? updateUserSkills(mobileSkills) : updateUserSkills(formatChosenSkills(data))}>
              Continue
            </Button>
            <Button disabled={disableButtons} handleclick={skipPage} customClass="skill-step__skip">
              Skip
            </Button>
          </div>
          <div onClick={() => setShowUsers()} className="skill-step__mobile-users__cta">
            <img src={mobileSkillStepMessage} className="skill-step__msg-mobile" alt="Other user's favorite skills" />
            <img alt={showUsers ? "close" : "open"} src={showUsers ? userMsgIconDown : userMsgIconUp} className="skill-step__msg-icon" />
          </div>
        </div>
        <div className="left-hand__bottom-gradient"></div>
      </div>
    );
  }

}

export default TopSkillStep;
