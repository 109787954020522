import React, { Component } from 'react';
import PropTypes from 'prop-types'

import AddIcon from './AddIcon';
import CancelIcon from './CancelIcon';
import EditIcon from './EditIcon';
import SearchIcon from './SearchIcon';
import NameIcon from './NameIcon';
import PasswordIcon from './PasswordIcon';
import EmailIcon from './EmailIcon';
import UserIcon from './UserIcon';
import BackIcon from './BackIcon';
import CloseIcon from './CloseIcon';
import PinIcon from './PinIcon';
import ListViewIcon from './ListViewIcon';
import GraphViewIcon from './GraphViewIcon';
import MenuIcon from './MenuIcon';
import UpArrow from './UpArrow';
import DownArrow from './DownArrow';
import LogoutIcon from './LogoutIcon';



const iconMap = {
  'add': AddIcon,
  'cancel': CancelIcon,
  'edit': EditIcon,
  'search': SearchIcon,
  'name': NameIcon,
  'password': PasswordIcon,
  'email': EmailIcon,
  'user': UserIcon,
  'back': BackIcon,
  'close': CloseIcon,
  'location': PinIcon,
  'list': ListViewIcon,
  'graph': GraphViewIcon,
  'menu': MenuIcon,
  'up-arrow': UpArrow,
  'down-arrow': DownArrow,
  'logout': LogoutIcon
}

const Icon = ({
  handleclick,
  height,
  width,
  style,
  customClass,
  onMouseEnter,
  onMouseLeave,
  insideColor,
  backgroundColor,
  type,
  color,
  opacity,
  ...rest
}) => {
  const RenderedIcon = iconMap[type];

  return (
    <RenderedIcon
      height={height}
      width={width}
      style={style}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      insidecolor={insideColor}
      backgroundcolor={backgroundColor}
      handleclick={handleclick}
      className={customClass ? `${customClass} icon` : "icon"}
      color={color}
      opacity={opacity}
      type={type}
      {...rest}
    />
  )
}


Icon.defaultProps = {
  handleclick: () => {},
  height: "32px",
  width: "32px",
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  type: "password",
  color: "#f1e3ce"
}

Icon.propTypes = {
  handleclick: PropTypes.func,
  height: PropTypes.string,
  width: PropTypes.string,
  customClass: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  insideColor: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  type: PropTypes.string
}


export default Icon;
