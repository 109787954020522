const colors = {
  beachHouse: '#f1e3ce',
  beachHouseLight: '#fff1dc',
  blackThought: '#2c201e',
  carti: '#c77d4a',
  cartiLight: '#ea9458',
  frankOcean: '#486279',
  frankOceanLight: '#5c7d9a',
  wineHouse: '#722a1f',
  bluePrint: '#181c2f',
  orangeGradient: 'linear-gradient(270deg, #F2A55F 0%, #F1863E 100%)'
};

const fonts = {
  default: 'Avenir, sans-serif',
  secondary: 'Comfortaa'
}

const fontSizes = {
  xsm: '10px',
  sm: '12px',
  md: '14px',
  default: '16px',
  lg: '18px',
  xlg: '26px',
  xxl: '30px'
}



export {
  colors,
  fonts,
  fontSizes
};
