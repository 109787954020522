import React from 'react';
import { render, hydrate } from 'react-dom'
import './styles/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloProvider } from 'react-apollo'
import { ApolloProvider as ReactApolloHooksProvider  } from 'react-apollo-hooks';
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { BrowserRouter } from 'react-router-dom'
import { setContext } from 'apollo-link-context'
import { AUTH_TOKEN } from './token'
import { Provider as AlertProvider } from 'react-alert'
import { colors, fonts, fontSizes } from './styles/defaultTheme';
import { isMobile } from "react-device-detect";
import HttpsRedirect from 'react-https-redirect';

import Icon from './components/Icon/Icon'

// const uri = process.env.REACT_APP_API_URL || "http://localhost:4000";
const uri = process.env.REACT_APP_API_URL || "http://localhost:4000";


const httpLink = createHttpLink({
  uri
})

// credentials: 'same-origin',
//   headers: {
//     cookie: req.header('Cookie'),
//   },

// Note that you also might need to make sure your GraphQL endpoint is configured to accept GraphQL operations from your SSR server (for example, by safelisting its domain or IP).

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(AUTH_TOKEN)
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const client = new ApolloClient({
  ssrMode: true,
  link: authLink.concat(httpLink),
  fetchOptions: {
    mode: 'no-cors',
  },
  cache: new InMemoryCache()
})

const options = {
  position: "top center",
  timeout: 4000,
  offset: '11px',
  transition: "fade", // or scale
  containerStyle: {
    fontFamily: fonts.default,
    background: colors.orangeGradient,
    width: "max-content",
    borderRadius: "36.6px",
    boxShadow: "0 4px 7px 0 rgba(0, 0, 0, 0.5)",
    color: colors.beachHouse,
    fontWeight: "bold",
    height: "50px",
    marginTop: "20px",
    zIndex: "100",
    position: "absolute",
    top: "20px",
    left: "0",
    right: "0",
    margin: "auto"
  }
}

const AlertTemplate = ({ message, style, options, close }) => {
  return (
    <div style={{margin: "10px", width: "100%", pointerEvents: "all"}}>
      <Icon
        handleclick={close}
        style={{
          cursor: "pointer",
          float: "right",
          color: "rgb(241, 227, 206)",
          position: "relative",
          position: "absolute",
          bottom: "19px",
          right: "21px",
        }}
        type="close"
        width="12px"
        height="12px"
        color={colors.beachHouseLight}
      />
      <p style={{padding: "20px 40px 18px 20px", fontSize: message.length > 24 ? fontSizes.md : fontSizes.default }}>{message ? message : "Error! Please try again."}</p>
    </div>
  )
}
const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  hydrate(
    <BrowserRouter onUpdate={() => window.scrollTo(0, 0)}>
      <ReactApolloHooksProvider client={client}>
        <ApolloProvider client={client}>
          <AlertProvider template={AlertTemplate} {...options}>
            <App />
          </AlertProvider>
        </ApolloProvider>
      </ReactApolloHooksProvider>
    </BrowserRouter>,
    rootElement
  );
} else {
  render(
    <BrowserRouter onUpdate={() => window.scrollTo(0, 0)}>
      <ReactApolloHooksProvider client={client}>
        <ApolloProvider client={client}>
          <AlertProvider template={AlertTemplate} {...options}>
            <HttpsRedirect>
              <App />
            </HttpsRedirect>
          </AlertProvider>
        </ApolloProvider>
      </ReactApolloHooksProvider>
    </BrowserRouter>,
    rootElement
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
