import React, { useState } from 'react';
import gql from 'graphql-tag'
import { useQuery } from 'react-apollo-hooks';

import Input from './Input'
import Icon from './Icon/Icon'
import Button from './Button'

import copyIcon from '../images/copy-icon.svg';
import rapchrLogo from '../images/logo-single.svg'
import loadingIcon from '../images/load-disk.png';

import { isMobile } from "react-device-detect";
import { useAlert } from 'react-alert'

const renderTopRatings = (rating) => {
  const sortedRatings = rating.ratings.sort((function(a, b) { return b.rating - a.rating;}))
  const topRatings = sortedRatings.slice(0, 3)

  return topRatings.map((rating, index) => {
    return (
      <div key={index} className="user-list__user__rating">
        <p className="user-list__user__rating__skill">{rating.skillName}</p>
        <p className="user-list__user__rating__num">{rating.rating}</p>
      </div>
    )
  })

}

const renderUsers = (state, dispatch, artistRatings, artistRatingsLoading, artistRatingsError, history, artistSlug, profile) => {

  if(artistRatingsError) {
    return (
      <div className="loading__wrapper">
        <p className="user-list__error">Error Loading Ratings</p>
      </div>
    )
  } else if(artistRatingsLoading) {
    return (
      <div className="loading__wrapper">
        <img className="loading__icon" alt="loading" src={loadingIcon} />
      </div>
    )
  } else if(artistRatings) {
    const hiddenUsers = (state.originalArtist && state.originalArtist.ratedCount) - (artistRatings && artistRatings.artistRatings.length)

    return artistRatings.artistRatings.map((rating, index) => {
      const hiddenUser = rating.user.hidden
      return (
        <div onClick={() => handleUserClick(rating, artistSlug, dispatch, history, profile)} key={index} className={`user-list__user__wrapper${(artistRatings.artistRatings.length - 1) === index && (hiddenUsers && hiddenUsers.length == 0) ? " last-child" : ""}`}>
          <div className="user-list__user__info">
            <div style={{backgroundImage: `url(${rating.user.image && rating.user.image})`}} className={`user-list__user__image${!rating.user.image || rating.user.hidden ? " default-img" : ""}`}>
            </div>
            {!state.smallScreensize && <p className="user-list__user__name">{rating.user.name.split(" ")[0].length > 10 ? `${rating.user.name.split(" ")[0].slice(0, 10)}...` : rating.user.name.split(" ")[0]}</p>}
          </div>
          <div className="user-list__user__ratings">
            {renderTopRatings(rating)}
          </div>
        </div>
      )
    })
  }
}

const renderHidden = (state, artist, artistRatings) => {
  const ratingCount = artist && artist.skillAverages && artist.skillAverages[0] && artist.skillAverages[0].ratingCount ? artist.skillAverages[0].ratingCount : artist.ratedCount
  const hiddenUsers = ratingCount - (artistRatings && artistRatings.artistRatings.length)

  if(hiddenUsers > 0) {
    return [...Array(hiddenUsers)].map((x, i) =>
      <div key={i} className={`user-list__user__wrapper${(hiddenUsers - 1) === i ? " last-child" : ""} hidden`}>
        <div className="user-list__user__info">
            <div className="user-list__user__image default-img">
            </div>
            <p className="user-list__user__name hidden">hidden</p>
          </div>
          <div className="user-list__user__ratings">
        </div>
      </div>
    )
  }
}

const handleUserClick = (rating, artistSlug, dispatch, history, profile) => {
  dispatch({payload: {renderRatingGraphic: true, userListChoice: rating}})
  if(!profile) {
    const slug = rating.artist && rating.artist.slug ? rating.artist.slug : artistSlug
    history.push(`/rate/graphic/${rating.user.id}/${slug}`)
  }
}

const UserRatingList = ({
  currentUser,
  state,
  dispatch,
  closeModal,
  artist,
  renderUserList,
  history,
  artistSlug,
  profile
}) => {

  const {
    data: artistRatings,
    loading: artistRatingsLoading,
    error: artistRatingsError
  } = useQuery(ARTIST_RATINGS_QUERY, {
    variables: {
      artistId: artist.id
    }
  })

  return (
    <div className="user-list__bg">
      <div className={`user-list__wrapper`}>
        <Icon
          customClass="user-list__close-icon"
          color="#f1e3ce"
          width="20px"
          height="20px"
          type="close"
          style={{opacity: isMobile && "1"}}
          handleclick={() => dispatch({payload: {userListModal: false}})}
        />
        <p className="user-list__title">User Ratings</p>
        <div className="user-list__container">
          {renderUsers(state, dispatch, artistRatings, artistRatingsLoading, artistRatingsError, history, artistSlug, profile)}
          {renderHidden(state, artist, artistRatings)}
          <div className="bump"></div>
        </div>
      </div>
    </div>
  )
}

const ARTIST_RATINGS_QUERY = gql`
  query artistRatings($artistId: ID!) {
    artistRatings(artistId: $artistId) {
      id
      createdAt
      ratings
      artist {
        id
        slug
      }
      user {
        id
        name
        image
        hidden
      }
    }
  }
`

export default UserRatingList;
