import React from 'react';

const EditIcon = ({
  height,
  width,
  color,
  handleclick,
  id,
  ...rest
}) => (
  <svg id={id} onClick={handleclick} width={width} height={height} viewBox="0 0 16 16" {...rest}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g fill={color} transform="translate(-529.000000, -260.000000)">
              <g transform="translate(517.000000, 248.000000)">
                  <path d="M23.3627431,14.8814242 L22.3707752,15.8735374 L20.3515387,13.8541444 L19.5855086,14.6202868 L21.6047451,16.639819 L16.652077,21.5927948 L14.6329798,19.5736107 L13.8669496,20.339753 L15.8860469,22.359146 L15.3925346,22.8527305 C15.3284087,22.9334394 15.2394261,22.9991764 15.1337331,23.0226439 L13.2508253,23.4425529 C13.2226265,23.4488898 13.1939405,23.4519538 13.1656025,23.4519538 C13.0628339,23.4519538 12.9629895,23.4114949 12.8889766,23.3372623 C12.7940062,23.2426261 12.7544583,23.1059293 12.7837015,22.9749428 L13.20334,21.0923162 C13.227013,20.9866774 13.2928796,20.8974729 13.382837,20.8428778 L21.343437,12.861683 C21.4602702,12.7450417 21.6500719,12.7450417 21.7669051,12.8620312 L23.3628824,14.4578941 C23.4797155,14.5747444 23.4797155,14.7645739 23.3627431,14.8814242 Z"></path>
              </g>
          </g>
      </g>
  </svg>
);

export default EditIcon;
