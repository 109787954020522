import React, { Component } from 'react';
import PropTypes from 'prop-types'

const Button = ({
  handleclick,
  height,
  width,
  disabled,
  children,
  style,
  customClass,
  gradient
}) => {

  const buttonClass = gradient ? `button__gradient${disabled ? " disabled" : ""}` : `button${disabled ? " disabled" : ""}`;

  return (
    <button
      height={height}
      width={width}
      style={style}
      type="submit"
      disabled={disabled}
      onClick={(e) => handleclick(e)}
      className={customClass ? `${customClass} ${buttonClass}` : buttonClass}
    >
      {children}
    </button>
  )
}

Button.defaultProps = {
  handleclick: () => {},
  disabled: false,
  gradient: false
}

Button.propTypes = {
  handleclick: PropTypes.func,
  disabled: PropTypes.bool,
  gradient: PropTypes.bool
}

export default Button;
