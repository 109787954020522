import React, {useReducer} from 'react'
import { useHistory } from "react-router-dom";
import gql from 'graphql-tag'
import { useAlert } from 'react-alert'
import WindowSizeListener from 'react-window-size-listener'
import { Helmet } from "react-helmet";
import {
  FacebookShareButton,
  TwitterShareButton,
} from "react-share";
import { isMobile } from "react-device-detect";
import useOnclickOutside from "react-cool-onclickoutside";
import MailchimpSubscribe from "react-mailchimp-subscribe"

import {
  USERS_CONNECTION_QUERY
} from '../api/homepage'

import { handleArtistClick } from '../utils'
import { colors, fontSizes} from '../styles/defaultTheme';
import ErrorPage from '../components/ErrorPage';
import Header from '../components/Header';
import RecommendedUser from '../components/RecommendedUser';
import Footer from '../components/Footer';
import ArtistRatingModal from '../components/ArtistRatingModal';
import Button from '../components/Button';


import imagePlaceholder from '../images/blog-placeholder.png'
import userMsgIconDown from '../images/carrot-down.svg';
import userMsgIconUp from '../images/carrot-up.svg';
import favArtistStepMessage from '../images/favorite-artists-msg.svg';
import mobileFavArtistStepMessage from '../images/other-users-artists-mobile.svg';
import loadingIcon from '../images/load-disk.png';
import copyIcon from '../images/copy-icon.svg';
import twitterShareIcon from '../images/twitter-share-icon.svg';
import facebookShareIcon from '../images/facebook-share-icon.svg';

import { useQuery, useMutation } from 'react-apollo-hooks';

const formatDate = (timestamp) => {
  const date = new Date(timestamp);

  let ye = date.getFullYear();
  let mo = date.toLocaleString('default', { month: 'long' });
  let da = date.getDate();
  return `${mo} ${da}, ${ye}`
}

const renderPosts = (currentUser, state, dispatch, history, alert, postResults, loading, error, recentUsersError) => {

  const small = state.mediumScreensize

  if(error) {
    return (
      <div className="loading__container">
        <p className="post-page__error-msg">No Results</p>
        {!currentUser && renderActionItems(state, dispatch, history)}
      </div>
    )
  } else if(loading) {
    return (
      <div className="loading__container">
        <div className="loading__wrapper">
          <img className="loading__icon" alt="loading" src={loadingIcon} />
        </div>
      </div>
    )
  } else {
    const posts = postResults.posts
    const small = state.mediumScreensize || state.smallScreensize
    if(posts.length === 0) {
      return (
        <div className="loading__container">
          <p className="post-page__error-msg">No Results</p>
          {!currentUser && renderActionItems(state, dispatch, history)}
        </div>
      )
    }
    return posts.map((post, index) => {
      if(post.image) {

        return (
          <div key={index}
            style={{
              backgroundImage: small && `linear-gradient(rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.7) 65.07%), url(${post.image})`,
            }}
            className={`post-search__post with-image ${state.mediumScreensize ? " mobile-post" : ""}`}
            onClick={() => history.push(`/p/${post.slug}`)}
          >
            {!state.smallScreensize && !state.mediumScreensize && <div className="post-search__post__image-wrapper" style={{backgroundImage: `url(${post.image})`}}></div>}
            <div className="post-search__post__content">
              <div className="post-search__post__info">
                <p className="post-search__post__category"><span>{post.category}</span></p>
                <p className="post-search__post__date">{formatDate(post.createdAt)}</p>
              </div>
              <h4 className="post-search__post__title">{post.title}</h4>
              {(post.lede && post.title.length < 34 && !state.mediumScreensize) || (state.mediumScreensize) && <p className="post-search__post__lede">{post.lede.length > 34 && !state.mediumScreensize  ? `${post.lede.substring(0,34)}...` : post.lede}</p>}
              <div className="post-search__post__footer">
                <div className="post-search__post__byline">
                  {post.byline.image && <div style={{backgroundImage: `url(${post.byline.image})`}} className="post-search__post__auth-image"></div>}
                  <p className="post-search__post__auth-name">{post.byline.name}</p>
                </div>
                <p>Read ></p>
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <div className={`post-search__post with-image ${state.mediumScreensize ? " mobile-post" : ""}`}>
            <div className="post-search__post__info">
              <p className="post-search__post__category">{post.category}</p>
              <p className="post-search__post__date">{formatDate(post.createAt)}</p>
            </div>
            <h4 className="post-search__post__title">{post.title}</h4>
            <p className="post-search__post__lede">{post.lede}</p>
            <div className="post-search__post__footer">
              <div></div>
              <p>Read ></p>
            </div>
          </div>
        )
      }
    })
  }
}

const renderSearchArea = (state, dispatch, ref) => {
  return (
    <div className="post-search__search-area">
      <input
        value={state.filter}
        onChange={(e) => dispatch({payload: {filter: e.target.value}})}
        className="fav-artist-step__input post-search__search-input"
        placeholder="Search..."
        />
      <div onClick={() => dispatch({payload: {showDropdown: !state.showDropdown}})} className="post-search__filter-wrapper">
        {!isMobile && <p className="post-search__filter-msg">Sort By: </p>}
        <p className="post-search__filter-choice">{state.sortType} <span className="post-search__filter-arrow">{!state.showDropdown ? "⋁" : "⋀"}</span></p>
        {state.showDropdown && <div ref={ref} className="post-search__filter-dropdown">
          <p onClick={() => dispatch({payload: {showDropdown: false, sortType: "latest"}})} className="post-search__filter-option">Latest</p>
          <p onClick={() => dispatch({payload: {showDropdown: false, sortType: "earliest"}})} className="post-search__filter-option">Earliest</p>
        </div>}
      </div>
    </div>
  )
}

const renderActionItems = (state, dispatch, history) => {
  const url = process.env.REACT_APP_MAILCHIMP_URL;

  const SimpleForm = () => <MailchimpSubscribe url={url}/>


  return (
    <div className="post-page__action-items">
      <Button
        handleclick={() => history.push('/get-access')}
        customClass="rating-artist__rate-btn post-page__cta-btn"
        gradient={true}
      >
        Sign Up To Rate & Explore
      </Button>
      <p className="post-page__action-items__split">- or subscribe for launch updates -</p>
      <div className="post-page__sub-container">
        <p className="post-page__action-items__title">Join Our Waitlist</p>
        <p className="post-page__action-items__msg">Rapchr is a new social platform being built for rap fans of all eras and styles, this is just a taste of what you can expect! Subscribe now for early access when the app is released.</p>
        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => {
            return (
              <div className="post-page__sub-wrapper">
                <SimpleForm url={url} onSubmitted={formData => subscribe(formData)} />
                {status === "sending" && <div style={{ color: "#c77d4a", marginBottom: "20px" }}>sending...</div>}
                {status === "error" && <div style={{ color: "#722a1f" + 10, marginBottom: "20px" }} dangerouslySetInnerHTML={{__html: message}}/>}
                {status === "success" && <div style={{ color: "#c77d4a", marginBottom: "20px" }}>Subscribed !</div>}
              </div>
            )
          }}
        />
      </div>
    </div>
  )
}


const onResize = (windowSize, state, dispatch) => {

  if(windowSize.windowWidth < "1200" && !state.mediumScreensize) {
    dispatch({payload: {mediumScreensize: true, showUsers: false}})
  } else if(windowSize.windowWidth > "1200" && state.mediumScreensize) {
    dispatch({payload: {mediumScreensize: false, showUsers: false}})
  }
  if(windowSize.windowWidth < "768" && !state.smallScreensize) {
    dispatch({payload: {smallScreensize: true, showUsers: false}})
  } else if(windowSize.windowWidth > "768" && state.smallScreensize) {
    dispatch({payload: {smallScreensize: false, showUsers: false}})
  }
}

const handleUserScroll = (e, fetchMore, data, state, dispatch, setLoadingMore) => {
  const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
  if (bottom) {

    if(setLoadingMore) {
      setLoadingMore(true)
    } else {
      dispatch({payload: {loading: true}})
    }

    fetchMore({
      variables: {
        first: 20,
        cursor: data.usersConnection.pageInfo.endCursor
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult.usersConnection.edges;
        const pageInfo = fetchMoreResult.usersConnection.pageInfo;

        if(setLoadingMore) {
          setLoadingMore(false)
        } else {
          dispatch({payload: {loading: false}})
        }
        return newEdges.length
        ? {
          usersConnection: {
            __typename: prevResult.usersConnection.__typename,
            edges: [...prevResult.usersConnection.edges, ...newEdges],
            pageInfo
          }
        }
        : prevResult
      }
    })
  }
}


const reducer = (state, action) => {
  switch (action.type) {
    default:
      return { ...state, ...action.payload };
  }
};

const initialState = () => ({
  smallScreensize: window.innerWidth <= 768,
  filter: '',
  showDropdown: false,
  loading: false,
  sortType: "latest"
})

const PostSearch = ({match, currentUser, allSkills}) => {

  const [state, dispatch] = useReducer(reducer, initialState())
  // const postId = match.params.postId
  // const alert = useAlert();

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    dispatch({payload: {showDropdown: false}})
  });

  const {
    data: posts,
    loading: postLoading,
    error: postError
  } = useQuery(POSTS_QUERY, {
    variables: {
      filter: state.filter,
      orderBy: state.sortType
    }
  })

  const {
    data: recentUsers,
    loading: recentUsersLoading,
    error: recentUsersError,
    fetchMore
  } = useQuery(USERS_CONNECTION_QUERY, {
    variables: {
      first: 20,
    }
  })

  let history = useHistory();

  const seoTitle = "Rapchr | New Music"
  const seoImage = "https://res.cloudinary.com/rapchr/image/upload/v1620340519/full-racphr-logo_zqtbdx.png";
  const seoDescription = "The newest music in hip-hop";


  return (
    <div className="post-search">
      <Helmet>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
        <meta propery="og:url" content={window.location.href} />
        <meta propery="og:title" content={seoTitle} />
        <meta name="og:description" content={seoDescription} />
        <meta propery="og:image"  content={seoImage} />
        <meta name="fb:app_id" content={process.env.REACT_APP_FACEBOOK_ID} />
        <meta name="twitter:title" content={seoTitle} />
        <meta name="twitter:description" content={seoDescription} />
        <meta name="twitter:image" content={seoImage} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@rapchr" />
      </Helmet>
      <WindowSizeListener onResize={(windowSize) => onResize(windowSize, state, dispatch)}/>
      <Header
        customClass={state.smallScreensize && state.showUsers && "blur"}
        allSkills={allSkills}
        currentUser={currentUser}
        smallScreensize={state.smallScreensize}
        onboarding={currentUser && (!currentUser.onboardedArtists || !currentUser.onboardedSkills)}
        profile={true}
      />
      <div style={{width: recentUsersError && "100%"}} className={`post-search__post-container skill-step__left-hand post-page__left-hand  ${state.smallScreensize && state.showUsers && "blur"}`}>
        <h2 className="post-search__title">New Music</h2>
        {renderSearchArea(state, dispatch, ref)}
        <div className="post-search__post-results">
          {renderPosts(currentUser, state, dispatch, history, alert, posts, postLoading, postError, recentUsersError)}
        </div>
        {!currentUser && renderActionItems(state, dispatch, history)}
        <div className="bump"></div>
        <div className="bump"></div>
        <div className="left-hand__bottom-gradient"></div>
      </div>
      {((state.smallScreensize && state.showUsers) || !state.smallScreensize) && <div style={{display: recentUsersError && "none"}} className="skill-step__right-hand">
        <div onClick={() => dispatch({payload: {showUsers: !state.showUsers}})} className="skill-step__mobile-users__cta__top">
          <img src={mobileFavArtistStepMessage} className="skill-step__msg-mobile" alt="Other user's favorite artists" />
          <img lt={state.showUsers ? "close" : "open"} src={state.showUsers ? userMsgIconDown : userMsgIconUp} className="skill-step__msg-icon" />
        </div>
        <div className="skill-step__top-gradient"></div>
        <div className="skill-step__users">
          {recentUsersLoading && !state.smallScreensize &&
            <div className="loading__wrapper">
              <img className="loading__icon" alt="loading" src={loadingIcon} />
            </div>
          }
          {recentUsers && recentUsers.usersConnection && recentUsers.usersConnection.edges.map((user, index) => {
            return (
              <RecommendedUser
                key={index}
                user={user.node}
                artists={true}
                index={index}
                currentUser={currentUser}
                recentUsersLoading={recentUsersLoading}
                onboarding={currentUser && (!currentUser.onboardedArtists || !currentUser.onboardedSkills)}
                onArtistClick={(artist) => handleArtistClick(artist, history)}
                customClass={state.loading ? " loading-more" : ""}
              />
            )
          })}
        </div>
        {state.loading && <div className="loading__wrapper user-profile__loading-wrapper right-hand">
          <img className="loading__icon" alt="loading" src={loadingIcon} />
        </div>}
        <div className="skill-step__bottom-gradient"></div>
        <div className="bump"></div>
      </div>}
      {!state.showUsers && <div onClick={() => dispatch({payload: {showUsers: !state.showUsers}})} className="skill-step__mobile-users__cta">
        <img src={mobileFavArtistStepMessage} className="skill-step__msg-mobile" alt="Other user's favorite artists" />
        <img alt={state.showUsers ? "close" : "open"} src={state.showUsers ? userMsgIconDown : userMsgIconUp} className="skill-step__msg-icon" />
      </div>}
      <Footer />
    </div>
  )
}


export default PostSearch

const POSTS_QUERY = gql`
  query posts($filter: String, $orderBy: String){
    posts(filter: $filter, orderBy: $orderBy) {
      id
      title
      category
      lede
      slug
      byline {
        name
        image
      }
      image
      createdAt
    }
  }
`
