import React from 'react';

const DownArrow = ({
  height,
  width,
  color,
  style,
  handleclick,
  ...rest
}) => (
  <svg onClick={handleclick} style={style} width={width ? width : "15"} height={height ? height : "15"} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
  <path d="M7.78284 14.7172C7.62663 14.8734 7.37337 14.8734 7.21716 14.7172L0.682844 8.18284C0.430858 7.93086 0.609324 7.5 0.965686 7.5L3.35 7.5C3.57091 7.5 3.75 7.32091 3.75 7.1L3.75 0.4C3.75 0.179086 3.92909 -9.0682e-07 4.15 -9.04186e-07L10.85 -8.24289e-07C11.0709 -8.21655e-07 11.25 0.179086 11.25 0.4L11.25 7.1C11.25 7.32091 11.4291 7.5 11.65 7.5L14.0343 7.5C14.3907 7.5 14.5691 7.93086 14.3172 8.18284L7.78284 14.7172Z" fill={color ? color : "#695750"}/>
  </svg>
);

export default DownArrow;
