import React from 'react';

const PasswordIcon = ({
  height,
  width,
  color,
  style
}) => (
  <svg style={style} width={width} height={height} viewBox="0 0 24 24">
      <g stroke="none" strokeWidth=".6" fill="none" fillRule="evenodd">
          <g transform="translate(-409.000000, -397.000000)">
              <g transform="translate(280.000000, 118.000000)">
                  <g transform="translate(89.000000, 103.000000)">
                      <g transform="translate(24.137143, 166.994286)">
                          <g transform="translate(14.628571, 9.874286)">
                              <g stroke={color} strokeWidth=".8" transform="translate(1.828571, 0.000000)">
                                  <rect x="0.365714286" y="4.02285714" width="13.8971429" height="13.8971429" rx="6.94857143"></rect>
                                  <circle cx="7.31428571" cy="10.9714286" r="1.46285714"></circle>
                                  <path d="M3.65714286,4.75428571 L3.65714286,3.57647059 C3.65714286,1.60440471 5.29773714,0 7.31428571,0 C9.33083429,0 10.9714286,1.60440471 10.9714286,3.57647059 L10.9714286,4.75428571"></path>
                              </g>
                              <rect x="0" y="0" width="18.2857143" height="18.2857143"></rect>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </g>
  </svg>
);

export default PasswordIcon;
