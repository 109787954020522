import React from 'react';

const EmailIcon = ({
  height,
  width,
  color,
  handleclick,
  ...rest
}) => (
  <svg onClick={handleclick} width={width} height={height} viewBox="0 0 16 13" {...rest}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-409.000000, -347.000000)">
              <g transform="translate(280.000000, 118.000000)">
                  <g transform="translate(89.000000, 103.000000)">
                      <g transform="translate(24.137143, 112.137143)">
                          <g transform="translate(14.628571, 10.971429)">
                              <g transform="translate(1.828571, 2.925714)" stroke={color} strokeWidth="0.731428571">
                                  <rect x="0.365714286" y="0.365714286" width="13.8971429" height="11.7028571" rx="1.46285714"></rect>
                                  <polyline points="13.8971429 0.365714286 7.49717066 6.94857143 1.09714286 0.365714286"></polyline>
                              </g>
                              <rect x="0" y="0" width="18.2857143" height="18.2857143"></rect>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </g>
  </svg>
);

export default EmailIcon;
