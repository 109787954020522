import {
  handleVoteLogic,
  productionConsoleCheck,
} from '../utils'

import ReactGA from 'react-ga';

const adminCheck = (user) => {
  if(user && user.roles) {

    if(user.roles.some(role => role.name === "ADMIN")) {
      return true
    }
  }

  return false
}

const handleGAEvent = (category, action, label, userId, currentUser) => {
  if(process.env.REACT_APP_PRODUCTION) {
    if(!currentUser || (currentUser && currentUser.roles && !adminCheck(currentUser))) {
      ReactGA.event({
        userId: userId,
        category: category,
        action: action,
        label: label
      })
    }
  }
}

const handleGAError = (description, userId, action, currentUser) => {
  if(process.env.REACT_APP_PRODUCTION) {
    if(!currentUser || (currentUser && currentUser.roles && !adminCheck(currentUser))) {
      ReactGA.event({
        userId: userId,
        category: "Error",
        description: description,
        fatal: true,
        label: 'error',
        action: action
      })
    }
  }
}

export const userUpdateShares = (userUpdateMutation, currentUser, category, action, label, download, share) => {

  userUpdateMutation({
    variables: {download: download, share: share}
  })
  .then((result) => {
    productionConsoleCheck("SUCCESS:", result)
    handleGAEvent(category, action, label, currentUser.email, currentUser)
  })
  .catch((error) => {
    handleGAError(`Error when updating user ${share ? "share" : "download"}`, currentUser.email, action, currentUser)
    productionConsoleCheck(`Error when updating user ${share ? "share:" : "download:"}`, error)
  })
}


export const submitArtistRating = (rateArtistMutation, artist, artistSlug, currentUser, state, dispatch, alert, formatMutationSkillAverages, updateCacheAfterRating, history) => {
  dispatch({payload: {loadingBarProgress: 40}})

  if(state.userArtistRatings.length < 10) {
    alert.removeAll()
    alert.show("Must rate all 10 skills")
    dispatch({payload: {loadingBarProgress: 0}})
  } else {
    rateArtistMutation({
      variables: {artistId: artist.id, ratings: state.userArtistRatings, artistSkillAverages: formatMutationSkillAverages(artist.skillAverages)},
      update: (store, data) => updateCacheAfterRating(store, data, state, artist, artistSlug, currentUser.id)
    })
    .then((result) => {
      handleGAEvent("Rating Rapper", `Rating ${artist.name}`, 'rating', currentUser.email, currentUser)
      dispatch({payload: {loadingBarProgress: 100, rateArtistView: false, userArtistRatings: [], initialUserRatings: [], renderRatingGraphic: true, ratingMade: true}})
      history.push(`/rate/graphic/${currentUser.id}/${artistSlug}`)
      productionConsoleCheck("SUCCESS:", result)
    })
    .catch((error) => {
      dispatch({payload: {loadingBarProgress: 0}})
      alert.removeAll()
      alert.show()
      handleGAError('Error Rating Artist', currentUser.email, `Rating ${artist.name}`, currentUser)
      productionConsoleCheck("Error when submitting ratings: ", error)
    })
  }
}

export const submitRatingUpdate = (updateRatingMutation, artist, artistSlug, currentUser, state, dispatch, alert, formatMutationSkillAverages, updateCacheAfterRating, history) => {
  dispatch({payload: {loadingBarProgress: 40}})

  if(state.userArtistRatings.length < 10) {
    alert.removeAll()
    alert.show("Must rate all 10 skills")
    dispatch({payload: {loadingBarProgress: 0}})
  } else {
    updateRatingMutation({
      variables: {artistId: artist.id, ratings: state.userArtistRatings, artistSkillAverages: formatMutationSkillAverages(artist.skillAverages)},
      update: (store, data) => updateCacheAfterRating(store, data, state, artist, artistSlug, currentUser.id)
    })
    .then((result) => {
      handleGAEvent("Update Rapper Rating", `Updating ${artist.name} Rating`, 'rating', currentUser.email, currentUser)
      dispatch({payload: {loadingBarProgress: 100, rateArtistView: false, userArtistRatings: [], renderRatingGraphic: true, ratingMade: true}})
      history.push(`/rate/graphic/${currentUser.id}/${artistSlug}`)
      productionConsoleCheck("SUCCESS:", result)
    })
    .catch((error) => {
      dispatch({payload: {loadingBarProgress: 0}})
      alert.removeAll()
      alert.show()
      handleGAError('Error Updating Artist Rating', currentUser.email, `Updating ${artist.name} rating`, currentUser)
      productionConsoleCheck("Error when submitting ratings: ", error)
    })
  }
}

export const submitVote = (e, createVoteMutation, artist, ratingGroup, artistSlug, userId, positive, state, dispatch, alert, currentUser, updateCacheAfterVote) => {
  e.stopPropagation()

  let oldVoteSum = ratingGroup.voteSum
  oldVoteSum = handleVoteLogic(ratingGroup.currentUserVote, positive, oldVoteSum)

  dispatch({payload: {voting: true, graphicRating: oldVoteSum}})

  createVoteMutation({
    variables: {
      ratingGroupId: ratingGroup.id,
      positive: positive
    },
    update: (store, data) => updateCacheAfterVote(store, data, state, artist, ratingGroup, positive, artistSlug, userId)
  })
  .then(function (res) {
    handleGAEvent("Submit Voti", `Voting for ${artist.name}`, 'voting', currentUser.email, currentUser)
    dispatch({payload: {voting: false}})
    productionConsoleCheck("SUCCESS:", res)
   })
   .catch(function (error) {
     dispatch({payload: {voting: false}})
     handleGAError('Error Submitting Vote', currentUser.email, `Voting for ${artist.name}`, currentUser)
     productionConsoleCheck('Error Submitting Vote: ', error)
   });
}

export const updateVoteToggle = (voteToggleMutation, artist, artistSlug, switchOn, state, dispatch, alert, setShowVotes, currentUserRatings) => {

  voteToggleMutation({
    variables: {
      ratingGroupId: currentUserRatings ? currentUserRatings.id : artist.currentUserRatings.id,
      turnOn: switchOn
    }
  })
  .then(function (res) {
    handleGAEvent("Toggle Voting", `Toggling for ${artist.name}`, 'toggle voting', currentUserRatings ? currentUserRatings.user.email : artist.currentUserRatings.user.email, currentUserRatings ? currentUserRatings.user : artist.currentUserRatings.user)
    setShowVotes(switchOn)
    productionConsoleCheck("SUCCESS:", res)
   })
   .catch(function (error) {
     alert.removeAll()
     alert.show()
     handleGAError('Error Toggling Vote', currentUserRatings ? currentUserRatings.user.email : artist.currentUserRatings.user.email, `Toggling for ${artist.name}`, currentUserRatings ? currentUserRatings.user : artist.currentUserRatings.user)
     productionConsoleCheck('Error Toggling Vote: ', error)
   });
}
