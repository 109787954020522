import React from 'react';

import { useQuery, useMutation } from 'react-apollo-hooks';
import gql from 'graphql-tag'

import loadingIcon from '../images/load-disk.png';


const renderSearchResults = (artistSearchResults, onArtistClick, state, currentUser, filter) => {

  if(artistSearchResults && artistSearchResults.artists.length < 1) {
    return (
      <div className="search-error__wrapper">
        {state.skillPreference && !filter ? <p className="search-error__message">Check back later, or update your skill preferences <a className="search-error__link" href="/update-skills">here</a></p> :
          <p className="search-error__message">No search results.</p>
        }
      </div>
    )
  }

  return artistSearchResults.artists.map((artist, index) => {
    return (
      <div
        className="artist-option__wrapper"
        // onClick={() => dispatch({payload: {compareSearch: false, compareArtist: artist}})}
        onClick={() => onArtistClick(artist)}
        key={index}
      >
        <div
          className="artist-option__image"
          style={{backgroundImage: `url(${artist.image})`}}
        >
        </div>
        <p className={`artist-option__name ${artist.name.length > 13 ? "large" : ""}`}>{artist.name}</p>
      </div>
    )
  })
}

const ArtistSearchResults = ({
  filter,
  onArtistClick,
  state,
  excludes,
  first,
  compareSearch,
  currentUser
}) => {

  const {
    data: artistSearchResults,
    loading: artistSearchResultsLoading,
    error: artistSearchResultsError
  } = useQuery(ARTISTS_QUERY, {
    variables: {
      filter: filter,
      skillPreference: state.skillPreference,
      featured: state.featured,
      mostListed: state.mostListed,
      mostRated: state.mostRated,
      excludes: excludes,
      first: first,
      compare: compareSearch && compareSearch
    }
  });

  if(!artistSearchResultsLoading && !artistSearchResultsError) {
    return renderSearchResults(artistSearchResults, onArtistClick, state, currentUser, filter)
  } else if(artistSearchResultsLoading) {
    return (
      <div className="loading__wrapper">
        <img className="loading__icon inner" alt="loading" src={loadingIcon} />
      </div>
    )
  } else {
    return (
      <div className="search-error__wrapper">
        {state.skillPreference && !filter ? <p className="search-error__message">Check back later, or update your skill preferences <a className="search-error__link" href="/update-skills">here</a></p> :
          <p className="search-error__message">No search results.</p>
        }
      </div>
    )
  }
}

const ARTISTS_QUERY = gql`
  query artists($filter: String, $excludes: [ID!], $featured: Boolean, $mostListed: Boolean, $mostRated: Boolean, $skillPreference: Boolean, $first: Int $compare: Boolean) {
    artists(filter: $filter, excludes: $excludes, featured: $featured, mostListed: $mostListed, mostRated: $mostRated, skillPreference: $skillPreference, first: $first, compare: $compare) {
      id
      name
      image
      scaledImage
      slug
      deceased
    }
  }
`

export default ArtistSearchResults;
