
export const quotes = [
  {
    message: "Behind the walls of intelligence, life is defined...",
    name: "Nas"
  },
  {
    message: "Remind yourself, nobody’s built like you. You design yourself.",
    name: "JAY-Z"
  },
  {
    message: "My whole thing is to inspire, to better people, to better myself forever in this thing that we call rap, this thing that we call hip hop.",
    name: "Kenrick Lamar"
  },
  {
    message: "When you go out, go out with a bang, anything you do.",
    name: "Playboi Carti"
  },
  {
    message: "Hip-hop is about the brilliance of pavement poetry.",
    name: "Michael Eric Dyson"
  }
]
