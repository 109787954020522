import React from 'react';

const ListViewIcon = ({
  height,
  width,
  color,
  handleclick,
  style,
  ...rest
}) => (
  <svg { ...rest } onClick={handleclick} style={style} width={width} height={height} viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1.14283 2.33333C1.774 2.33333 2.28567 1.811 2.28567 1.16667C2.28567 0.522334 1.774 0 1.14283 0C0.511664 0 0 0.522334 0 1.16667C0 1.811 0.511664 2.33333 1.14283 2.33333ZM4.59517 0C3.95084 0 3.4285 0.522334 3.4285 1.16667C3.4285 1.811 3.95084 2.33333 4.59517 2.33333H14.833C15.4773 2.33333 15.9997 1.811 15.9997 1.16667C15.9997 0.522334 15.4773 0 14.833 0H4.59517ZM4.59517 5.83301C3.95084 5.83301 3.4285 6.35534 3.4285 6.99967C3.4285 7.64401 3.95084 8.16634 4.59517 8.16634H14.833C15.4773 8.16634 15.9997 7.64401 15.9997 6.99967C15.9997 6.35534 15.4773 5.83301 14.833 5.83301H4.59517ZM3.4285 12.8337C3.4285 12.1893 3.95084 11.667 4.59517 11.667H14.833C15.4773 11.667 15.9997 12.1893 15.9997 12.8337C15.9997 13.478 15.4773 14.0003 14.833 14.0003H4.59517C3.95084 14.0003 3.4285 13.478 3.4285 12.8337ZM2.28599 7C2.28599 7.64433 1.77433 8.16667 1.14316 8.16667C0.51199 8.16667 0.000325521 7.64433 0.000325521 7C0.000325521 6.35567 0.51199 5.83333 1.14316 5.83333C1.77433 5.83333 2.28599 6.35567 2.28599 7ZM1.14316 14C1.77433 14 2.28599 13.4777 2.28599 12.8333C2.28599 12.189 1.77433 11.6667 1.14316 11.6667C0.51199 11.6667 0.000325521 12.189 0.000325521 12.8333C0.000325521 13.4777 0.51199 14 1.14316 14Z" fill={color}/>
  </svg>
);

export default ListViewIcon;
