import React, { useEffect, useRef } from 'react';

import Icon from '../components/Icon/Icon';
import Button from '../components/Button';
import RadarGraph from '../components/RadarGraph';
import Tooltip from '../components/Tooltip';
import UserRatingList from '../components/UserRatingList'
import RatingGraphic from '../components/RatingGraphic';

import styled, { keyframes } from 'styled-components'
import { colors } from '../styles/defaultTheme';
import { isMobile } from "react-device-detect";

import { useQuery, useMutation } from 'react-apollo-hooks';
import gql from 'graphql-tag';

import deceasedIcon from '../images/rip.svg';
import rapchrLogo from '../images/BH_Filled_Secondary_Logo.svg';
import loadingIcon from '../images/load-disk.png';
import { handleModalClose } from '../utils'

import {
  VOTE_MUTATION,
  VOTE_TOGGLE_MUTATION,
  USER_RATING_QUERY
} from '../api/singleArtist'

import {
  updateCacheAfterVote,
  updateCacheAfterRating
} from '../cache/singleArtist'

import {
  submitArtistRating,
  submitRatingUpdate,
  submitVote,
  updateVoteToggle
} from '../mutations/singleArtist'

const widthSlide = (score) => keyframes`
  from {
    width: 0;
  }

  to {
    width: ${score + "%"};
  }
`

const Bar = styled.div`
  animation: ${props => widthSlide(props.score)} ease-in-out;
  animation-duration: 1s;
  width: ${props => props.score ? props.score + "%" : "0"};
`

const formatSkillAverage = (skillAverage, artist) => {
  return skillAverage.average === 0 || artist.hideRatings ? "N/R" : Math.round(skillAverage.average * 10)
}

const renderCompareRatings = (originalArtist, compareArtist) => {

  return (
    <div className="rating-artist__rating-wrapper compare">
        {originalArtist.skillAverages.map((skillAverage, index) => {
          const comparisonArtistAverage =  compareArtist.skillAverages.filter(skill => skill.skill.name === skillAverage.skill.name)
          return (
            <div key={index} className="skill-rating__wrapper__compare">
              <div className="skill-rating__info-wrapper__compare">
                <p className="skill-rating__skill-name__compare">{skillAverage.skill.name}</p>
                  <div className="skill-rating__skill-average-wrapper__compare">
                    <p className="skill-rating__skill-average__compare">{formatSkillAverage(skillAverage, originalArtist)}</p>
                    <p className="skill-rating__skill-average__compare comparison">{formatSkillAverage(comparisonArtistAverage[0], compareArtist)}</p>
                  </div>
              </div>
              <div className="skill-rating__score-wrapper__compare">
                <Bar className={`skill-rating__score__compare${index > 2 ? " bottom" : ""}`} score={skillAverage.average * 10}></Bar>
                <Bar className="skill-rating__score__compare comparison" score={comparisonArtistAverage[0].average * 10}></Bar>
              </div>
            </div>
          )
        })}
    </div>
  )
}

const renderArtistImages = (originalArtist, compareArtist) => {
  return (
    <div className="rating-artist__image-wrapper__compare">
      <div className="rating-artist__image-wrapper__original">
        <div
          style={{backgroundImage: `url(${originalArtist.image && originalArtist.image})`}}
          className="rating-artist__image"
        >
        </div>
        <p className={`rating-artist__name__compare ${originalArtist.name.length > 13 ? "rating-artist__compare-name-lg" : ""}`}>{originalArtist.name}</p>
      </div>
      {compareArtist.id && <div className="rating-artist__image-wrapper__comparison">
        <div
          style={{backgroundImage: `url(${compareArtist.image})`}}
          className="rating-artist__image"
        >
        </div>
        <p className={`rating-artist__name__compare comparison-image ${compareArtist.name.length > 13 ? "rating-artist__compare-name-lg" : ""}`}>{compareArtist.name}</p>
      </div>}
    </div>
  )
}

const renderViewIcons = (state, dispatch) => {
  return (
    <div className="rating-artist__view-icon-wrapper">
      <div className="rating-artist__view-icon-container">
        <Icon
          customClass="rating-artist__graph-icon"
          color={state.listView ? "#695750" : colors.beachHouse}
          type="graph"
          width="17px"
          height="15px"
          handleclick={() => dispatch({payload: {listView: false}})}
        />
        <Icon
          customClass="rating-artist__list-icon"
          color={!state.listView ? "#695750" : colors.beachHouse}
          type="list"
          width="16px"
          height="34px"
          handleclick={() => dispatch({payload: {listView: true}})}
        />
      </div>
    </div>
  )
}

const renderRatingLabels = (originalArtist, compareArtist, state, dispatch, homepage, currentUser) => {
  const originalRatingCount = originalArtist && originalArtist.skillAverages && originalArtist.skillAverages[0] && originalArtist.skillAverages[0].ratingCount ? originalArtist.skillAverages[0].ratingCount : originalArtist.ratedCount
  const compareRatingCount = compareArtist && compareArtist.skillAverages && compareArtist.skillAverages[0] && compareArtist.skillAverages[0].ratingCount ? compareArtist.skillAverages[0].ratingCount : compareArtist.ratedCount


  return (
    <div className="rating-artist__label-wrapper compare">
        {currentUser && <Tooltip howItWorks={true} customClass={`hiw${state.compareArtist && state.compareArtist.id ? " compare" : ""}`} smallScreensize={state.smallScreensize} />}
        <p onClick={() => dispatch({payload: {userListModal: true, compareListChoice: originalArtist}})} className="rating-artist__label ratings-label">
          <span className="rating-artist__label-color">
          </span>
          {originalArtist.name} {!homepage && <span className="rating-artist__rated-count">{`(${originalRatingCount})`}</span>}
        </p>
        <p onClick={() => dispatch({payload: {userListModal: true, compareListChoice: compareArtist}})} className="rating-artist__label ratings-label">
          <span className="rating-artist__label-color compare">
          </span>
          {compareArtist.name} {!homepage && <span className="rating-artist__rated-count">{`(${compareRatingCount})`}</span>}
        </p>
    </div>
  )
}

const renderUserList = (state, dispatch, artist, history, artistSlug, postPage) => {
  if(state.userListModal) {
    return <UserRatingList profile={postPage} history={history} artistSlug={artistSlug} artist={artist} dispatch={dispatch} state={state} />
  }
}

const handleCompareBack = (state, dispatch, homepage) => {
  const compareArtistId = state.compareArtist.id;

  if(homepage) {
    dispatch({payload: {rateArtistView: false, compareSearch: true, compareArtist: {}}})
  } else {
    const newExcludes = state.excludes.filter(artistId => artistId !== compareArtistId)
    dispatch({payload: {excludes: newExcludes, rateArtistView: false, compareSearch: true, compareArtist: {}}})
  }

}

const handleBackToRatings = (state, dispatch, homepage) => {
  const compareArtistId = state.compareArtist.id;
  if(homepage) {
    dispatch({payload: {rateArtistView: false, compareFilter: "", compareSearch: false, compareArtist: {}}})
  } else {
    const newExcludes = state.excludes.filter(artistId => artistId !== compareArtistId)
    dispatch({payload: {excludes: newExcludes, rateArtistView: false, compareFilter: "", compareSearch: false, compareArtist: {}}})
  }
}

const CompareArtists = ({
  state,
  dispatch,
  allSkills,
  history,
  userId,
  executeScroll,
  homepage,
  appState,
  artistSlug,
  submitRatingUpdate,
  submitArtistRating,
  currentUser,
  postPage
}) => {

  const [createVoteMutation] = useMutation(VOTE_MUTATION)
  const [voteToggleMutation] = useMutation(VOTE_TOGGLE_MUTATION)

  const {
    data: artistData,
    loading: artistLoading,
    error: artistError
  } = useQuery(ORIGINAL_ARTIST_QUERY, {
   variables: { artistId: state.originalArtist.id }
  });

  const {
    data: compareArtistData,
    loading: compareArtistLoading,
    error: compareArtistError
  } = useQuery(COMPARE_ARTIST_QUERY, {
   variables: { artistId: state.compareArtist.id}
  });

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  if(artistLoading || compareArtistLoading) {
    return (
      <div className="rating-artist__bg">
        <div className="rating-artist__wrapper">
          {renderArtistImages(state.originalArtist, state.compareArtist)}
          <div className="rating-artist__compare-container">
            <div className="loading__wrapper">
              <img className="loading__icon" alt="loading" src={loadingIcon} />
            </div>
          </div>
        </div>
      </div>
    )
  }

  const originalArtist = artistData.singleArtist
  const compareArtist = compareArtistData && compareArtistData.singleArtist

  return (
    <div className="rating-artist__bg">
      {state.renderRatingGraphic &&
        <RatingGraphic
           state={state}
           userId={userId}
           dispatch={dispatch}
           artist={state.compareListChoice}
           artistSlug={artistSlug}
           userListChoice={state.userListChoice}
           onVoteClick={(e, ratingGroup, positive) => submitVote(e, createVoteMutation, state.compareListChoice, ratingGroup, artistSlug, userId, positive, state, dispatch, alert, currentUser, updateCacheAfterVote)}
           onSwitchToggle={(switchOn, setShowVotes) => updateVoteToggle(voteToggleMutation, state.compareListChoice, artistSlug, switchOn, state, dispatch, alert, setShowVotes)}
           history={history}
           currentUser={currentUser}
           artistName={state.compareListChoice.name}
           isMobile={isMobile}
           appState={appState}
           artistImage={state.compareListChoice.smallImage ? state.compareListChoice.smallImage : state.compareListChoice.image}
           artistId={state.compareListChoice.id}
           postPage={postPage}
         />
      }
      <div className="rating-artist__wrapper">
        <div className="rating-artist__icon-wrapper">
          <Icon
            customClass="rating-artist__exit-icon"
            color={isMobile ? colors.beachHouse : colors.beachHouseLight}
            type="close"
            width="24px"
            height="24px"
            handleclick={() => handleModalClose(dispatch, history, userId)}
            style={{opacity: isMobile && "1"}}
          />
        </div>
        {!state.compareSearch && renderArtistImages(originalArtist, compareArtist)}
        {renderUserList(state, dispatch, state.compareListChoice, history, artistSlug, postPage)}
        <div className="rating-artist__compare-container">
          <div className="rating-artist__bar-wrapper">
            {renderRatingLabels(originalArtist, compareArtist, state, dispatch, homepage, currentUser)}
            {renderViewIcons(state, dispatch)}
          </div>
          {!state.compareSearch && state.listView && renderCompareRatings(originalArtist, compareArtist)}
          {!state.compareSearch && !state.listView && <RadarGraph homepage={homepage} state={state} allSkills={allSkills} compareArtist={compareArtist} originalArtist={originalArtist} />}
        </div>
        <div className="rating-artist__bottom-gradient"></div>
        <div className={state.compareSearch ? "rating-artist__cta-wrapper__compare-search" : "rating-artist__cta-wrapper__compare"}>
          {!state.compareSearch && <Button
            handleclick={state.rateArtistView ? () => dispatch({payload: {rateArtistView: false}})
              : () => handleCompareBack(state, dispatch, homepage)}
            customClass="rating-artist__rate-btn"
            gradient={true}
          >
            Choose Rapper
          </Button>}
          <Button
            handleclick={() => handleBackToRatings(state, dispatch, homepage)}
            customClass={`rating-artist__compare-btn ${state.compareSearch ? 'compare-search' : ""}`}
          >
            Ratings
          </Button>
        </div>
      </div>
    </div>
  )
}



const ORIGINAL_ARTIST_QUERY = gql`
  query singleArtist($artistId: ID!) {
    singleArtist(artistId: $artistId) {
      id
      name
      image
      deceased
      hideRatings
      ratedCount
      skillAverages {
        skill {
          name
        }
        average
        ratingCount
      }
      currentUserRatings {
        id
        ratings
        votesTurnedOn
        voteSum
        currentUserVote {
          id
          positive
        }
        user {
          id
          name
          email
          roles {
            name
          }
        }
      }
    }
  }
`

const COMPARE_ARTIST_QUERY = gql`
  query singleArtist($artistId: ID!) {
    singleArtist(artistId: $artistId) {
      id
      name
      image
      deceased
      hideRatings
      ratedCount
      skillAverages {
        skill {
          name
        }
        average
        ratingCount
      }
      currentUserRatings {
        id
        ratings
        votesTurnedOn
        voteSum
        currentUserVote {
          id
          positive
        }
        user {
          id
          name
          email
          roles {
            name
          }
        }
      }
    }
  }
`

export default CompareArtists;
