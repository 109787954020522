import React, {useReducer} from 'react';
import SearchBar from './SearchBar'
import ArtistSearchResults from '../components/ArtistSearchResults';

const artistFilterArgument = (choice, filter, state, dispatch, parentDispatch) => {
  if(filter.length > 0) {
    parentDispatch({payload: {filter: '', compareFilter: ''}})
  }

  if(choice === "skillPreference") {
    if(state.skillPreference && !filter.length > 0) {
      dispatch({payload: {skillPreference: false}})
    } else {
      dispatch({payload: {skillPreference: true, featured: false, mostRated: false}})
    }
  } else if(choice === "featured") {
    if(state.featured && !filter.length > 0) {
      dispatch({payload: {featured: false}})
    } else {
      dispatch({payload: {featured: true, skillPreference: false, mostRated: false}})
    }
  } else if(choice === "mostRated") {
    if(state.mostRated && !filter.length > 0) {
      dispatch({payload: {mostRated: false}})
    } else {
      dispatch({payload: {mostRated: true, skillPreference: false, featured: false}})
    }
  }
}

const recommendationOptions = (filter, state, dispatch, parentDispatch, homepage) => {
  return (
    <div className="rec-options__wrapper">
      <p
        onClick={() => artistFilterArgument("featured", filter, state, dispatch, parentDispatch)}
        className={`rec-options__name ${(state.featured) && !filter.length > 0 ? "active" : ''}`}>
        Featured
      </p>
      <p onClick={() => artistFilterArgument("mostRated", filter, state, dispatch, parentDispatch)}
        className={`rec-options__name ${(state.mostRated) && !filter.length > 0 ? "active" : ''}`}>
        Most Rated
      </p>
      <p
        onClick={() => artistFilterArgument("skillPreference", filter, state, dispatch, parentDispatch)}
        className={`rec-options__name ${(state.skillPreference) && !filter.length > 0 ? "active" : ''}`}>
        Recommended
      </p>
    </div>
  )
}

const reducer = (state, action) => {
  switch (action.type) {
    default:
      return { ...state, ...action.payload };
  }
};

const initialState = () => ({
  skillPreference: false,
  excludes: [],
  featured: true,
  mostRated: false,
  mediumScreensize: false,
  smallScreensize: false,
  mobileScreensize: false
})

const SearchArea = ({
  filter,
  onFilterChange,
  clearFilter,
  blur,
  parentDispatch,
  onArtistClick,
  excludes,
  isMobile,
  first,
  compareSearch,
  smallScreensize,
  currentUser,
  ratePageSearch,
  handleFocus,
  homepage,
  history,
  openSignup
}) => {

  const [state, dispatch] = useReducer(reducer, initialState())
  return (
    <div className={`search-area__wrapper${blur ? " blur" : ""}`}>
      {!homepage && <SearchBar
        clearIconHeight={smallScreensize || (compareSearch && !ratePageSearch) ? "12px" : "1.3vw"}
        clearIconWidth={smallScreensize || (compareSearch && !ratePageSearch) ? "12px" : "1.3vw"}
        searchIconHeight={smallScreensize || (compareSearch && !ratePageSearch) ? "18px" : "2vw"}
        searchIconWidth={smallScreensize || (compareSearch && !ratePageSearch) ? "18px" : "2vw"}
        onHandleClick={clearFilter}
        onChange={(e) => onFilterChange(e.target.value)}
        filter={filter}
        isMobile={isMobile}
        ratePageSearch={ratePageSearch}
        handleFocus={handleFocus}
      />}
      {homepage && <p className="search-area__no-user-msg"><u onClick={history.location.pathname.includes("rapper") ? () => history.push('/') : () => openSignup()}>Sign up</u> to chose from over 600 rappers!</p>}
      {!homepage && recommendationOptions(filter, state, dispatch, parentDispatch, homepage)}
      <div className="search-area__results-wrapper">
        <ArtistSearchResults
          compareSearch={compareSearch}
          first={first} excludes={excludes}
          state={state}
          filter={filter}
          onArtistClick={(artist) => onArtistClick(artist)}
          currentUser={currentUser}
        />
      </div>
    </div>
  )
}

export default SearchArea;
