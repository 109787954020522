import gql from 'graphql-tag'


export const CURRENT_USER_QUERY = gql`
  query currentUser {
    currentUser {
      id
      name
      email
      onboardedArtists
      onboardedSkills
      location
      image
      resetPasswordToken
      resetPasswordExpires
      roles {
        name
      }
    }
  }
`

export const FEATURED_ARTISTS_QUERY = gql`
  query artists($filter: String, $excludes: [ID!], $featured: Boolean, $mostListed: Boolean, $mostRated: Boolean, $skillPreference: Boolean) {
    artists(filter: $filter, excludes: $excludes, featured: $featured, mostListed: $mostListed, mostRated: $mostRated, skillPreference: $skillPreference) {
      id
      name
      image
      smallImage
      scaledImage
      slug
      skillAverages {
        id
        skill {
          id
          name
        }
        average
        ratingSum
        ratingCount
      }
    }
  }
`

export const SIGNUP_MUTATION = gql`
  mutation SignupMutation($email: String!, $password: String!, $name: String!, $location: String, $image: String, $userId: ID) {
    signup(email: $email, password: $password, name: $name, location: $location, image: $image, userId: $userId) {
      token
      user {
        id
        name
        image
        email
      }
    }
  }
`

export const LOGIN_MUTATION = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        id
        name
        image
        onboardedArtists
        onboardedSkills
        location
      }
    }
  }
`

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser($userId: ID!, $imageUrl: String, $name: String, $email: String, $location: String, $artistIds: [ID!], $skillIds: [ID!]) {
    updateUser(userId: $userId, imageUrl: $imageUrl, name: $name, email: $email, location: $location, artistIds: $artistIds, skillIds: $skillIds) {
      id
      name
      onboardedArtists
      onboardedSkills
      location
      image
      roles {
        name
      }
    }
  }
`

export const REQUEST_RESET_PASSWORD_MUTATION = gql`
  mutation requestResetPassword($email: String!) {
    requestResetPassword(email: $email) {
      id
      name
    }
  }
`

export const RESET_PASSWORD_MUTATION = gql`
  mutation resetPassword($email: String!, $password: String!, $confirmPassword: String!, $resetPasswordToken: String!) {
    resetPassword(email: $email, password: $password, confirmPassword: $confirmPassword, resetPasswordToken: $resetPasswordToken) {
      token
      user {
        id
        name
        image
        onboardedArtists
        onboardedSkills
        location
      }
    }
  }
`

export const RECENT_USERS_QUERY = gql`
  query recentUsers {
    recentUsers {
      id
      location
      name
      image
      favoriteArtists {
        id
        name
        image
        smallImage
        scaledImage
        slug
      }
      skillRankings {
        id
        skillRankings {
          id
          name
        }
      }
    }
  }
`

export const SPOTIFY_LOGIN_MUTATION = gql`
  mutation SpotifyLoginMutation($accessToken: String) {
    spotifyLogin(accessToken: $accessToken) {
      token
      user {
        id
        name
        image
        onboardedArtists
        onboardedSkills
        location
      }
    }
  }
`

export const FACEBOOK_LOGIN_MUTATION = gql`
  mutation FacebookLoginMutation($accessToken: String!, $email: String!) {
    facebookLogin(accessToken: $accessToken, email: $email) {
      token
      user {
        id
        name
        image
        onboardedArtists
        onboardedSkills
        location
      }
    }
  }
`

export const GOOGLE_LOGIN_MUTATION = gql`
  mutation GoogleLoginMutation($accessToken: String!, $email: String!) {
    googleLogin(accessToken: $accessToken, email: $email) {
      token
      user {
        id
        name
        image
        onboardedArtists
        onboardedSkills
        location
      }
    }
  }
`

export const USERS_CONNECTION_QUERY = gql`
  query usersConnection($first: Int, $cursor: String) {
    usersConnection(first: $first, after: $cursor) {
      edges {
        cursor
       	node {
          id
          location
          name
          image
          favoriteArtists {
            id
            name
            image
            smallImage
            scaledImage
            slug
          }
          skillRankings {
            id
            skillRankings {
              id
              name
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`
