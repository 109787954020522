import React, { useRef } from 'react';
import { useQuery } from 'react-apollo-hooks';
import { isMobile } from "react-device-detect";
import { Link } from 'react-router-dom'

import {
  RECENT_USERS_QUERY,
  UPDATE_USER_MUTATION,
  USERS_CONNECTION_QUERY
} from '../api/homepage'

import {
  updateUserProfile
} from '../mutations/homepage'

import Button from './Button'
import Input from './Input'
import Icon from './Icon/Icon'
import Header from '../components/Header';
import RecommendedUser from '../components/RecommendedUser';
import LocationAutocomplete from './LocationAutocomplete'
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';

import fullLogo from '../images/full-logo.svg'
import checkMark from '../images/check-mark.svg'
import loadingIcon from '../images/load-disk.png';
import signupCommunityMessage from '../images/signup-community-msg.svg';
import signupCommunityMobile from '../images/growing-community-mobile.svg';
import userMsgIconDown from '../images/carrot-down.svg';
import userMsgIconUp from '../images/carrot-up.svg';
import facebookIcon from '../images/facebook-icon.svg'
import googleIcon from '../images/google-icon.svg'
import defaultUserImage from '../images/default-user.svg';
import editIcon from '../images/edit-icon.svg';

import { colors } from '../styles/defaultTheme'
import { handleArtistClick } from '../utils'

import Resizer from "react-image-file-resizer";
import cloudinaryConfig from '../cloudinaryConfig'
const unsignedUploadPreset = cloudinaryConfig.unsigned_upload_preset;

const handleUserScroll = (e, fetchMore, data, state, dispatch, setLoadingMore) => {
  const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
  if (bottom) {

    if(setLoadingMore) {
      setLoadingMore(true)
    } else {
      dispatch({payload: {loading: true}})
    }

    fetchMore({
      variables: {
        first: 20,
        cursor: data.usersConnection.pageInfo.endCursor
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult.usersConnection.edges;
        const pageInfo = fetchMoreResult.usersConnection.pageInfo;

        if(setLoadingMore) {
          setLoadingMore(false)
        } else {
          dispatch({payload: {loading: false}})
        }
        return newEdges.length
        ? {
          usersConnection: {
            __typename: prevResult.usersConnection.__typename,
            edges: [...prevResult.usersConnection.edges, ...newEdges],
            pageInfo
          }
        }
        : prevResult
      }
    })
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    default:
      return { ...state, ...action.payload };
  }
};

const handleImageChange = (e, dispatch, state) => {

  const file = e.target.files[0]

  let quality = 100;
  //4MB image file
    if (file.size > 4000000) {
      quality = 90;
    }
  //8MB image file
    if (file.size > 8000000) {
      quality = 85;
    }

    // https://res.cloudinary.com/demo/image/upload/ar_2.5,c_crop/sample.jpg

    Resizer.imageFileResizer(
      file, // the file from input
      400, // width
      400, // height
      "JPEG", // compress format WEBP, JPEG, PNG
      quality, // quality
      0, // rotation
      (image) => {

        const fd = new FormData();
        fd.append("upload_preset", unsignedUploadPreset);
        fd.append("file", image);
        fd.append("folder", process.env.REACT_APP_USER_IMAGE_FOLDER);

        // fd.append("public_id", "test/" + state.name);
        // fd.append("eager", "ar_2.5,c_crop");


        dispatch({payload: {imageFile: image, formData: fd, imageSrc: URL.createObjectURL(file)}})
        // You upload logic goes here

      },
      "base64" // blob or base64 default base64
      );

  // formData.append("timestamp", xxx);
  // fd.append('file', {type: 'image/png', name: e.target.files[0].name});
  // dispatch({payload: {imageFile: e.target.files[0], formData: fd}})
}


const renderButtonMsg = (state) => {
  if(state.login) {
    return "Login"
  } else if(state.resetPassword) {
    return "Create Password"
  } else if(state.forgetPassword) {
    return "Request Password Reset"
  } else if(!state.imageFile && !state.addImage) {
    return "Continue"
  } else return "Get Access"
}

const renderOnboardingMsg = (state, signupScreen, update, dispatch) => {

  if(update) {
    return (
      <Link className="onboarding__account-msg bottom" to="/update-skills"><p>update preferences</p></Link>
    )
  } else if(signupScreen) {
    return (
      <p
        onClick={() => dispatch({payload: {password: state.resetPassword ? "" : state.password, confirmPassword: "",login: state.forgetPassword || state.resetPassword ? false : !state.login, forgetPassword: false, resetPassword: false, addImage: false}})}
        className="onboarding__account-msg bottom"
      >
        already have an account? <span className="onboarding__account-cta">log in here!</span>
      </p>
    )
  } else if(!state.login) {
    return (
      <p
        onClick={() => dispatch({payload: {password: state.resetPassword ? "" : state.password, confirmPassword: "",login: state.forgetPassword || state.resetPassword ? false : !state.login, forgetPassword: false, resetPassword: false, addImage: false}})}
        className="onboarding__account-msg bottom"
      >
        back to sign up
      </p>
    )
  }
}

const renderImageStep = (state, dispatch) => {
  if(state.addImage) {
    return (
      <div className="onboarding__image-step__wrapper">
        <p className="onboarding__image-step__title">Add profile image</p>
        {renderImage(state, dispatch)}
        <p
          onClick={() => dispatch({payload: {addImage: false}})}
          className="onboarding__image-step__back-btn"
        >
          Back
        </p>
      </div>
    )
  }
}

const renderImage = (state, dispatch) => {
  if(state.imageFile) {
    return (
      <form className="onboarding__add-image__form" encType={'multipart/form-data'}>
      <label htmlFor="image-file-input" className="onboarding__add-image__label">
        <div
          style={{backgroundImage: `url(${state.imageSrc && state.imageSrc})`}}
          className={`onboarding__image-step__user-image${state.imageHovered ? " hovered" : ""}`}
          onMouseEnter={() => dispatch({payload: {imageHovered: true}})}
          onMouseLeave={() => dispatch({payload: {imageHovered: false}})}
        >
          {state.imageHovered && <img src={editIcon} alt="edit icon" className="onboarding__image-step__edit-icon" />}
        </div>
        </label>
        <input
          type="file"
          id="image-file-input"
          accept="image/*"
          onChange={(e) => handleImageChange(e, dispatch, state)}
        />
      </form>
    )
  } else {
    return (
      <form className="onboarding__add-image__form" encType={'multipart/form-data'}>
      <label htmlFor="image-file-input" className="onboarding__add-image__label">
        <img
          alt="add profile image"
          className="onboarding__image-step__default"
          src={defaultUserImage}
        />
        </label>
        <input
          type="file"
          id="image-file-input"
          accept="image/*"
          onChange={(e) => handleImageChange(e, dispatch, state)}
        />
      </form>
    )
  }
}

const handleSubmitMutation = (state, authMutation, requestResetMutation, resetPasswordMutation, e) => {
  e.preventDefault()

  if(!state.login && !state.forgetPassword && !state.resetPassword && state.email.length > 3 && state.name.length > 2 && state.password.length > 6 && !state.imageFile && !state.addImage) {
    return
  } else if(state.forgetPassword) {
    requestResetMutation()
  } else if(state.resetPassword) {
    resetPasswordMutation()
  } else {
    authMutation(e)
  }
}

const handleModalClose = (dispatch, history, shareUserId) => {
  dispatch({payload: {signupModal: false, updateModal: false, login: false, resetPassword: false, forgetPassword: false, addImage: false}})
  if(shareUserId) {
    history.push('/' + shareUserId)
  } else {
    history.push('/')
  }
}

const handleSubmitClick = (e, state, dispatch, authMutation, requestResetMutation, resetPasswordMutation, alert) => {
  e.preventDefault()

  if(!state.login && !state.forgetPassword && !state.resetPassword && (state.email.length < 3 || state.name.length < 2)) {
    alert.show("Please input name & email")
  } else if (!state.login && !state.forgetPassword && !state.resetPassword && state.email.length > 3 && state.name.length > 2 && state.password.length > 6 && !state.imageFile && !state.addImage) {
    e.preventDefault()
    dispatch({payload: {addImage: true, login: false, forgetPassword: false, resetPassword: false}})
  } else {
    handleSubmitMutation(state, authMutation, requestResetMutation, resetPasswordMutation, e)
  }
}

const Onboarding = ({
  state,
  dispatch,
  authMutation,
  update,
  resetPasswordMutation,
  requestResetMutation,
  spotifyLoginMutation,
  facebookLoginMutation,
  googleLoginMutation,
  updateProfileMutation,
  currentUser,
  handleAuthFailure,
  history,
  executeScroll,
  shareUserId,
  alert
}) => {

  const signupScreen = !state.login && !state.forgetPassword && !state.resetPassword

  const {
    data: recentUsers,
    loading: recentUsersLoading,
    error: recentUsersError,
    fetchMore
  } = useQuery(USERS_CONNECTION_QUERY, {
    variables: {
      first: 20,
    }
  })

  return (
    <div
      className={`onboarding__wrapper${update ? " update-profile" : ""}`}
    >
      {!update && <img style={{display: recentUsersError && "none"}} src={signupCommunityMessage} className="skill-step__middle-msg onboarding__middle-msg" alt="Join our growing community" />}
      {recentUsersLoading && !update && !state.mediumScreensize &&
        <div className="modal-users__users">
          <div className="loading__wrapper">
            <img className="loading__icon" alt="loading" src={loadingIcon} />
          </div>
        </div>
      }
      {((state.mediumScreensize && state.showUsers) || !state.mediumScreensize) && !update &&
      <div
        style={{display: recentUsersError && !state.showUsers && "none"}}
        className={`modal-users__users${(recentUsersLoading || recentUsersError) && !update ? " users-loading" : ""}`}
        onScroll={(e) => handleUserScroll(e, fetchMore, recentUsers, state, dispatch)}
      >
        <div onClick={() => dispatch({payload: {showUsers: !state.showUsers}})} className="onboarding__mobile-users__cta__top">
          <img src={signupCommunityMobile} className="skill-step__msg-mobile" alt="Check out our growing community" />
          <img alt={state.showUsers ? "close" : "open"} src={state.showUsers ? userMsgIconDown : userMsgIconUp} className="skill-step__msg-icon" />
        </div>
        {recentUsersLoading && state.mediumScreensize && !update &&
          <div className="loading__wrapper">
            <img className="loading__icon" alt="loading" src={loadingIcon} />
          </div>
        }
        {recentUsersError && (state.mediumScreensize) && !update &&
          <div className="loading__wrapper">
            <p>Error Loading Users</p>
          </div>
        }
        {recentUsers && recentUsers.usersConnection && recentUsers.usersConnection.edges.map((user, index) => {
          return (
            <RecommendedUser
              key={index}
              user={user.node}
              artists={true}
              index={index}
              recentUsersLoading={recentUsersLoading}
              onArtistClick={(artist) => handleArtistClick(artist, history)}
              onboarding={currentUser && (!currentUser.onboardedArtists || !currentUser.onboardedSkills)}
              currentUser={currentUser}
              customClass={state.loading ? " loading-more" : ""}
            />
          )
        })}
        {state.loading && <div className="loading__wrapper user-profile__loading-wrapper onboarding-load">
          <img className="loading__icon" alt="loading" src={loadingIcon} />
        </div>}
        <div className="bump"></div>
      </div>}
      {!update && <div style={{display: state.showUsers && "none"}} onClick={() => dispatch({payload: {showUsers: true}})} className="onboarding__mobile-users__cta">
        <img src={signupCommunityMobile} className="skill-step__msg-mobile" alt="Check out our growing community" />
        <img alt={state.showUsers ? "close" : "open"} src={state.showUsers ? userMsgIconDown : userMsgIconUp} className="skill-step__msg-icon" />
      </div>}
      <Icon
        handleclick={() => handleModalClose(dispatch, history, shareUserId)}
        customClass="onboarding__close-icon"
        color={colors.beachHouseLight}
        width="20px"
        height="20px"
        type="close"
        style={{opacity: isMobile && "1"}}
      />
      <div style={{width: recentUsersError && "100%"}} className={`onboarding__container ${state.showUsers ? "blur" : ""}`}>
        <div className="onboarding__logo-wrapper">
          <img className="onboarding__logo-image" alt="Rapchr" src={fullLogo} />
          {!update && <p className="onboarding__message">A social platform for rap fans, sign up to join the waitlist and explore this sneak peek.</p>}
          {update && !state.forgetPassword && <p className="onboarding__message">Update your profile.</p>}
          {state.forgetPassword && <p className="onboarding__message">Input to recieve forget password email.</p>}
        </div>
        <form onSubmit={(e) => handleSubmitClick(e, state, dispatch, authMutation, requestResetMutation, resetPasswordMutation, alert)} >
        {renderImageStep(state, dispatch)}
        {signupScreen && !state.addImage && <Input
          placeholder="name"
          icon="name"
          iconClass="onboarding__icon"
          customClass="onboarding__input"
          iconStyle={{position: "relative", top: "3px"}}
          wrapperCustomeClass="onboarding__input-wrapper"
          onChange={(e) => dispatch({payload: {name: e.target.value}})}
          value={state.name}
          autoFocus={!isMobile}
          onFocus={executeScroll}
          onBlur={executeScroll}
        />}
        {!state.addImage && <Input
          placeholder="email"
          icon="email"
          iconWidth="24px"
          iconHeight="16px"
          iconStyle={{position: "relative", top: "2px", marginRight: "5px"}}
          iconClass="onboarding__icon"
          customClass="onboarding__input"
          wrapperCustomeClass="onboarding__input-wrapper"
          onChange={(e) => dispatch({payload: {email: e.target.value}})}
          value={state.email}
          onFocus={executeScroll}
          onBlur={executeScroll}
        />
        }
        {signupScreen && !state.addImage && <LocationAutocomplete dispatch={dispatch} state={state} onLocationSelect={(location) => dispatch({payload: {location: location}})} />}
        {!state.forgetPassword && !update && !state.addImage && <Input
          placeholder={state.forgetPassword ? "new password" : "password"}
          icon="password"
          iconClass="onboarding__icon"
          customClass="onboarding__input"
          iconStyle={{position: "relative", top: "3px", left: "1px"}}
          wrapperCustomeClass="onboarding__input-wrapper"
          onChange={(e) => dispatch({payload: {password: e.target.value}})}
          value={state.password}
          inputType="password"
          onFocus={executeScroll}
          onBlur={executeScroll}
        />}
        {state.login && <p
          onClick={() => dispatch({payload: {login: false, forgetPassword: true, resetPassword: false, addImage: false }})}
          className="onboarding__password-msg"
        >
           Forget your password?
         </p>
       }
        {state.resetPassword &&
          <Input
            placeholder="confirm password"
            icon="password"
            iconClass="onboarding__icon"
            customClass="onboarding__input"
            iconStyle={{position: "relative", top: "3px", left: "1px"}}
            wrapperCustomeClass="onboarding__input-wrapper"
            onChange={(e) => dispatch({payload: {confirmPassword: e.target.value}})}
            value={state.confirmPassword}
            inputType="password"
            onFocus={executeScroll}
            onBlur={executeScroll}
          />
        }
        {signupScreen && !state.addImage &&
          <div className="onboarding__add-image__wrapper">
            <form className="onboarding__add-image__form" encType={'multipart/form-data'}>
              <label className="onboarding__add-image__label" htmlFor="file-input">
                {state.imageFile ?
                  <img alt="image uploaded" className="onboarding__add-image__check-mark" src={checkMark} /> :
                  <Icon
                    customClass="onboarding__add-image__add-icon"
                    color={colors.beachHouse}
                    width="15px"
                    height="15px"
                    type="add"
                  />
                }
                <p className="onboarding__add-image__text">Add Profile Image</p>
              </label>
              <input
                type="file"
                id="file-input"
                accept="image/*"
                onChange={(e) => handleImageChange(e, dispatch, state)}
              />
            </form>
            {update && !state.forgetPassword && <p
              onClick={() => dispatch({payload: {login: false, forgetPassword: true, resetPassword: false, addImage: false }})}
              className="onboarding__password-msg reset-password-msg"
            >
               Reset Password
             </p>
           }
          </div>
        }
       {update && state.forgetPassword && <p
         onClick={() => dispatch({payload: {login: false, forgetPassword: false, resetPassword: false, addImage: false }})}
         className="onboarding__password-msg"
       >
          Back to Update Profile
        </p>
      }
        {!update && <div className="onboarding__button-wrapper">
          <Button
            disabled={state.loadingBarProgress > 0 || state.email.length < 3}
            handleclick={(e) => handleSubmitClick(e, state, dispatch, authMutation, requestResetMutation, resetPasswordMutation, alert)}
            customClass="onboarding__button"
            gradient={true}
          >
            {renderButtonMsg(state)}
          </Button>
        </div>}
        {update && <div className="onboarding__button-wrapper">
          <Button
            disabled={(state.loadingBarProgress > 0) || (state.email.length < 3 && state.name.length < 2 && state.location.length < 3 && !state.formData)}
            handleclick={state.forgetPassword ? (e) => handleSubmitMutation(state, authMutation, requestResetMutation, resetPasswordMutation, e) : (e) => updateProfileMutation(e)}
            customClass="onboarding__button"
            gradient={true}
          >
            {state.forgetPassword ? "Request Password Reset" : "Update"}
          </Button>
        </div>}
        {state.login && <p
          className="onboarding__account-msg no-pointer"
        >
          Or Sign in With
        </p>}
        {state.login && <div className="onboarding__social-auth__wrapper">
         <GoogleLogin
           clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
           render={renderProps => (
            <img alt="Google" className="onboarding__google-icon__image" src={googleIcon} onClick={renderProps.onClick} />
          )}
           onSuccess={(response) => googleLoginMutation(response)}
           onFailure={() => handleAuthFailure()}
           cookiePolicy={'single_host_origin'}
           className="onboarding__google-icon"
         />
         <FacebookLogin
           appId={process.env.REACT_APP_FACEBOOK_ID}
           autoLoad={false}
           fields="email"
           callback={(response) => facebookLoginMutation(response)}
           icon={<img alt="Facebook" className="onboarding__facebook-icon__image" src={facebookIcon} />}
           cssClass="onboarding__facebook-icon"
           onFailure={() => handleAuthFailure()}
         />
        </div>}
        </form>
          {renderOnboardingMsg(state, signupScreen, update, dispatch)}
          {state.login &&  <p
              onClick={() => dispatch({payload: {password: state.resetPassword ? "" : state.password, confirmPassword: "",login: state.forgetPassword || state.resetPassword ? false : !state.login, forgetPassword: false, resetPassword: false, addImage: false}})}
              className="onboarding__account-msg bottom"
            >
              still need access? <span className="onboarding__account-cta">sign up here!</span>
            </p>}
      </div>
      <div style={{display: isMobile && "none"}} className="onboarding__bottom-gradient"></div>
    </div>
  )
}

export default Onboarding;
