import gql from 'graphql-tag'

export const VOTE_MUTATION = gql`
  mutation graphicVote($ratingGroupId: ID!, $positive: Boolean!) {
    graphicVote(ratingGroupId: $ratingGroupId, positive: $positive) {
      id
      positive
    }
  }
`

export const VOTE_TOGGLE_MUTATION = gql`
  mutation toggleGraphicVoteView($ratingGroupId: ID!, $turnOn: Boolean!) {
    toggleGraphicVoteView(ratingGroupId: $ratingGroupId, turnOn: $turnOn) {
      id
      votesTurnedOn
    }
  }
`

export const RATE_ARTIST_MUTATION = gql`
  mutation rateArtist($ratings: [RatingInput!]!, $artistId: ID!, $artistSkillAverages: [SkillAverageInput!]!) {
    rateArtist(ratings: $ratings, artistId: $artistId, artistSkillAverages: $artistSkillAverages) {
      id
      name
      image
      scaledImage
      deceased
      hideRatings
      slug
      ratedCount
      currentUserRatings {
        id
        ratings
        votesTurnedOn
        voteSum
        currentUserVote {
          id
          positive
        }
        user {
          id
          name
          email
          roles {
            name
          }
        }
      }
      skillAverages {
        id
        skill {
          id
          name
          description
          categories
        }
        average
        ratingCount
      }
    }
  }
`

export const UPDATE_RATING_MUTATION = gql`
  mutation updateArtistRatings($ratings: [RatingInput!]!, $artistId: ID!, $artistSkillAverages: [SkillAverageInput!]!) {
    updateArtistRatings(ratings: $ratings, artistId: $artistId, artistSkillAverages: $artistSkillAverages) {
      id
      name
      image
      scaledImage
      deceased
      hideRatings
      slug
      ratedCount
      currentUserRatings {
        id
        ratings
        votesTurnedOn
        voteSum
        currentUserVote {
          id
          positive
        }
        user {
          id
          name
          email
          roles {
            name
          }
        }
      }
      skillAverages {
        id
        skill {
          id
          name
          description
          categories
        }
        average
        ratingCount
      }
    }
  }
`


export const SINGLE_ARTIST_QUERY = gql`
  query singleArtist($artistId: ID, $slug: String) {
    singleArtist(artistId: $artistId, slug: $slug) {
      id
      name
      image
      scaledImage
      deceased
      hideRatings
      slug
      ratedCount
      currentUserRatings {
        id
        ratings
        votesTurnedOn
        voteSum
        currentUserVote {
          id
          positive
        }
        user {
          id
          name
          email
          roles {
            name
          }
        }
      }
      skillAverages {
        id
        skill {
          id
          name
          description
          categories
        }
        average
        ratingCount
      }
    }
  }
`

export const USER_RATING_QUERY = gql`
  query userArtistRatings($userId: ID, $slug: String, $ratingGroupId: ID) {
    userArtistRatings(slug: $slug, userId: $userId, ratingGroupId: $ratingGroupId) {
      id
      ratings
      votesTurnedOn
      voteSum
      currentUserVote {
        id
        positive
      }
      user {
        id
        name
        roles {
          name
        }
      }
      artist {
        id
        name
        image
        slug
        ratedCount
      }
    }
  }
`

export const UPDATE_USER_SHARES_MUTATION = gql`
  mutation updateShares($download: Boolean, $share: Boolean) {
    updateShares(download: $download, share: $share) {
      id
      downloads
      shares
    }
  }
`

export const GRAPHIC_REC_QUERY = gql`
  query recArtists($artistId: ID!, $limit: Int) {
    recArtists(artistId: $artistId, limit: $limit) {
      id
      name
      image
      smallImage
      slug
    }
  }
`
