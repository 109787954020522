import React from 'react';

const NameIcon = ({
  height,
  width,
  color,
  handleclick,
  style,
  ...rest
}) => (
  <svg onClick={handleclick} style={style} width={width} height={height} viewBox="0 -8 24 24" { ...rest }>
      <g stroke="none" strokeWidth="2" fill="none" fillRule="evenodd">
          <g transform="translate(-409.000000, -295.000000)">
              <g transform="translate(280.000000, 118.000000)">
                  <g transform="translate(89.000000, 103.000000)">
                      <g transform="translate(24.137143, 57.280000)">
                          <g transform="translate(14.628571, 10.971429)">
                              <g transform="translate(2.194286, 6.582857)" stroke={color} strokeLinecap="square" strokeWidth="1">
                                  <path d="M0,5.12 L13.8971429,5.12"></path>
                                  <path d="M0,0.365714286 L9.50857143,0.365714286"></path>
                              </g>
                              <rect x="0" y="0" width="18.2857143" height="18.2857143"></rect>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </g>
  </svg>
);

export default NameIcon;
