import React, {useState} from 'react'
import PropTypes from 'prop-types'

import { colors } from '../styles/defaultTheme';

import Icon from './Icon/Icon';

const handleOnFocus = (focusFunc, whenOnFocus, setWhenOnFocus) => {
  document.body.scrollTop = 0
  focusFunc && focusFunc()
  setWhenOnFocus(!whenOnFocus)
}

const Input = ({
  onFocus,
  onChange,
  inputType,
  disabled,
  placeholder,
  icon,
  handleclick,
  iconClass,
  onBlur,
  customClass,
  wrapperCustomeClass,
  value,
  required,
  iconWidth,
  iconHeight,
  fieldCustomClass,
  iconStyle,
  autoFocus
}) => {

  const [whenOnFocus, setWhenOnFocus] = useState(false);

  return (
    <div className={wrapperCustomeClass ? `${wrapperCustomeClass} input-wrapper` : "input-wrapper"}>
      <div className={fieldCustomClass ? `${fieldCustomClass} field` : "field"}>
        {icon &&
          <Icon
            color={whenOnFocus ? colors.beachHouse : `${colors.beachHouse}70`}
            style={iconStyle}
            width={iconWidth}
            height={iconHeight}
            type={icon}
            customClass={iconClass ? `${iconClass} input-icon` : "input-icon"}
          />
        }
        <input
          value={value}
          placeholder={placeholder}
          inputtype={inputType}
          required={required}
          type={inputType}
          onChange={onChange}
          onClick={handleclick}
          onFocus={() => handleOnFocus(onFocus, whenOnFocus, setWhenOnFocus)}
          onBlur={() => handleOnFocus(onBlur, whenOnFocus, setWhenOnFocus)}
          className={customClass ? `${customClass} input` : "input"}
          autoFocus={autoFocus}
        />
      </div>
    </div>
  )
}

Input.propTypes = {
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  handleclick: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  iconStyle: PropTypes.object,
  iconClass: PropTypes.string,
  customClass: PropTypes.string,
  wrapperCustomeClass: PropTypes.string,
  inputType: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.string,
  required: PropTypes.bool,
  fieldCustomClass: PropTypes.string,
  iconName: PropTypes.string,
  iconWidth: PropTypes.string,
  iconHeight: PropTypes.string
}

Input.defaultProps = {
  onFocus: () => {},
  onChange: () => {},
  inputType: "",
  disabled: false,
  placeholder: "Name",
  handleclick: () => {},
  iconClass: "",
  onBlur: () => {},
  value: "",
  iconWidth: "32px",
  iconHeight: "32px"
}

export default Input;
