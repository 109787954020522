import React, { Component } from 'react';
import PropTypes from 'prop-types'

import Icon from './Icon/Icon'
import { colors } from '../styles/defaultTheme';


const SearchBar = ({
  results,
  loading,
  error,
  style,
  disabled,
  filter,
  onChange,
  placeholder,
  onHandleClick,
  searchIconWidth,
  searchIconHeight,
  clearIconWidth,
  clearIconHeight,
  isMobile,
  handleFocus,
  ...props
}) => {
  return (
    <div className="search-bar-wrapper">
      <Icon color={colors.beachHouse} width={searchIconWidth} height={searchIconHeight} customClass="search-icon" type="search" />
      <input
        style={style}
        disabled={disabled}
        className={['searchbar-input'].join(' ')}
        value={filter}
        onChange={onChange}
        placeholder={placeholder}
        {...props}
        autoFocus={!isMobile}
        onFocus={handleFocus ? () => handleFocus() : () => {}}
        onBlur={handleFocus ? () => handleFocus() : () => {}}
      />
      {filter.length > 0 && <Icon handleclick={onHandleClick}  color={colors.beachHouse} width={clearIconWidth} height={clearIconHeight} customClass="clear-icon" type="close" />}
    </div>
  )
}

SearchBar.defaultProps = {
  results: [],
  loading: false,
  error: false,
  disabled: false,
  filter: '',
  onChange: () => {},
  placeholder: 'Search...',
  searchIconHeight: "1.6vw",
  searchIconHeight: "1.6vw",
  clearIconHeight: "1.2vw",
  clearIconHeight: "1.2vw"
}

SearchBar.propTypes = {
  disabled: PropTypes.bool,
  filter: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  searchIconWidth: PropTypes.string,
  searchIconHeight: PropTypes.string,
  clearIconWidth: PropTypes.string,
  clearIconHeight: PropTypes.string
}

export default SearchBar;
