import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router'

import melodiesIcon from '../images/skill-melodies.svg'
import storytellingIcon from '../images/skill-storytelling.svg'
import deliveryIcon from '../images/skill-delivery.svg'
import flowIcon from '../images/skill-flow.svg'
import adlibsIcon from '../images/skill-adlibs.svg'
import originalityIcon from '../images/skill-originality.svg'
import influenceIcon from '../images/skill-influence.svg'
import wordplayIcon from '../images/skill-wordplay.svg'
import catalogIcon from '../images/skill-catalog.svg'
import beatChoiceIcon from '../images/skill-beat-choice.svg'

import hundredIcon from '../images/hundred.svg'


import { CircularProgressbar } from 'react-circular-progressbar';


const ConditionalLink = ({ children, to, condition }) => (!!condition && to)
      ? <Link  style={{textDecoration: 'none', display: 'contents'}} to={to}>{children}</Link>
      : <>{children}</>;

const renderIcon = (skill) => {
  switch (skill.name.toLowerCase()) {
    case 'melodies':
      return melodiesIcon
      break
    case 'storytelling':
      return storytellingIcon
      break
    case 'delivery':
      return deliveryIcon
      break
    case 'catalog':
      return catalogIcon
      break
    case 'beat choice':
      return beatChoiceIcon
      break
    case 'originality':
      return originalityIcon
      break
    case 'flow':
      return flowIcon
      break
    case 'influence':
      return influenceIcon
      break
    case 'adlibs':
      return adlibsIcon
      break
    case 'wordplay':
      return wordplayIcon
      break
  }
}

const FeaturedArtist = ({
  artist,
  customClass,
  currentUser,
  profile,
  date,
  renderGraphic,
  smallScreensize,
  xSmallScreensize,
  openModal,
  userRatings,
  chooseArtist
}) => {
  const ratings = profile ? userRatings : artist.skillAverages
  const sortedArtistSkillAverages = ratings.sort((function(a, b) { return !profile ? b.average - a.average : b.rating - a.rating;}))

  return (
    <ConditionalLink to={"/rate/rapper/" + (artist.slug && artist.slug)} condition={currentUser}>
      <div
        onClick={() => chooseArtist(artist)}
        className={`featured-artist__wrapper ${customClass ? customClass : ""}`}
      >
        <div className="featured-artist__info">
          <div className="featured-artist__image" style={{backgroundImage: `url(${artist.image})`}}></div>
          <div className={`featured-artist__name${artist.name.length > 13 ? " large" : ""}${artist.name.length > 16 ? " x-large" : ""}`}>{artist.name}</div>
        </div>
        <div className="featured-artist__ratings">
        {sortedArtistSkillAverages.map((rating, index) => {
          if(index < 3) {
            return (
              <div key={index} className={`featured-artist__rating-wrapper${(rating.rating && Math.round(rating.rating * 10) == 100) || (rating.average && Math.round(rating.average * 10)) == 100 ? " perfect" : ""}`}>
                {(xSmallScreensize || !smallScreensize) && <img src={renderIcon(rating.skill)} className={`featured-artist__rating-name${(rating.skill && rating.skill.name.length > 9) || (rating.skillName && rating.skillName.length > 9) ? " x-large" : ""}${(rating.skill && rating.skill.name.length < 5) || (rating.skillName && rating.skillName.length < 5) ? " x-small" : ""}${(rating.skill && rating.skill.name.length < 7) || (rating.skillName && rating.skillName.length < 7) ? " small" : ""}${(rating.skill && rating.skill.name.length > 8) || (rating.skillName && rating.skillName.length > 8) ? " large" : ""}`} />}
                {smallScreensize && !xSmallScreensize && <p className={`featured-artist__rating-name ${(rating.skill && rating.skill.name.length > 10) || (rating.skillName && rating.skillName.length > 10) ? " large" : ""}`}>{(rating.skill && rating.skill.name) || (rating.skillName && rating.skillName)}<span className={`featured-artist__rating-score${rating.rating == 10 ? " large" : ""}`}>{profile ? Math.round(rating.rating * 10) : Math.round(rating.average * 10)}</span></p>}
                {(xSmallScreensize || !smallScreensize) && (rating.rating && Math.round(rating.rating * 10) != 100) || (rating.average && Math.round(rating.average * 10) != 100) && <p className={`featured-artist__rating-score`}>{profile ? Math.round(rating.rating * 10) : Math.round(rating.average * 10)}</p>}
                {(xSmallScreensize || !smallScreensize) && (rating.rating && Math.round(rating.rating * 10) == 100) || (rating.average && Math.round(rating.average * 10) == 100) && <img src={hundredIcon} className={`featured-artist__rating-score${Math.round(rating.rating) && Math.round(rating.rating) == 10 || Math.round(rating.average) && Math.round(rating.average) == 10 ? " perfect" : ""}`} />}
                <CircularProgressbar
                  style={{fontFamily: "Comfortaa", width: "4vw"}}
                  value={profile ? Math.round(rating.rating * 10) : Math.round(rating.average * 10)}
                  strokeWidth={4}
                  counterClockwise
                />
              </div>
            )
          }
        })}
        </div>
      </div>
    </ConditionalLink>
  )
}

export default withRouter(FeaturedArtist);
