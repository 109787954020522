import React, {useState, useEffect, useRef} from 'react';
import { useQuery, useMutation } from 'react-apollo-hooks';
import gql from 'graphql-tag'
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";

import {
  USERS_CONNECTION_QUERY
} from '../api/homepage'

import gradientElipse from '../images/gradient-elipse.svg';
import dragIcon from '../images/drag-icon.svg';
import loadingIcon from '../images/load-disk.png';
import mobileFavArtistStepMessage from '../images/growing-community-mobile.svg';
import userMsgIconDown from '../images/carrot-down.svg';
import userMsgIconUp from '../images/carrot-up.svg';
import favArtistStepMessage from '../images/favorite-artists-msg.svg';
import logoSingle from '../images/logo-single.svg'


import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SearchBar from '../components/SearchBar'
import Button from '../components/Button'
import ErrorPage from '../components/ErrorPage'
import RecommendedUser from '../components/RecommendedUser';
import Header from '../components/Header'
import ListGraphic from '../components/ListGraphic'
import Footer from '../components/Footer'

import { Helmet } from "react-helmet";
import WindowSizeListener from 'react-window-size-listener'
import { handleExcludes } from '../utils'
import { useAlert } from 'react-alert'
import { handleArtistClick } from '../utils'

const handleUserScroll = (e, fetchMore, data, state, dispatch, setLoadingMore) => {
  const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
  if (bottom) {

    if(setLoadingMore) {
      setLoadingMore(true)
    } else {
      dispatch({payload: {loading: true}})
    }

    fetchMore({
      variables: {
        first: 20,
        cursor: data.usersConnection.pageInfo.endCursor
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult.usersConnection.edges;
        const pageInfo = fetchMoreResult.usersConnection.pageInfo;

        if(setLoadingMore) {
          setLoadingMore(false)
        } else {
          dispatch({payload: {loading: false}})
        }
        return newEdges.length
        ? {
          usersConnection: {
            __typename: prevResult.usersConnection.__typename,
            edges: [...prevResult.usersConnection.edges, ...newEdges],
            pageInfo
          }
        }
        : prevResult
      }
    })
  }
}

const artistFilterArgument = (choice, filter, setFilter, skillPreference, setSkillPreference, featured, setFeatured, mostRated, setMostRated) => {

  if(filter.length > 0) {
    setFilter('')
  }

  if(choice === "skillPreference") {
    if(skillPreference && !filter.length) {
      setSkillPreference(false)
    } else {
      setSkillPreference(true)
      setMostRated(false)
      setFeatured(false)
    }
  } else if(choice === "featured") {
    if(featured && !filter.length) {
      setFeatured(false)
    } else {
      setFeatured(true)
      setSkillPreference(false)
      setMostRated(false)
    }
  } else if(choice === "mostRated") {
    if(mostRated && !filter.length) {
      setMostRated(false)
    } else {
      setMostRated(true)
      setFeatured(false)
      setSkillPreference(false)
    }
  }
}

const recommendationOptions = (filter, setFilter, skillPreference, setSkillPreference, featured, setFeatured, mostRated, setMostRated) => {
  return (
    <div className="rec-options__wrapper">
      <p
        onClick={() => artistFilterArgument("featured", filter, setFilter, skillPreference, setSkillPreference, featured, setFeatured, mostRated, setMostRated)}
        className={`rec-options__name${featured && !filter.length > 0 ? " active" : ''}`}>Featured</p>
      <p onClick={() => artistFilterArgument("mostRated", filter, setFilter, skillPreference, setSkillPreference, featured, setFeatured, mostRated, setMostRated)}
        className={`rec-options__name${mostRated && !filter.length > 0 ? " active" : ''}`}>Most Rated</p>
      <p
        onClick={() => artistFilterArgument("skillPreference", filter, setFilter, skillPreference, setSkillPreference, featured, setFeatured, mostRated, setMostRated)}
        className={`rec-options__name${skillPreference && !filter.length > 0 ? " active" : ''}`}>Recommended</p>
    </div>
  )
}

const handleOnDragEnd = (result, data, setData, excludes, setExcludes, alert) => {
  const { destination, source, draggableId } = result;

  if (!destination) return;
  if (
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  ) {
    return;
  }

  const start = data.areas[source.droppableId]
  const finish = data.areas[destination.droppableId]

  if(start !== finish) {
    const artistIds = Array.from(start.artistIds);
    artistIds.splice(source.index, 1);
    if(finish.artist && finish.artist.id) {
      setExcludes(handleExcludes(finish.artist.id, excludes))
      artistIds.push(finish.artist.id)
    }

    const newStart = {
      ...start,
      artistIds: artistIds
    }
    const newFinish = {
      ...finish,
      artist: data.artists[draggableId]
    }
    const newState = {
      areaOrder: data.areaOrder,
      artists: data.artists,
      areas: {
        ...data.areas,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish
      }
    }

    setData(newState)
    setExcludes(handleExcludes(draggableId, excludes))
  }
}

const handleRemoveArtist = (artistChoice, area, data, setData, excludes, setExcludes) => {

    if(!artistChoice.name) {
      return
    }
    const newSearchResults = data.areas.searchResults.artistIds;
    newSearchResults.push(artistChoice.id)

    const newSearchResultsArea = {
      id: 'searchResults',
      artistIds: newSearchResults
    }

    const newChoiceArea = {
      ...area,
      artist: {}
    }

    const newState = {
      areaOrder: data.areaOrder,
      artists: data.artists,
      areas: {
        ...data.areas,
        searchResults: newSearchResultsArea,
        [area.id]: newChoiceArea
      }
    }
    setData(newState)
    setExcludes(handleExcludes(artistChoice.id, excludes))
}


const onDragUpdate = (update, setDragIsActive) => {
  const { destination } = update;
  destination && setDragIsActive(true)
}

const getMobileOpenArtist = (data) => {
  if(!data.areas["pick-1"].artist.id) {
    return data.areas["pick-1"]
  } else if(!data.areas["pick-2"].artist.id) {
    return data.areas["pick-2"]
  } else if(!data.areas["pick-3"].artist.id) {
    return data.areas["pick-3"]
  } else if(!data.areas["pick-4"].artist.id) {
    return data.areas["pick-4"]
  } else if(!data.areas["pick-5"].artist.id) {
    return data.areas["pick-5"]
  }
}

const handleMobileClick = (artistOption, data, setData, excludes, setExcludes) => {

  const start = data.areas["searchResults"]
  const finish = getMobileOpenArtist(data)
  const artistIds = Array.from(start.artistIds);
  if(finish.artist && finish.artist.id) {
    setExcludes(handleExcludes(finish.artist.id, excludes))
    artistIds.push(finish.artist.id)
  }

  const newStart = {
    ...start,
    artistIds: artistIds
  }
  const newFinish = {
    ...finish,
    artist: data.artists[artistOption.id]
  }
  const newState = {
    areaOrder: data.areaOrder,
    artists: data.artists,
    areas: {
      ...data.areas,
      [newStart.id]: newStart,
      [newFinish.id]: newFinish
    }
  }

  setData(newState)
  setExcludes(handleExcludes(artistOption.id, excludes))
}

const renderDropArea = (artistChoice, index, area, data, setData, excludes, setExcludes) => {
  return (
    <Droppable key={area.id} droppableId={area.id}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="chosen-artist__wrapper"
          onClick={() => handleRemoveArtist(artistChoice, area, data, setData, excludes, setExcludes)}
          className={`chosen-artist__wrapper${snapshot.isDraggingOver ? " active" : ""}${artistChoice.name ? " artist-filled" : ""}`}
        >
          {snapshot.isDraggingOver || artistChoice.name &&
            <img className="chosen-artist__droppable-elipse" alt={artistChoice.name ? artistChoice.name : "rapper space"} src={gradientElipse} />
          }
          {artistChoice.name
            ? <div
                className="chosen-artist__image"
                style={{backgroundImage: `url(${artistChoice.image})`}}
              >
              </div>
            : <p className="chosen-artist__name">{index + 1}</p>
          }
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

const renderDraggableArtist= (artistOption, index) => {
  return (
    <Draggable key={artistOption.id} draggableId={artistOption.id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`artist-option__wrapper${snapshot.isDragging ? " active" : ""}`}
        >
          {artistOption.currentUserRatings && <div className="artist-option__rated">
          </div>}
          <img alt="drag" src={dragIcon} className="artist-option__drag-icon" />
          <div
            className="artist-option__image"
            style={{backgroundImage: `url(${artistOption.image})`}}
          >
          </div>
          <p className="artist-option__name">{artistOption.name}</p>
        </div>
      )}
     </Draggable>
  )
}

const renderMobileArtist = (artistOption, index, data, setData, excludes, setExcludes) => {
  return (
    <div
      key={index}
      className="artist-option__wrapper"
      onClick={formatChosenArtists(data).length > 4 ? () => {} : () => handleMobileClick(artistOption, data, setData, excludes, setExcludes)}
    >
      {artistOption.currentUserRatings && <div className="artist-option__rated">
      </div>}
      <div
        className="artist-option__image"
        style={{backgroundImage: `url(${artistOption.smallImage ? artistOption.smallImage : artistOption.image})`}}
      >
      </div>
      <p className={`artist-option__name${artistOption.name.length > 13 ? " large" : ""}`}>{artistOption.name}</p>
    </div>
  )
}

const renderNoResults = (currentUser, skillPreference) => {
  return (
    <div className="search-error__wrapper">
      {skillPreference ? <p className="search-error__message">Check again later or update your skill preferences <a className="search-error__link" href="/update-skills">here</a></p> :
        <p className="search-error__message">No search results.</p>
      }
    </div>
  )
}

const renderRightSide = (loadingMore, recentUsers, smallScreensize, showUsers, setShowUsers, history, currentUser, recentUsersLoading, recentUsersError, setLoadingMore, fetchMore) => {
  if ((smallScreensize && showUsers) || !smallScreensize) {
    return (
      <div
        onScroll={(e) => handleUserScroll(e, fetchMore, recentUsers, false, false, setLoadingMore)}
        className="skill-step__right-hand"
      >
       <div onClick={() => setShowUsers(!showUsers)} className="skill-step__mobile-users__cta__top">
         <img src={mobileFavArtistStepMessage} className="skill-step__msg-mobile onboarded" alt="Our Growing Community" />
         <img alt={showUsers ? "close" : "open"} src={showUsers ? userMsgIconDown : userMsgIconUp} className="skill-step__msg-icon" />
       </div>
       <div className="skill-step__top-gradient"></div>
       <div className={`skill-step__users${recentUsersLoading || recentUsersError ? " users-loading" : ""}`}>
         {recentUsersError &&
           <div className="loading__wrapper">
             <p>Error Loading Users</p>
           </div>
         }
         {recentUsersLoading &&
           <div className="loading__wrapper">
             <img className="loading__icon" alt="loading" src={loadingIcon} />
           </div>
         }
         {recentUsers && recentUsers.usersConnection && recentUsers.usersConnection.edges.map((user, index) => {
           return (
             <RecommendedUser
               key={index}
               user={user.node}
               artists={true}
               index={index}
               onArtistClick={(artist) => handleArtistClick(artist, history)}
               onboarding={currentUser && (!currentUser.onboardedArtists || !currentUser.onboardedSkills)}
               recentUsersLoading={recentUsersLoading}
               currentUser={currentUser}
               ratingPage={true}
               customClass={loadingMore ? " loading-more" : ""}
             />
           )
         })}
       </div>
       {loadingMore && <div className="loading__wrapper user-profile__loading-wrapper right-hand">
         <img className="loading__icon" alt="loading" src={loadingIcon} />
       </div>}
       <div className="skill-step__bottom-gradient"></div>
       <div className="bump"></div>
     </div>
    )
  }
}

const formatChosenArtists = (data) => {
  let chosenArtists = []
  const dataArray = Object.keys(data.areas).map(area => data.areas[area])
  dataArray.map((area) => {
    if(area && area.artist && area.artist.id) {
      chosenArtists.push(area.artist)
    }
  })

  return chosenArtists
}

const artistChoices = (artistSearchResults) => {
  const formattedArtists = {}

  artistSearchResults.artists && artistSearchResults.artists.map((artist) => {
    formattedArtists[artist.id] = { id: artist.id, image: artist.image, name: artist.name, currentUserRatings: artist.currentUserRatings ? artist.currentUserRatings.ratings : null}
  })

  return formattedArtists
}

const handleListRender = (setRenderListGraphic, listTitle, alert, executeScroll) => {
  if(listTitle.length < 1) {
    alert.removeAll()
    alert.show("Must have title!")
  } else {
    executeScroll()
    setRenderListGraphic(true)
  }
}

const onResize = (windowSize, smallScreensize, setSmallScreensize) => {
  if(windowSize.windowWidth < "768" && !smallScreensize) {
    setSmallScreensize(true)
  } else if(windowSize.windowWidth > "768" && smallScreensize) {
    setSmallScreensize(false)
  }
}

const calcQueryAmount = (smallScreensize) => {
  if(isMobile) {
    return 10
  } else if(smallScreensize) {
    return 4
  } else return 5
}


const ListBuilder = ({
  currentUser,
}) => {

  const [filter, setFilter] = useState('');
  const [excludes, setExcludes] = useState([]);
  const [skillPreference, setSkillPreference] = useState(false);
  const [featured, setFeatured] = useState(true);
  const [mostRated, setMostRated] = useState(false);
  const [showUsers, setShowUsers] = useState(false);
  const [smallScreensize, setSmallScreensize] = useState(window.innerWidth <= 768);
  const [renderListGraphic, setRenderListGraphic] = useState(false)
  const [listTitle, setListTitle] = useState("")
  const [loadingMore, setLoadingMore] = useState(false)

  const alert = useAlert();
  let history = useHistory();

  const {
    data: recentUsers,
    loading: recentUsersLoading,
    error: recentUsersError,
    fetchMore
  } = useQuery(USERS_CONNECTION_QUERY, {
    variables: {
      first: 20,
    }
  })

  const {
    data: artistSearchResults,
    loading: artistSearchResultsLoading,
    error: artistSearchResultsError
  } = useQuery(ARTISTS_QUERY, {
   variables: {
     filter,
     skillPreference,
     featured,
     mostRated,
     excludes,
     first: calcQueryAmount(smallScreensize)
   }
  });

  const formattedArtists = artistSearchResults ? artistChoices(artistSearchResults) : [];

  const initialData = {
    artists: formattedArtists,
    areas: {
      "searchResults": {
        id: "searchResults",
        artistIds: Object.keys(formattedArtists).map(artist => formattedArtists[artist].id)
      },
      "pick-1": {
        id: "pick-1",
        artist: {}
      },
      "pick-2": {
        id: "pick-2",
        artist: {}
      },
      "pick-3": {
        id: "pick-3",
        artist: {}
      },
      "pick-4": {
        id: "pick-4",
        artist: {}
      },
      "pick-5": {
        id: "pick-5",
        artist: {}
      }
    },
    areaOrder: ['pick-1', 'pick-2', 'pick-3', 'pick-4', 'pick-5']
  }

  const [data, setData] = useState(initialData);
  const [dragStarted, setDragStarted] = useState(false);
  const [dragIsActive, setDragIsActive] = useState(false);

  const favArtistRef = useRef(null)

  const executeScroll = favArtistRef && favArtistRef.current && isMobile ? () => window.scrollTo(0, favArtistRef.current.offsetTop) : () => {};

  useEffect(() => {
    if(!artistSearchResultsLoading && artistSearchResults) {
      const formattedArtists = artistChoices(artistSearchResults);

      const newSearchResultsArea = {
        id: 'searchResults',
        artistIds: Object.keys(formattedArtists).map(artist => formattedArtists[artist].id)
      }

      const newState = {
        areaOrder: data.areaOrder,
        artists: formattedArtists,
        areas: {
          ...data.areas,
          searchResults: newSearchResultsArea,
        }
      }
      setData(newState)
    }
  }, [artistSearchResults])

  return (
    <div ref={favArtistRef} className="list-build-page page">
      <Helmet>
        <title>Rap List Builder | Rapchr</title>
        <meta name="description" content={"Rate your favorite rappers! A social platform for music fans to explore and share their taste in hip-hop."} />
        <meta propery="og:title" content="Rap List Builder | Rapchr" />
        <meta propery="og:url" content={window.location.href} />
        <meta propery="og:description" content="Rate your favorite rappers! A social platform for music fans to explore and share their taste in hip-hop." />
        <meta propery="og:image"  content="https://res.cloudinary.com/rapchr/image/upload/v1620340519/full-racphr-logo_zqtbdx.png" />
        <meta propery="fb:app_id" content={process.env.REACT_APP_FACEBOOK_ID} />
        <meta name="twitter:title" content="Rap List Builder | Rapchr" />
        <meta name="twitter:description" content="Rate your favorite rappers! A social platform for music fans to explore and share their taste in hip-hop." />
        <meta name="twitter:image" content="https://res.cloudinary.com/rapchr/image/upload/v1620340519/full-racphr-logo_zqtbdx.png" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@rapchr" />
      </Helmet>
      <WindowSizeListener onResize={(windowSize) => onResize(windowSize, smallScreensize, setSmallScreensize)}/>
      {renderListGraphic &&
        <ListGraphic
           artists={formatChosenArtists(data)}
           renderListGraphic={renderListGraphic}
           setRenderListGraphic={setRenderListGraphic}
           listTitle={listTitle}
           currentUser={currentUser}
           isMobile={isMobile}
           smallScreensize={smallScreensize}
         />
      }
      <Header
        smallScreensize={smallScreensize}
        customClass={smallScreensize && showUsers && "blur"}
        currentUser={currentUser}
        onboarding={currentUser && (!currentUser.onboardedArtists || !currentUser.onboardedSkills)}
        profile={true}
        listBuilder={true}
      />
      <div className="homepage__wrapper">
        <div style={{width: recentUsersError && "100%"}} className={`skill-step__left-hand${smallScreensize && showUsers ? " blur" : ""}`}>
          <div className="fav-artist-step__wrapper">
            <div className="fav-artist-step__title-section">
              <h2 className="fav-artist-step__title"><span className="fav-artist-step__highlight">Build</span> and <span className="fav-artist-step__highlight">share</span></h2>
              <p className="fav-artist-step__message">
                Share by picking rappers and adding a title, you'll see your ratings for each rapper you've rated!
              </p>
              <input
                onChange={(e) => setListTitle(e.target.value, alert)}
                className="fav-artist-step__input"
                placeholder="Title..."
                handleFocus={executeScroll}
                maxLength="24"
              />
            </div>
            <div>
            <DragDropContext
              onDragEnd={(result) => handleOnDragEnd(result, data, setData, excludes, setExcludes, alert)}
              onDragStart={() => setDragStarted(true)}
              onDragUpdate={(update) => onDragUpdate(update, setDragIsActive)}
            >
              <div className="chosen-artists__wrapper">
                {data.areaOrder.map((areaId, index) => {
                    const area = data.areas[areaId]
                    const artistChoice = area.artist
                    return renderDropArea(artistChoice, index, area, data, setData, excludes, setExcludes)
                   })}
                </div>
                <div className="search-area__wrapper">
                  <SearchBar
                    onHandleClick={() => setFilter('')}
                    onChange={(e) => setFilter(e.target.value)}
                    filter={filter}
                    isMobile={isMobile}
                    clearIconHeight={smallScreensize ? "12px" : "1.3vw"}
                    clearIconWidth={smallScreensize ? "12px" : "1.3vw"}
                    searchIconHeight={smallScreensize ? "18px" : "2vw"}
                    searchIconWidth={smallScreensize ? "18px" : "2vw"}
                    handleFocus={executeScroll}
                  />
                  {recommendationOptions(filter, setFilter, skillPreference, setSkillPreference, featured, setFeatured, mostRated, setMostRated)}
                </div>
                {artistSearchResultsLoading &&
                  <div className="artist-search-results__wrapper">
                    <div className="loading__wrapper">
                      <img className="loading__icon inner" alt="loading" src={loadingIcon} />
                    </div>
                  </div>
                }
                {artistSearchResults && artistSearchResults.length == 0 &&
                  <div artist-search-results__wrapper>
                    <div className="search-error__wrapper">
                    {skillPreference ? <p className="search-error__message">Check again later or update your skill preferences <a className="search-error__link" href="/update-skills">here</a></p> :
                      <p className="search-error__message">No search results.</p>
                    }
                  </div>
                </div>}
                {artistSearchResults && !artistSearchResultsLoading && <Droppable direction="horizontal" droppableId={data.areas.searchResults.id}>
                  {(provided) => (
                    <div className={`artist-search-results__wrapper list-results${isMobile ? " mobile-wrapper" : ""}`} areaname={data.areas.artists} {...provided.droppableProps} ref={provided.innerRef}>
                      {(data.areas.searchResults.artistIds.length < 1 || artistSearchResultsError) && renderNoResults(currentUser, skillPreference) }
                      {data.areas.searchResults.artistIds.map((artistId, index) => {
                        const artistOption = (artistId === (data.artists[artistId] && data.artists[artistId].id)) && data.artists[artistId]
                        if(artistOption) {
                          if(isMobile) {
                            return renderMobileArtist(artistOption, index, data, setData, excludes, setExcludes)
                          } else {
                            return renderDraggableArtist(artistOption, index, dragIsActive, setDragIsActive)
                          }
                        }
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>}
              </DragDropContext>
            </div>
            <div className="fav-artist-step__cta-wrapper">
              <Button
                disabled={formatChosenArtists(data).length === 0}
                customClass="fav-artist-step__continue list-share"
                handleclick={() => handleListRender(setRenderListGraphic, listTitle, alert, executeScroll)}
              >
                Share List
              </Button>
            </div>
            <div className="left-hand__bottom-gradient"></div>
            <div onClick={() => showUsers ? setShowUsers(false) : setShowUsers(true)} className="skill-step__mobile-users__cta">
              <img src={mobileFavArtistStepMessage} className="skill-step__msg-mobile" alt="Our Growing Community" />
              <img alt={showUsers ? "close" : "open"} src={showUsers ? userMsgIconDown : userMsgIconUp} className="skill-step__msg-icon" />
            </div>
          </div>
        </div>
        <img style={{display: recentUsersError && "none"}} src={favArtistStepMessage} className="skill-step__middle-msg onboarded" alt="Join Our Growing Community" />
        {renderRightSide(loadingMore, recentUsers, smallScreensize, showUsers, setShowUsers, history, currentUser, recentUsersLoading, recentUsersError, setLoadingMore, fetchMore)}
      </div>
      <Footer />
    </div>
  );
}


const ARTISTS_QUERY = gql`
  query artists($filter: String, $excludes: [ID!], $featured: Boolean, $mostRated: Boolean, $skillPreference: Boolean, $first: Int) {
    artists(filter: $filter, excludes: $excludes, featured: $featured, mostRated: $mostRated, skillPreference: $skillPreference, first: $first) {
      id
      name
      image
      currentUserRatings {
        id
        ratings
      }
    }
  }
`

export default ListBuilder
