import React from 'react';

const BackIcon = ({
  height,
  width,
  color,
  style,
  handleclick,
  opacity,
  ...rest
}) => (
  <svg viewBox="0 0 28 28" { ...rest } fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleclick} style={style} width={width} height={height} >
    <rect width={width} height={height} rx="18" fill="white" fill-opacity={opacity ? opacity : "0.02"}/>
    <path d="M20.2727 11.6102L20.9051 12.1684C21.0316 12.3153 21.0316 12.5503 20.9051 12.6678L15.1818 17.9853L20.9051 23.3322C21.0316 23.4497 21.0316 23.6847 20.9051 23.8316L20.2727 24.3898C20.1146 24.5367 19.8933 24.5367 19.7352 24.3898L13.0949 18.2497C12.9684 18.1028 12.9684 17.8972 13.0949 17.7503L19.7352 11.6102C19.8933 11.4633 20.1146 11.4633 20.2727 11.6102Z" fill={color} stroke={color} stroke-width=".3"/>
  </svg>
);

export default BackIcon;
