import React, { useReducer, useRef, useEffect } from 'react';
import { useQuery, useMutation } from 'react-apollo-hooks';
import { isMobile } from "react-device-detect";

import SearchArea from '../components/SearchArea';
import ArtistSearchResults from '../components/ArtistSearchResults';
import SingleArtist from '../components/SingleArtist';
import CompareArtists from '../components/CompareArtists';
import Button from '../components/Button';
import Icon from '../components/Icon/Icon';

import { colors } from '../styles/defaultTheme';
import rapchrLogo from '../images/BH_Filled_Secondary_Logo.png';
import defaultArtistImage from '../images/default-user.svg';
import dottedElipse from '../images/dotted-elipse.svg';
import deceasedIcon from '../images/rip.svg';
import { handleExcludes, handleModalClose } from '../utils'


const renderCompareSearchImages = (state) => {
  return (
    <div className="rating-artist__image-wrapper__compare-search">
      <div className="rating-artist__image-wrapper__original-search">
        <div
          style={{backgroundImage: `url(${state.originalArtist.image})`}}
          className="rating-artist__image-search"
        >
        </div>
        <p className={`rating-artist__name-search${state.originalArtist.name.length > 13 ? " rating-artist__name-search-lg" : ""}`}>{state.originalArtist.name}</p>
      </div>
      <div className="rating-artist__image-wrapper__comparison-search">
        <img
          alt="elipse"
          src={dottedElipse}
          className="rating-artist__comparison-elipse"
        />
        <div
          className="rating-artist__comparison-default chosen-artist__wrapper"
        >
          <img
            alt="default rapper"
            src={defaultArtistImage}
            className="rating-artist__image-search-comparison"
          />
        </div>
      </div>
    </div>
  )
}

const handleBackClick = (dispatch, executeScroll) => {
  dispatch({payload: {rateArtistView: false, compareSearch: false, compareArtist: {}}})
  executeScroll && executeScroll()
}

const handleCompareArtistClick = (state, dispatch, artist, executeScroll, homepage, featuredArtists) => {
  if(homepage) {
    dispatch({payload: {compareSearch: false, compareFilter: "", compareArtist: artist, featuredArtists: featuredArtists ? featuredArtists : []}})
  } else {
    let newExcludes = state.excludes
    newExcludes.push(artist.id)
    dispatch({payload: {excludes: newExcludes, compareSearch: false, compareFilter: "", compareArtist: artist, userArtistRatings: []}})
    executeScroll && executeScroll()
  }
}

// const getCorrectId = (state, artistSlug) => {
//   if(!state.originalArtist.id && artistSlug) {
//     return artistSlug
//   } else if(state.originalArtist.id) {
//     return state.originalArtist.id
//   } else if(artistSlug) {
//     return artistSlug
//   }
// }

const ArtistRatingModal = ({
  state,
  dispatch,
  allSkills,
  submitArtistRating,
  submitRatingUpdate,
  artistCompareResults,
  artistSlug,
  userId,
  history,
  currentUser,
  sendData,
  parentModalClose,
  profile,
  homepage,
  openSignup,
  appState,
  userRatings,
  executeScroll,
  featuredArtists,
  postPage
}) => {


  // const originalArtistId = getCorrectId(state, artistSlug)
  if((state.originalArtist.id || artistSlug) && state.artistModal) {
    if(state.compareSearch) {
      return (
        <div className="rating-artist__bg compare">
          <div className="rating-artist__wrapper">
            <div className="rating-artist__icon-wrapper">
              <Icon
                customClass="rating-artist__exit-icon"
                color={isMobile ? colors.beachHouse : colors.beachHouseLight}
                type="close"
                width="24px"
                height="24px"
                style={{opacity: isMobile && "1"}}
                handleclick={() => handleModalClose(dispatch, history, userId)}
              />
            </div>
            {renderCompareSearchImages(state)}
            <SearchArea
              filter={state.compareFilter}
              onArtistClick={(artist) => handleCompareArtistClick(state, dispatch, artist, executeScroll, homepage, featuredArtists)}
              onFilterChange={(value) => dispatch({payload: {compareFilter: value}})}
              clearFilter={() => dispatch({payload: {compareFilter: ""}})}
              parentDispatch={dispatch}
              excludes={state.compareExcludes}
              isMobile={isMobile}
              first={isMobile ? 6 : 8}
              compareSearch={true}
              smallScreensize={state.smallScreensize}
              currentUser={currentUser}
              handleFocus={executeScroll ? () => executeScroll() : () => {}}
              homepage={homepage}
              openSignup={openSignup}
              history={history}
            />
            <div className="rating-artist__bottom-gradient"></div>
            <div className={state.compareSearch ? "rating-artist__cta-wrapper__compare-search" : "rating-artist__cta-wrapper__compare"}>
              {!state.compareSearch && <Button
                handleclick={state.rateArtistView ? () => dispatch({payload: {rateArtistView: false}})
                  : () => dispatch({payload: {rateArtistView: false, compareSearch: true, compareArtist: {}}})}
                customClass="rating-artist__rate-btn"
              >
                Choose Rapper
              </Button>}
              <Button
                handleclick={() => handleBackClick(dispatch, executeScroll)}
                customClass={`rating-artist__compare-btn ${state.compareSearch ? 'compare-search' : ""}`}
              >
                {state.originalArtist.name + "'s Ratings"}
              </Button>
            </div>
          </div>
        </div>
      )
    } else if((state.compareArtist && state.compareArtist.id) || state.compareSearch) {
      return <CompareArtists postPage={postPage} homepage={homepage} executeScroll={executeScroll} appState={appState} history={history} artistId={state.originalArtist.id} artistSlug={artistSlug} currentUser={currentUser} history={history} userId={userId} submitArtistRating={submitArtistRating} submitRatingUpdate={submitRatingUpdate} allSkills={allSkills} state={state} dispatch={dispatch} />
    } else {
      return <SingleArtist postPage={postPage}  executeScroll={executeScroll} userRatings={userRatings} appState={appState} openSignup={openSignup} profile={profile} homepage={homepage} sendData={sendData} artistSlug={artistSlug} currentUser={currentUser} history={history} userId={userId} submitArtistRating={submitArtistRating} submitRatingUpdate={submitRatingUpdate} allSkills={allSkills} state={state} dispatch={dispatch}/>
    }
  }
}

export default ArtistRatingModal;
