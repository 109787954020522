import {
  SINGLE_ARTIST_QUERY,
  USER_RATING_QUERY
} from '../api/singleArtist'

import {
  handleVoteLogic,
  productionConsoleCheck
} from '../utils'


export const updateCacheAfterRating = async (store, data, state, artist, artistSlug, userId) => {
  try {
    const queryData = artistSlug && !state.originalArtist.id ? store.readQuery({query: SINGLE_ARTIST_QUERY, variables: {slug: artist.slug}}) : store.readQuery({query: SINGLE_ARTIST_QUERY, variables: {artistId: artist.id, slug: artist.slug}})
    let ratingQueryData = null

    if(data.data.updateArtistRatings) {
      ratingQueryData = store.readQuery({query: USER_RATING_QUERY, variables: {slug: artistSlug, userId: userId}});
    }

    const newData = data.data.rateArtist ? data.data.rateArtist : data.data.updateArtistRatings;
    const oldData = queryData.singleArtist;
    const oldRatingData = ratingQueryData.userArtistRatings;

    if(newData) {
      oldData.skillAverages = newData.skillAverages
      oldData.currentUserRatings = newData.currentUserRatings

      if(data.data.updateArtistRatings) {
        oldRatingData.ratings = newData.currentUserRatings.ratings
        oldRatingData.voteSum = newData.currentUserRatings.voteSum
        oldRatingData.votesTurnedOn = newData.currentUserRatings.votesTurnedOn
        oldRatingData.currentUserVote = newData.currentUserRatings.currentUserVote
      }
    }

    store.writeQuery({ query: SINGLE_ARTIST_QUERY, variables: {artistId: artist.id}, data: queryData })
    if(data.data.updateArtistRatings) {
      store.writeQuery({ query: USER_RATING_QUERY, variables: {slug: artistSlug, userId: userId}, data: ratingQueryData})
    }
  } catch (e) {
    productionConsoleCheck("Not updating:", e)
  }

}

const formatQueryData = (store, state, userId, artistSlug) => {
  if(state.userListChoice) {
    return store.readQuery({query: USER_RATING_QUERY, variables: { userId: state.userListChoice.user.id, ratingGroupId: state.userListChoice.id }});
  } else if(state.ratingGroupId) {
    return store.readQuery({query: USER_RATING_QUERY, variables: {ratingGroupId: state.ratingGroupId, userId: userId}})
  } else {
    return store.readQuery({query: USER_RATING_QUERY, variables: {slug: artistSlug, userId: userId}});
  }
}

export const updateCacheAfterVote = async (store, data, state, artist, ratingGroup, positive, artistSlug, userId) => {
  try {
    const queryData = formatQueryData(store, state, userId, artistSlug)
    const newData = data.data.graphicVote && data.data.graphicVote

    const oldData = queryData.userArtistRatings;
    let voteSum = oldData.voteSum;

    voteSum = handleVoteLogic(ratingGroup.currentUserVote, positive, ratingGroup.voteSum)
    oldData.currentUserVote = newData;
    oldData.voteSum = voteSum;

    store.writeQuery({ query: USER_RATING_QUERY, variables: {slug: artistSlug, userId: userId}, data: queryData})
  } catch (e) {
    productionConsoleCheck("Not updating:", e)
  }

}
