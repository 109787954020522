import React, {useReducer} from 'react';
import { useHistory } from "react-router-dom";
import gql from 'graphql-tag';

import {
  USERS_CONNECTION_QUERY
} from '../api/homepage'

import { handleArtistClick } from '../utils'
import { colors, fontSizes} from '../styles/defaultTheme';
import Header from '../components/Header';
import Footer from '../components/Footer';
import RecommendedUser from '../components/RecommendedUser';
import Icon from '../components/Icon/Icon'

import WindowSizeListener from 'react-window-size-listener'
import { Helmet } from "react-helmet";
import { isMobile } from "react-device-detect";

import imagePlaceholder from '../images/blog-placeholder.png'
import userMsgIconDown from '../images/carrot-down.svg';
import userMsgIconUp from '../images/carrot-up.svg';
import favArtistStepMessage from '../images/favorite-artists-msg.svg';
import mobileFavArtistStepMessage from '../images/other-users-artists-mobile.svg';
import loadingIcon from '../images/load-disk.png';

import { useQuery, useMutation } from 'react-apollo-hooks';

const handleUserScroll = (e, fetchMore, data, state, dispatch, setLoadingMore) => {
  const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
  if (bottom) {

    if(setLoadingMore) {
      setLoadingMore(true)
    } else {
      dispatch({payload: {loading: true}})
    }

    fetchMore({
      variables: {
        first: 20,
        cursor: data.usersConnection.pageInfo.endCursor
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult.usersConnection.edges;
        const pageInfo = fetchMoreResult.usersConnection.pageInfo;

        if(setLoadingMore) {
          setLoadingMore(false)
        } else {
          dispatch({payload: {loading: false}})
        }
        return newEdges.length
        ? {
          usersConnection: {
            __typename: prevResult.usersConnection.__typename,
            edges: [...prevResult.usersConnection.edges, ...newEdges],
            pageInfo
          }
        }
        : prevResult
      }
    })
  }
}

const onResize = (windowSize, state, dispatch) => {

  if(windowSize.windowWidth < "900" && !state.mediumScreensize) {
    dispatch({payload: {mediumScreensize: true, showUsers: false}})
  } else if(windowSize.windowWidth > "900" && state.mediumScreensize) {
    dispatch({payload: {mediumScreensize: false, showUsers: false}})
  }
  if(windowSize.windowWidth < "768" && !state.smallScreensize) {
    dispatch({payload: {smallScreensize: true, showUsers: false}})
  } else if(windowSize.windowWidth > "768" && state.smallScreensize) {
    dispatch({payload: {smallScreensize: false, showUsers: false}})
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    default:
      return { ...state, ...action.payload };
  }
};

const initialState = () => ({
  smallScreensize: window.innerWidth <= 768,
  showUsers: false,
  loading: false,
  imageView: false,
  imageIndex: 0,
  folder: "../images/research",
  currentImage: null
})

const importAll = (r) => {
  return r.keys().map(r);
}


const Research = ({match, currentUser, allSkills}) => {

  const [state, dispatch] = useReducer(reducer, initialState())

  const community = importAll(require.context("../images/research/community", false, /\.(png|jpe?g|svg)$/), 'sync');
  const generation = importAll(require.context("../images/research/generation", false, /\.(png|jpe?g|svg)$/), 'sync');
  const potential = importAll(require.context("../images/research/potential", false, /\.(png|jpe?g|svg)$/), 'sync');
  const critique = importAll(require.context("../images/research/critique", false, /\.(png|jpe?g|svg)$/), 'sync');
  const media = importAll(require.context("../images/research/media", false, /\.(png|jpe?g|svg)$/), 'sync');
  const quo = importAll(require.context("../images/research/quo", false, /\.(png|jpe?g|svg)$/), 'sync');
  const feedback = importAll(require.context("../images/research/feedback", false, /\.(png|jpe?g|svg)$/), 'sync');
  const anti = importAll(require.context("../images/research/anti", false, /\.(png|jpe?g|svg)$/), 'sync');

  const categories = [
    {title: "Want For Community", subTitle: "Music fans are frustrated with the experiences on offer, and desire new ways to connect with like minded fans.", folder: community},
    {title: "Value Potential", subTitle: "As a digital playground for hip-hop fans, Rapchr has the potential for immense cultural impact.", folder: potential},
    {title: "Importance of Critique", subTitle: "Brillant minds have highlighted the importance of critique in culture and art.", folder: critique},
    {title: "Media Frustrations", subTitle: "Hip-hop media as the backbone of music media in general, has exhibited worrying trends over the last decade.", folder: media},
    {title: "Status Quo Frustations", subTitle: "Music fans are frustrated with how institutions like The Grammys and Billboard reward artistic achievement.", folder: quo},
    {title: "Generational Gap", subTitle: "Hip-hop's sound is constantly evolving, which has lead to a lack of understanding around music quality between generations.", folder: generation},
    {title: "Product Feedback", subTitle: "Positive feedback Rapchr has recieved regarding our current platforms, and examples of features being used and shared.", folder: feedback},
    {title: "Anti-Blackness Concerns", subTitle: "Music fans are highlighting how a lack of respect for hip-hop as an artform can lead to anti-blackness.", folder: anti},
  ]



  const {
    data: recentUsers,
    loading: recentUsersLoading,
    error: recentUsersError,
    fetchMore
  } = useQuery(USERS_CONNECTION_QUERY, {
    variables: {
      first: 20,
    }
  })

  let history = useHistory();

  const renderCurrentImage = () => {
    return (
      <div className="research__overlay">
        <Icon
          customClass="research__close-icon"
          color="#f1e3ce"
          width="20px"
          height="20px"
          type="close"
          style={{opacity: isMobile && "1"}}
          handleclick={() => dispatch({payload: {currentImage: null}})}
        />
        <img alt={`Research Image`} src={state.currentImage} />
      </div>
    )
  }


  const renderImages = () => {
    const filenames = categories[state.imageIndex].folder;

    return (
      <div className="research__images">
        {
          filenames.map((filename, index) => {
            return <div onClick={() => dispatch({payload: {currentImage: filename.default}})} className="research__images__image" key={index + "-img"}>
              <img alt={`Research Image ${index}`} src={filename.default} />
            </div>
          })
        }
      </div>
    )
  }

  return (
    <div className="research__wrapper">
      <WindowSizeListener onResize={(windowSize) => onResize(windowSize, state, dispatch)}/>
      <Header
        customClass={state.smallScreensize && state.showUsers && "blur"}
        allSkills={allSkills}
        history={history}
        currentUser={currentUser}
        smallScreensize={state.smallScreensize}
        errorPage={true}
      />
      {state.currentImage && renderCurrentImage()}
      <div style={{width: recentUsersError && "100%"}} className={`skill-step__left-hand blog-page__left-hand  ${state.smallScreensize && state.showUsers && "blur"}`}>
        <div className="left-hand__top-gradient"></div>
        <div className="research__title">
          <h2 className="blog-page__title">{(state.imageView && categories[state.imageIndex].title) || "Rapchr Market Validation"}</h2>
          <p>{(state.imageView && categories[state.imageIndex].subTitle) || "Over the years we've collected screenshots of target audience thoughts and frustrations that we believe further support our research and hypotheses."}</p>
        </div>
        {!state.imageView && <div className="research__topics">
          {categories.map((cat, index) => {
            return (
              <p key={index + "-cat"} onClick={() => dispatch({payload: {imageView: true, imageIndex: index}})} className="research__topics__items">{cat.title}</p>
            )
          })}
        </div>}
        {state.imageView && <p onClick={() => dispatch({payload: {currentImage: null, imageView: false, category: "../images/research"}})} className="research__back">back</p>}
        {state.imageView && renderImages()}
        {state.smallScreensize && <div className="bump"></div>}

      </div>
      {((state.smallScreensize && state.showUsers) || !state.smallScreensize) &&
      <div
        style={{display: recentUsersError && "none"}}
        className="skill-step__right-hand"
        onScroll={(e) => handleUserScroll(e, fetchMore, recentUsers, state, dispatch)}
      >
        <div onClick={() => dispatch({payload: {showUsers: !state.showUsers}})} className="skill-step__mobile-users__cta__top">
          <img src={mobileFavArtistStepMessage} className="skill-step__msg-mobile" alt="Other user's favorite artists" />
          <img lt={state.showUsers ? "close" : "open"} src={state.showUsers ? userMsgIconDown : userMsgIconUp} className="skill-step__msg-icon" />
        </div>
        <div className="skill-step__top-gradient"></div>
        <div className="skill-step__users">
          {recentUsersLoading &&
            <div className="loading__wrapper">
              <img className="loading__icon" alt="loading" src={loadingIcon} />
            </div>
          }
          {recentUsers && recentUsers.usersConnection && recentUsers.usersConnection.edges.map((user, index) => {
            return (
              <div onClick={() => {}}>
              <RecommendedUser
                key={index}
                user={user.node}
                artists={true}
                index={index}
                currentUser={currentUser}
                recentUsersLoading={recentUsersLoading}
                onboarding={currentUser && (!currentUser.onboardedArtists || !currentUser.onboardedSkills)}
                customClass={state.loading ? " loading-more" : ""}
                noClick={true}
              />
              </div>
            )
          })}
        </div>
        {state.loading && <div className="loading__wrapper user-profile__loading-wrapper right-hand">
          <img className="loading__icon" alt="loading" src={loadingIcon} />
        </div>}
        <div className="skill-step__bottom-gradient"></div>
        <div className="bump"></div>
      </div>}
      {!state.showUsers && <div onClick={() => dispatch({payload: {showUsers: !state.showUsers}})} className="skill-step__mobile-users__cta">
        <img src={mobileFavArtistStepMessage} className="skill-step__msg-mobile" alt="Other user's favorite artists" />
        <img alt={state.showUsers ? "close" : "open"} src={state.showUsers ? userMsgIconDown : userMsgIconUp} className="skill-step__msg-icon" />
      </div>}
      <Footer />
    </div>
  )
}

export default Research
