import React from 'react';

const AddIcon = ({
  height,
  width,
  color,
  style,
  handleclick,
  ...rest
}) => (
  <svg { ...rest } onClick={handleclick} style={style} width={width} height={height} viewBox="0 0 24 24" >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-984.000000, -13.000000)">
            <g transform="translate(992.914286, 22.308571) rotate(45.000000) translate(-992.914286, -22.308571) translate(983.771429, 13.165714)">
                <g stroke={color} strokeLinecap="round" strokeWidth="0.731428571" transform="translate(9.325714, 9.325714) rotate(-270.000000) translate(-9.325714, -9.325714) translate(2.742857, 2.742857)">
                    <path d="M12.6408537,12.8454981 L0.187435046,0.11446789"></path>
                    <path d="M0.187435046,12.8454981 L12.6408537,0.11446789"></path>
                </g>
                <rect x="0" y="0" width="18.2857143" height="18.2857143"></rect>
            </g>
          </g>
      </g>
  </svg>
);

export default AddIcon;
