import React from 'react';

const SearchIcon = ({
  height,
  width,
  color,
  handleclick,
  style,
  ...rest
}) => (
  <svg { ...rest } style={style} width={width} height={width} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7" cy="7" r="6.5" stroke={color}/>
    <line x1="12.3536" y1="11.6464" x2="18.3536" y2="17.6464" stroke={color} stroke-strokelinejoin="round"/>
  </svg>
);

export default SearchIcon;
