import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router'


const ConditionalLink = ({ children, to, condition }) => (!!condition && to)
      ? <Link  style={{textDecoration: 'none'}} to={to}>{children}</Link>
      : <>{children}</>;

const renderName = (name, isMobile) => {
  if(name.length > 11 && isMobile) {
    return name.substring(0,10) + ".."
  } else if(name.length > 12) {
    return name.split(" ")[0]
  } else {
    return name
  }
}

const ProfileArtist = ({
  artist,
  customClass,
  currentUser,
  profile,
  date,
  renderGraphic,
  smallScreensize,
  openModal,
  userRatings,
  chooseArtist,
  isMobile,
  state,
}) => {
  const ratings = profile ? userRatings : artist.skillAverages
  const sortedArtistSkillAverages = ratings.sort((function(a, b) { return !profile ? b.average - a.average : b.rating - a.rating;}))
  // <p onClick={(e) => openModal(e, artist)} className="user-profile__rate-btn">Stats</p>

  return (
    <ConditionalLink to={profile ? "" : "/rate/rapper/" + (artist.slug && artist.slug)} condition={currentUser}>
      <div className={`profile-artist__info-wrapper ${state.loading ? 'load-more' : ""}`}>
        <p className={`profile-artist__name`}>{artist.name}</p>
        {date && <p className="user-profile__rating-date">{date}</p> }
      </div>
      <div
        onClick={profile ? () => renderGraphic(artist, ratings) : () => chooseArtist(artist)}
        className={`profile-artist__wrapper ${customClass ? customClass : ""}`}
      >
        <div className="profile-artist__info">
          <div className="profile-artist__image" style={{backgroundImage: `url(${artist.image})`}}></div>

          {!date && <div className={`profile-artist__name`}>{renderName(artist.name, isMobile)}</div>}
        </div>
        <div className="profile-artist__ratings">
        {sortedArtistSkillAverages.map((rating, index) => {
          if(index < 3) {
            return (
              <div key={index} className="profile-artist__rating-wrapper">
                <p className={`profile-artist__rating-name ${(rating.skill && rating.skill.name.length > 10) || (rating.skillName && rating.skillName.length > 10) ? "large" : ""}`}>{(rating.skill && rating.skill.name) || (rating.skillName && rating.skillName)}<span className={`profile-artist__rating-score${rating.rating == 10 ? " large" : ""}`}>{profile ? Math.round(rating.rating * 10) : Math.round(rating.average * 10)}</span></p>
              </div>
            )
          }
        })}
        </div>
      </div>
    </ConditionalLink>
  )
}

export default withRouter(ProfileArtist);
