import React, {useState, useEffect, useRef} from 'react';
import { useQuery, useMutation } from 'react-apollo-hooks';
import gql from 'graphql-tag'
import { isMobile } from "react-device-detect";

import gradientElipse from '../images/gradient-elipse.svg';
import dragIcon from '../images/drag-icon.svg';
import loadingIcon from '../images/load-disk.png';
import mobileFavArtistStepMessage from '../images/other-users-artists-mobile.svg';
import userMsgIconDown from '../images/carrot-down.svg';
import userMsgIconUp from '../images/carrot-up.svg';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SearchBar from './SearchBar'
import Button from './Button'
import ErrorPage from './ErrorPage'

import { handleExcludes } from '../utils'

const artistFilterArgument = (choice, filter, setFilter, skillPreference, setSkillPreference, featured, setFeatured, mostRated, setMostRated) => {

  if(filter.length > 0) {
    setFilter('')
  }

  if(choice === "skillPreference") {
    if(skillPreference && !filter.length) {
      setSkillPreference(false)
    } else {
      setSkillPreference(true)
      setMostRated(false)
      setFeatured(false)
    }
  } else if(choice === "featured") {
    if(featured && !filter.length) {
      setFeatured(false)
    } else {
      setFeatured(true)
      setSkillPreference(false)
      setMostRated(false)
    }
  } else if(choice === "mostRated") {

    if(mostRated && !filter.length) {
      setMostRated(false)
    } else {
      setMostRated(true)
      setFeatured(false)
      setSkillPreference(false)
    }
  }
}

const recommendationOptions = (filter, setFilter, skillPreference, setSkillPreference, featured, setFeatured, mostRated, setMostRated) => {
  return (
    <div className="rec-options__wrapper">
      <p
        onClick={() => artistFilterArgument("featured", filter, setFilter, skillPreference, setSkillPreference, featured, setFeatured, mostRated, setMostRated)}
        className={`rec-options__name ${featured ? "active" : ''}`}>Most Listed</p>
      <p onClick={() => artistFilterArgument("mostRated", filter, setFilter, skillPreference, setSkillPreference, featured, setFeatured, mostRated, setMostRated)}
        className={`rec-options__name ${mostRated ? "active" : ''}`}>Most Rated</p>
      <p
        onClick={() => artistFilterArgument("skillPreference", filter, setFilter, skillPreference, setSkillPreference, featured, setFeatured, mostRated, setMostRated)}
        className={`rec-options__name ${skillPreference ? "active" : ''}`}>Recommended</p>
    </div>
  )
}

const handleOnDragEnd = (result, data, setData, excludes, setExcludes) => {
  const { destination, source, draggableId } = result;

  if (!destination) return;
  if (
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  ) {
    return;
  }

  const start = data.areas[source.droppableId]
  const finish = data.areas[destination.droppableId]

  if(start !== finish) {
    const artistIds = Array.from(start.artistIds);
    artistIds.splice(source.index, 1);
    if(finish.artist && finish.artist.id) {
      setExcludes(handleExcludes(finish.artist.id, excludes))
      artistIds.push(finish.artist.id)
    }

    const newStart = {
      ...start,
      artistIds: artistIds
    }
    const newFinish = {
      ...finish,
      artist: data.artists[draggableId]
    }
    const newState = {
      areaOrder: data.areaOrder,
      artists: data.artists,
      areas: {
        ...data.areas,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish
      }
    }

    setData(newState)
    setExcludes(handleExcludes(draggableId, excludes))
  }
}

const handleRemoveArtist = (artistChoice, area, data, setData, excludes, setExcludes) => {

    if(!artistChoice.name) {
      return
    }
    const newSearchResults = data.areas.searchResults.artistIds;
    newSearchResults.push(artistChoice.id)

    const newSearchResultsArea = {
      id: 'searchResults',
      artistIds: newSearchResults
    }

    const newChoiceArea = {
      ...area,
      artist: {}
    }

    const newState = {
      areaOrder: data.areaOrder,
      artists: data.artists,
      areas: {
        ...data.areas,
        searchResults: newSearchResultsArea,
        [area.id]: newChoiceArea
      }
    }
    setData(newState)
    setExcludes(handleExcludes(artistChoice.id, excludes))
}


const onDragUpdate = (update, setDragIsActive) => {
  const { destination } = update;
  destination && setDragIsActive(true)
}

const getMobileOpenArtist = (data) => {
  if(!data.areas["pick-1"].artist || !data.areas["pick-1"].artist.id) {
    return data.areas["pick-1"]
  } else if(!data.areas["pick-2"].artist || !data.areas["pick-2"].artist.id) {
    return data.areas["pick-2"]
  } else if(!data.areas["pick-3"].artist || !data.areas["pick-3"].artist.id) {
    return data.areas["pick-3"]
  } else if(!data.areas["pick-4"].artist || !data.areas["pick-4"].artist.id) {
    return data.areas["pick-4"]
  } else if(!data.areas["pick-5"].artist || !data.areas["pick-5"].artist.id) {
    return data.areas["pick-5"]
  }
}

const handleMobileClick = (artistOption, data, setData, excludes, setExcludes) => {

  const start = data.areas["searchResults"]
  const finish = getMobileOpenArtist(data)
  const artistIds = Array.from(start.artistIds);
  if(finish.artist && finish.artist.id) {
    setExcludes(handleExcludes(finish.artist.id, excludes))
    artistIds.push(finish.artist.id)
  }

  const newStart = {
    ...start,
    artistIds: artistIds
  }
  const newFinish = {
    ...finish,
    artist: data.artists[artistOption.id]
  }
  const newState = {
    areaOrder: data.areaOrder,
    artists: data.artists,
    areas: {
      ...data.areas,
      [newStart.id]: newStart,
      [newFinish.id]: newFinish
    }
  }

  setData(newState)
  setExcludes(handleExcludes(artistOption.id, excludes))
}

const renderDropArea = (artistChoice, index, area, data, setData, excludes, setExcludes) => {
  return (
    <Droppable key={area.id} droppableId={area.id}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          onClick={() => handleRemoveArtist(artistChoice, area, data, setData, excludes, setExcludes)}
          className={`chosen-artist__wrapper${snapshot.isDraggingOver ? " active" : ""}${artistChoice && artistChoice.name ? " artist-filled" : ""}`}
        >
          {snapshot.isDraggingOver || (artistChoice && artistChoice.name) &&
            <img className="chosen-artist__droppable-elipse" alt={artistChoice.name ? artistChoice && artistChoice.name : "rapper space"} src={gradientElipse} />
          }
          {artistChoice && artistChoice.name
            ? <div
                className="chosen-artist__image"
                style={{backgroundImage: `url(${artistChoice.image})`}}
              >
              </div>
            : <p className="chosen-artist__name">{index + 1}</p>
          }
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

const renderDraggableArtist= (artistOption, index) => {
  return (
    <Draggable key={artistOption.id} draggableId={artistOption.id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`artist-option__wrapper${snapshot.isDragging ? " active" : ""}`}
        >
          <img alt="drag" src={dragIcon} className="artist-option__drag-icon" />
          <div
            className="artist-option__image"
            style={{backgroundImage: `url(${artistOption.image})`}}
          >
          </div>
          <p className="artist-option__name">{artistOption.name}</p>
        </div>
      )}
     </Draggable>
  )
}

const renderMobileArtist= (artistOption, index, data, setData, excludes, setExcludes) => {
  return (
    <div
      key={index}
      className="artist-option__wrapper"
      onClick={formatChosenArtists(data).length > 4 ? () => {} : () => handleMobileClick(artistOption, data, setData, excludes, setExcludes)}
    >
      <div
        className="artist-option__image"
        style={{backgroundImage: `url(${artistOption.smallImage ? artistOption.smallImage : artistOption.image})`}}
      >
      </div>
      <p className={`artist-option__name${artistOption.name.length > 13 ? " large" : ""}`}>{artistOption.name}</p>
    </div>
  )
}

const renderNoResults = (currentUser, skillPreference, filter) => {
  return (
    <div className="search-error__wrapper">
      {skillPreference && !filter ? <p className="search-error__message">Check back later, or update your skill preferences <a className="search-error__link" href="/update-skills">here</a></p> :
        <p className="search-error__message">No search results.</p>
      }
    </div>
  )
}

const renderStepMessage = (currentUser) => {
  if(currentUser.onboardedArtists) {
    return "Update your favorite rappers!"
  } else if (isMobile) {
    return "Choose from over 500 rappers, tap to add up to 5!"
  } else return "Choose from over 600 rappers, drag each into the bubbles!"
}

const formatChosenArtists = (data) => {
  let chosenArtists = []
  const dataArray = Object.keys(data.areas).map(area => data.areas[area])

  dataArray.map((area) => {
    if(area && area.artist && area.artist.id) {
      chosenArtists.push(area.artist.id)
    }
  })

  return chosenArtists
}

const artistChoices = (artistSearchResults) => {
  const formattedArtists = {}

  artistSearchResults.artists && artistSearchResults.artists.map((artist) => {
    formattedArtists[artist.id] = { id: artist.id, image: artist.image, name: artist.name}
  })

  return formattedArtists
}

const formatExcludes = (currentUser) => {
  const favoriteArtists = currentUser.favoriteArtists
  let excludesIds = []

  if(currentUser.favoriteArtists) {
    favoriteArtists.map((artist) => {
      excludesIds.push(artist.id)
    })
  }
  return excludesIds
}

const calcQueryAmount = (smallScreensize) => {
  if(isMobile) {
    return 10
  } else if(smallScreensize) {
    return 4
  } else return 5
}

const FavoriteArtistStep = ({
  updateUserArtists,
  setShowUsers,
  showUsers,
  disableButtons,
  skipPage,
  currentUser,
  smallScreensize,
  recentUsersError,
  recentUsersLoading
}) => {

  const [filter, setFilter] = useState('');
  const [excludes, setExcludes] = useState(formatExcludes(currentUser));
  const [skillPreference, setSkillPreference] = useState(false);
  const [featured, setFeatured] = useState(true);
  const [mostRated, setMostRated] = useState(false);


  const {
    data: artistSearchResults,
    loading: artistSearchResultsLoading,
    error: artistSearchResultsError
  } = useQuery(ARTISTS_QUERY, {
   variables: {
     filter,
     skillPreference,
     featured,
     mostRated,
     excludes,
     first: calcQueryAmount(smallScreensize)
   }
  });

  const formattedArtists = artistSearchResults ? artistChoices(artistSearchResults) : [];

  const initialData = {
    artists: formattedArtists,
    areas: {
      "searchResults": {
        id: "searchResults",
        artistIds: Object.keys(formattedArtists).map(artist => formattedArtists[artist].id)
      },
      "pick-1": {
        id: "pick-1",
        artist: currentUser.favoriteArtists && currentUser.favoriteArtists[0] ? currentUser.favoriteArtists[0] : {}
      },
      "pick-2": {
        id: "pick-2",
        artist: currentUser.favoriteArtists && currentUser.favoriteArtists[0] ? currentUser.favoriteArtists[1] : {}
      },
      "pick-3": {
        id: "pick-3",
        artist: currentUser.favoriteArtists && currentUser.favoriteArtists[0] ? currentUser.favoriteArtists[2] : {}
      },
      "pick-4": {
        id: "pick-4",
        artist: currentUser.favoriteArtists && currentUser.favoriteArtists[0] ? currentUser.favoriteArtists[3] : {}
      },
      "pick-5": {
        id: "pick-5",
        artist: currentUser.favoriteArtists && currentUser.favoriteArtists[0] ? currentUser.favoriteArtists[4] : {}
      }
    },
    areaOrder: ['pick-1', 'pick-2', 'pick-3', 'pick-4', 'pick-5']
  }

  const [data, setData] = useState(initialData);
  const [dragStarted, setDragStarted] = useState(false);
  const [dragIsActive, setDragIsActive] = useState(false);

  const favArtistRef = useRef(null)

  const executeScroll = favArtistRef && favArtistRef.current && isMobile ? () => window.scrollTo(0, favArtistRef.current.offsetTop) : () => {};

  useEffect(() => {
    if(!artistSearchResultsLoading && artistSearchResults) {
      const formattedArtists = artistChoices(artistSearchResults);

      const newSearchResultsArea = {
        id: 'searchResults',
        artistIds: Object.keys(formattedArtists).map(artist => formattedArtists[artist].id)
      }

      const newState = {
        areaOrder: data.areaOrder,
        artists: artistChoices(artistSearchResults),
        areas: {
          ...data.areas,
          searchResults: newSearchResultsArea,
        }
      }
      setData(newState)
    }
  }, [artistSearchResults])

  if(currentUser) {
    return (
      <div ref={favArtistRef} className="fav-artist-step__wrapper">
        <div className="fav-artist-step__title-section">
          <h2 className="fav-artist-step__title">Your favorite <span className="fav-artist-step__highlight">Rappers</span></h2>
          <p className="fav-artist-step__message">{renderStepMessage(currentUser)}</p>
        </div>
        <div>
        <DragDropContext
          onDragEnd={(result) => handleOnDragEnd(result, data, setData, excludes, setExcludes)}
          onDragStart={() => setDragStarted(true)}
          onDragUpdate={(update) => onDragUpdate(update, setDragIsActive)}
        >
          <div className="chosen-artists__wrapper">
            {data.areaOrder.map((areaId, index) => {
                const area = data.areas[areaId]
                const artistChoice = area.artist
                return renderDropArea(artistChoice, index, area, data, setData, excludes, setExcludes)
               })}
            </div>
            <div className="search-area__wrapper">
              <SearchBar
                onHandleClick={() => setFilter('')}
                onChange={(e) => setFilter(e.target.value)}
                filter={filter}
                isMobile={isMobile}
                clearIconHeight={smallScreensize ? "12px" : "1.3vw"}
                clearIconWidth={smallScreensize ? "12px" : "1.3vw"}
                searchIconHeight={smallScreensize ? "18px" : "2vw"}
                searchIconWidth={smallScreensize ? "18px" : "2vw"}
                handleFocus={executeScroll}
              />
              {recommendationOptions(filter, setFilter, skillPreference, setSkillPreference, featured, setFeatured, mostRated, setMostRated)}
            </div>
            {artistSearchResultsLoading &&
              <div className="artist-search-results__wrapper">
                <div className="loading__wrapper">
                  <img className="loading__icon inner" alt="loading" src={loadingIcon} />
                </div>
              </div>
            }
            {artistSearchResults && artistSearchResults.length == 0 &&
              <div artist-search-results__wrapper>
                <div className="search-error__wrapper">
                {skillPreference && !filter ? <p className="search-error__message">Check back later, or update your skill preferences <a className="search-error__link" href="/update-skills">here</a></p> :
                  <p className="search-error__message">No search results.</p>
                }
              </div>
            </div>}
            {artistSearchResults && !artistSearchResultsLoading && <Droppable direction="horizontal" droppableId={data.areas.searchResults.id}>
              {(provided) => (
                <div className={`artist-search-results__wrapper${isMobile ? " mobile-wrapper" : ""}`} areaname={data.areas.artists} {...provided.droppableProps} ref={provided.innerRef}>
                  {(data.areas.searchResults.artistIds.length < 1 || artistSearchResultsError) && renderNoResults(currentUser, skillPreference, filter) }
                  {data.areas.searchResults.artistIds.map((artistId, index) => {
                    const artistOption = (artistId === (data.artists[artistId] && data.artists[artistId].id)) && data.artists[artistId]
                    if(artistOption) {
                      if(isMobile) {
                        return renderMobileArtist(artistOption, index, data, setData, excludes, setExcludes)
                      } else {
                        return renderDraggableArtist(artistOption, index, dragIsActive, setDragIsActive)
                      }
                    }
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>}
          </DragDropContext>
        </div>
        <div className="fav-artist-step__cta-wrapper">
          <Button gradient={formatChosenArtists(data).length > 2} disabled={disableButtons || formatChosenArtists(data).length < 3} customClass="fav-artist-step__continue with-back"  handleclick={() => updateUserArtists(formatChosenArtists(data))}>
            Start Rating
          </Button>
          <Button disabled={disableButtons} customClass="fav-artist-step__skip with-back" handleclick={() => skipPage()}>
            Skip
          </Button>
          <Button disabled={disableButtons} customClass="fav-artist-step__skip with-back" handleclick={() => window.location.replace('/update-skills')}>
            Back
          </Button>
        </div>
        <div onClick={() => setShowUsers()} className="skill-step__mobile-users__cta">
          <img src={mobileFavArtistStepMessage} className="skill-step__msg-mobile" alt="Other user's favorite artists" />
          <img alt={showUsers ? "close" : "open"} src={showUsers ? userMsgIconDown : userMsgIconUp} className="skill-step__msg-icon" />
        </div>
        <div className="left-hand__bottom-gradient"></div>
      </div>
    );
  }

}


const ARTISTS_QUERY = gql`
  query artists($filter: String, $excludes: [ID!], $featured: Boolean, $mostRated: Boolean, $skillPreference: Boolean, $first: Int) {
    artists(filter: $filter, excludes: $excludes, featured: $featured, mostRated: $mostRated, skillPreference: $skillPreference, first: $first) {
      id
      name
      image
      smallImage
    }
  }
`

export default FavoriteArtistStep
