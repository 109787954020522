import React from 'react';

const GraphViewIcon = ({
  height,
  width,
  color,
  handleclick,
  style,
  ...rest
}) => (
  <svg { ...rest } onClick={handleclick} style={style} width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.94804 5.04555C5.94804 4.72907 6.21162 4.4694 6.53443 4.4694H9.46557C9.78838 4.4694 10.052 4.72907 10.052 5.04555V15.4232C10.052 15.7403 9.78838 15.9998 9.46557 15.9998H6.53426C6.21162 15.9998 5.94787 15.7405 5.94787 15.4232V5.04555H5.94804ZM0 15.4234C0 15.7405 0.264071 16 0.586066 16H3.51851C3.83986 16 4.10458 15.7406 4.10458 15.4234V9.34311C4.10458 9.02487 3.83986 8.76647 3.51851 8.76647H0.586066C0.264071 8.76647 0 9.02471 0 9.34311V15.4234ZM12.4816 0C12.1601 0 11.8954 0.258077 11.8954 0.576634V15.4234C11.8954 15.7405 12.1601 16 12.4816 16H15.4139C15.7359 16 16 15.7406 16 15.4234V0.576634C16 0.258237 15.7361 0 15.4139 0H12.4816Z" fill={color}/>
  </svg>
);

export default GraphViewIcon;
