const productionConsoleCheck = (message, obj) => {
  if(!process.env.REACT_APP_PRODUCTION) {
    console.log(message, obj)
  }
}

const handleExcludes = (artistId, excludesArray) => {

  const index = excludesArray.indexOf(artistId);

  if (index === -1) {
    excludesArray.push(artistId)
  } else {
    excludesArray.splice(index, 1)
  }

  return excludesArray

}

const formatUserName = (name) => {
  const splitName = name.split(" ")

  if(splitName.length > 1 && splitName[1].length > 0) {
    return `${splitName[0]} ${splitName[1][0]}.`
  } else {
    return name
  }
}

const renderUserNameClass = (name) => {

  const splitName = name.split(" ")

  if(splitName[0].length > 13) {
    return "x-large"
  } else if(splitName[0].length > 10) {
    return "large"
  } else {
    return ""
  }
}

const handleArtistClick = (artist, history) => {
  history.push('/rate/rapper/' + artist.slug)
}

const handleModalClose = (dispatch, history, userId) => {


  dispatch({payload: {
    initialUserRatings: [],
    artistModal: false,
    rateArtistView: false,
    originalArtist: {},
    compareArtist: {},
    originalArtistId: null,
    compareSearch: false,
    userArtistRatings: [],
    compareExcludes: [],
    compareFilter: "",
    excludes: [],
    userListChoice: null,
    userListModal: false
  }})

  if(window.location.pathname.includes("user")) {
    history.push(`/user/${userId}`)
  } else if(window.location.pathname !== "/" && !window.location.pathname.includes("/p/")) {
    history.push('/rate/')
  }

}

const abbreviateNumber = (num, fixed) => {
  if (num === null) { return null; } // terminate early
  if (num === 0) { return '0'; } // terminate early
  fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show
  var b = (num).toPrecision(2).split("e"), // get power
      k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
      c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3) ).toFixed(1 + fixed), // divide by power
      d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
      e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power
  return e;
}

const handleVoteLogic = (oldUserVote, newUserVote, oldVoteSum) => {
  // if prevous user vote exists
  if(oldUserVote && oldUserVote.positive !== null) {

    // if unvoting
    if(oldUserVote.positive === newUserVote) {
      if(newUserVote) {
        oldVoteSum = oldVoteSum - 1
      } else {
        oldVoteSum = oldVoteSum + 1
      }
    } else {
      if(newUserVote) {
        oldVoteSum = oldVoteSum + 2
      } else {
        oldVoteSum = oldVoteSum - 2
      }
    }
  } else {
    if(newUserVote) {
      oldVoteSum = oldVoteSum + 1
    } else {
      oldVoteSum = oldVoteSum - 1
    }
  }

  // vote and sum (if unvote[plus 1 or minus 1] / if oppvote[plus or minus 2] )
  // no vote and sum ()

  return oldVoteSum
}

const formatDate = (date) =>  {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [month, day, year].join('/');
}

const adminCheck = (user) => {
  if(user && user.roles) {

    if(user.roles.some(role => role.name === "ADMIN")) {
      return true
    }
  }

  return false
}



module.exports = {
  handleExcludes,
  formatUserName,
  renderUserNameClass,
  handleModalClose,
  handleArtistClick,
  abbreviateNumber,
  handleVoteLogic,
  productionConsoleCheck,
  formatDate,
  adminCheck
}
