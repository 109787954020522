import cloudinaryConfig from '../cloudinaryConfig'
import axios from 'axios';
import ReactGA from 'react-ga';
import { productionConsoleCheck } from '../utils'

const cloudName = cloudinaryConfig.cloud_name;

const adminCheck = (user) => {
  if(user && user.roles) {

    if(user.roles.some(role => role.name === "ADMIN")) {
      return true
    }
  }

  return false
}

const handleGAEvent = (category, action, label, userId, currentUser) => {
  if(process.env.REACT_APP_PRODUCTION) {
    if(!currentUser || (currentUser && currentUser.roles && !adminCheck(currentUser))) {
      ReactGA.event({
        userId: userId,
        category: category,
        action: action,
        label: label
      })
    }
  }
}

const handleGAError = (description, userId, action, currentUser) => {
  if(process.env.REACT_APP_PRODUCTION) {
    if(!currentUser || (currentUser && currentUser.roles && !adminCheck(currentUser))) {
      ReactGA.event({
        userId: userId,
        category: "Error",
        description: description,
        fatal: true,
        label: 'error',
        action: action
      })
    }
  }
}

export const updateUserProfile = (state, dispatch, alert, userUpdateMutation, currentUser, history, e) => {
  e.preventDefault()

  dispatch({payload: {loadingBarProgress: 10}})
  const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  if (state.email.length < 0 && reg.test(state.email) === false) {
    alert.removeAll()
    alert.show("Email not valid")
    dispatch({payload: {loadingBarProgress: 0}})
    return;
  } else if(state.password.length > 0 && state.password.length < 6) {
    alert.removeAll()
    alert.show("Please input longer password")
    dispatch({payload: {loadingBarProgress: 0}})
    return;
  } else if(state.name.length > 0 && state.name.length < 2) {
    alert.removeAll()
    alert.show("Please input your name")
    dispatch({payload: {loadingBarProgress: 0}})
    return;
  }

  const url = `https://api.cloudinary.com/v1_1/${cloudName}/upload`;
  const config = {
    headers: { "X-Requested-With": "XMLHttpRequest" }
  };

  if(state.formData) {
    dispatch({payload: {loadingBarProgress: 20}})
    axios.post(url, state.formData, config)
    .then((res) => {
      dispatch({payload: {loadingBarProgress: 50}})
      // File uploaded successfully
      const profileImage = res.data.secure_url;
      userUpdateMutation({
        variables: { userId: currentUser.id, imageUrl: profileImage, name: state.name.length > 0 && state.name, location: state.location.length > 0 && state.location, email: state.email.length > 0 && state.email},
      }).then((result) => {
        dispatch({payload: {loadingBarProgress: 100}})
        productionConsoleCheck("SUCCESS:", result)
        handleGAEvent("Update User Profile", 'User Updated Profile', 'update', currentUser.email, currentUser)
        window.location.replace('/rate')
      })
      .catch((error) => {
        dispatch({payload: {loadingBarProgress: 0}})
        alert.removeAll()
        alert.show()
        handleGAError('Update Profile Error', currentUser.email, 'User Updated Profile', currentUser)
        productionConsoleCheck("Update error: ", error)
      })
    })
    .catch((err) => {
      dispatch({payload: {loadingBarProgress: 0}})
      handleGAError('Image updated upload error', currentUser.email, 'User Updated Image', currentUser)
      productionConsoleCheck("Image upload error: ", err)
    })
  } else {
    userUpdateMutation({
      variables: { userId: currentUser.id, name: state.name.length > 0 && state.name, location: state.location.length > 0 && state.location, email: state.email.length > 0 && state.email},
    }).then((result) => {
      dispatch({payload: {loadingBarProgress: 100}})
      productionConsoleCheck("SUCCESS:", result)
      handleGAEvent("Update User Profile", 'User Updated Profile', 'update', currentUser.email, currentUser)
      window.location.replace('/rate')
    })
    .catch((error) => {
      dispatch({payload: {loadingBarProgress: 0}})
      alert.removeAll()
      alert.show()
      handleGAError('Update Profile Error', currentUser.email, 'User Updated Profile', currentUser)
      productionConsoleCheck("Sign up error: ", error)
    })
  }


}

export const updateUserArtists = (userUpdateMutation, formattedArtistIds, currentUser, alert, dispatch) => {
  dispatch({payload: {loadingBarProgress: 10}})
  if(formattedArtistIds.length < 3) {
    alert.removeAll()
    alert.show("Please add at least 2!")
    dispatch({payload: {loadingBarProgress: 0}})
    return
  }

  dispatch({payload: {loadingBarProgress: 50}})
  userUpdateMutation({
    variables: {userId: currentUser.id, artistIds: formattedArtistIds}
  })
  .then((result) => {
    productionConsoleCheck("SUCCESS:", result)
    dispatch({payload: {loadingBarProgress: 100}})
    handleGAEvent("Update Favorite Rappers", `User Chose ${formattedArtistIds.length} Rappers`, 'update user', currentUser.email, currentUser)
    window.location.replace('/rate')
  })
  .catch((error) => {
    dispatch({payload: {loadingBarProgress: 0}})
    alert.removeAll()
    alert.show()
    handleGAError('Error when updating user', currentUser.email, `User Chose ${formattedArtistIds.length} Rappers`, currentUser)
    productionConsoleCheck("Error when updating user: ", error)
  })
}

export const updateUserSkills = (userUpdateMutation, formattedSkillIds, currentUser, alert, dispatch) => {
  dispatch({payload: {loadingBarProgress: 10}})
  if(formattedSkillIds.length < 3) {
    alert.removeAll()
    alert.show("Please fill all 3!")
    dispatch({payload: {loadingBarProgress: 0}})
    return
  }

  dispatch({payload: {loadingBarProgress: 50}})
  userUpdateMutation({
    variables: {userId: currentUser.id, skillIds:  formattedSkillIds}
  })
  .then((result) => {
    productionConsoleCheck("SUCCESS:", result)
    dispatch({payload: {loadingBarProgress: 100}})
    handleGAEvent("Update User Skills", `User Chose ${formattedSkillIds.length} Skills`, 'update user', currentUser.email, currentUser)
    window.location.replace('/update-rappers')
  })
  .catch((error) => {
    dispatch({payload: {loadingBarProgress: 0}})
    alert.removeAll()
    alert.show()
    handleGAError('Error when updating user', currentUser.email, `User Chose ${formattedSkillIds.length} Skills`, currentUser)
    productionConsoleCheck("Error when updating user: ", error)
  })
}

const uploadBeforeSignup = (state, dispatch, alert, userSignupMutation, confirm, history, updateCacheAfterAuth, shareUserId) => {
  dispatch({payload: {loadingBarProgress: 10}})
  const url = `https://api.cloudinary.com/v1_1/${cloudName}/upload`;
  const config = {
    headers: { "X-Requested-With": "XMLHttpRequest" }
  };
  axios.post(url, state.formData, config)
  .then(function (res) {
    dispatch({payload: {loadingBarProgress: 50}})
    // File uploaded successfully
    const profileImage = res.data.secure_url;
    userSignupMutation({
      variables: { image: profileImage, name: state.name, location: state.location, email: state.email, password: state.password, userId: shareUserId && shareUserId},
      update: (store, data) => updateCacheAfterAuth(store, data, state)
    }).then((result) => {
      let resultData = result.data
      if(resultData.signup && resultData.signup.token.length == 0 && resultData.signup.user && (resultData.signup.user.email.toLowerCase() == state.email.toLowerCase())) {
        alert.show("Email already in use!")
        dispatch({payload: {loadingBarProgress: 0}})
      } else {
        dispatch({payload: {loadingBarProgress: 100}})
        productionConsoleCheck("SUCCESS:", result)
        handleGAEvent("Sign Up With Profile Image", 'User Signed Up', 'sign up', state.email)
        confirm(result, state, dispatch, history)
      }
    })
    .catch((error) => {
      dispatch({payload: {loadingBarProgress: 0}})
      alert.removeAll()
      alert.show()
      handleGAError('Sign Up Error', state.email, 'User Signed Up')
      productionConsoleCheck("Sign up error: ", error)
    })
  })
  .catch(function (err) {
    dispatch({payload: {loadingBarProgress: 0}})
    handleGAError('Image upload error', state.email, 'User Signed Up')
    productionConsoleCheck("Image upload error: ", err)
  })
}

export const submitAuth = (e, state, alert, dispatch, userLoginMutation, userSignupMutation, confirm, updateCacheAfterAuth, history, shareUserId) => {
  e.preventDefault()
  dispatch({payload: {loadingBarProgress: 10}})
  const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  if (state.name.length < 0 || state.email.length < 0) {
    alert.removeAll()
    alert.show("Pleace input name and password");
    dispatch({payload: {loadingBarProgress: 0}})
    return;
  } else if(reg.test(state.email) === false) {
    alert.removeAll()
    alert.show("Email not valid")
    dispatch({payload: {loadingBarProgress: 0}})
    return;
  } else if(state.password.length < 6) {
    alert.removeAll()
    alert.show("Please input longer password")
    dispatch({payload: {loadingBarProgress: 0}})
    return;
  }

  if(state.login) {
    dispatch({payload: {loadingBarProgress: 50}})
    userLoginMutation({
      variables: { email: state.email, password: state.password},
      update: (store, data) => updateCacheAfterAuth(store, data, state)
    })
    .then((result) => {
      dispatch({payload: {loadingBarProgress: 100}})
      productionConsoleCheck("SUCCESS:", result)
      handleGAEvent("User Log In", 'User logged in', 'log in', state.email)
      confirm(result, state, dispatch, history)
    })
    .catch((error) => {
      dispatch({payload: {loadingBarProgress: 0}})
      alert.removeAll()
      alert.show("Match not found")
      handleGAError('Log In Error', state.email, 'User logged in')
      productionConsoleCheck("Log In Error: ", error)
    })
  } else if(state.formData) {
    dispatch({payload: {loadingBarProgress: 20}})
    uploadBeforeSignup(state, dispatch, alert, userSignupMutation, confirm, history, updateCacheAfterAuth, shareUserId)
  } else {
    dispatch({payload: {loadingBarProgress: 50}})
    userSignupMutation({
      variables: { name: state.name, location: state.location, email: state.email, password: state.password, userId: shareUserId && shareUserId}
    })
    .then((result) => {
      let resultData = result.data
      if(resultData.signup && resultData.signup.token.length == 0 && resultData.signup.user && (resultData.signup.user.email.toLowerCase() == state.email.toLowerCase())) {
        alert.show("Email already in use!")
        dispatch({payload: {loadingBarProgress: 0}})
      } else {
        dispatch({payload: {loadingBarProgress: 100}})
        productionConsoleCheck("SUCCESS:", result)
        handleGAEvent("Sign Up Without Profile Image", 'User Signed Up', 'sign up', state.email)
        confirm(result, state, dispatch, history)
      }
    })
    .catch((error) => {
      dispatch({payload: {loadingBarProgress: 0}})
      alert.removeAll()
      alert.show()
      handleGAError('Sign Up Error', state.email, 'User Signed Up')
      productionConsoleCheck("Sign Up Error: ", error)
    })
  }
}

export const requestReset = (requestResetMutation, state, alert, dispatch) => {
  dispatch({payload: {loadingBarProgress: 10}})

  const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  if (state.email.length < 0) {
    alert.removeAll()
    alert.show("Pleace input email");
    dispatch({payload: {loadingBarProgress: 0}})
    return;
  } else if(reg.test(state.email) === false) {
    alert.removeAll()
    alert.show("Email not valid")
    dispatch({payload: {loadingBarProgress: 0}})
    return;
  }
  dispatch({payload: {loadingBarProgress: 50}})
  requestResetMutation({
    variables: {email: state.email}
  })
  .then((result) => {
    productionConsoleCheck("SUCCESS:", result)
    alert.removeAll()
    alert.show("Reset link sent to email")
    handleGAEvent("User Request Reset", 'User requested reset', 'reset request', state.email)
    dispatch({payload: {name: '', email: '', location: '', password: '', login: false, forgetPassword: false, loadingBarProgress: 100}})
  })
  .catch((error) => {
    dispatch({payload: {loadingBarProgress: 0}})
    alert.removeAll()
    alert.show()
    handleGAError('Request Reset Error', state.email, 'User requested reset')
    productionConsoleCheck("Request Reset Error: ", error)
  })
}

export const resetPassword = (resetPasswordMutation, resetPasswordToken, state, alert, dispatch, confirm, updateCacheAfterAuth, history) => {
  dispatch({payload: {loadingBarProgress: 10}})

  const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  if (state.email.length < 0) {
    alert.removeAll()
    alert.show("Pleace input email");
    dispatch({payload: {loadingBarProgress: 0}})
    return;
  } else if(reg.test(state.email) === false) {
    alert.removeAll()
    alert.show("Email not valid")
    dispatch({payload: {loadingBarProgress: 0}})
    return;
  }
  if (state.password !== state.confirmPassword) {
    alert.removeAll()
    alert.show(`Passwords must match!`);
    dispatch({payload: {loadingBarProgress: 0}})
    return;
  }
  dispatch({payload: {loadingBarProgress: 50}})
  resetPasswordMutation({
    variables: {email: state.email, password: state.password, confirmPassword: state.confirmPassword, resetPasswordToken: resetPasswordToken},
    update: (store, data) => updateCacheAfterAuth(store, data, state)
  })
  .then((result) => {
    dispatch({payload: {loadingBarProgress: 100}})
    productionConsoleCheck("SUCCESS:", result)
    handleGAEvent("User Reset Password", 'User reset password', 'password reset', state.email)
    confirm(result, state, dispatch, history)
  })
  .catch((error) => {
    dispatch({payload: {loadingBarProgress: 0}})
    alert.removeAll()
    alert.show()
    handleGAError('Password Reset Error', state.email, 'User reset password')
    productionConsoleCheck('Password Reset Error: ', error)
  })
}


 export const googleOnSuccess = (response, mutation, state, alert, dispatch, confirm, updateCacheAfterAuth, history) => {

   dispatch({payload: {loadingBarProgress: 10}})

   if(!response.accessToken || !response.profileObj || !response.profileObj.email) {
     alert.removeAll()
     alert.show("Authentication failed")
   }

  dispatch({payload: {loadingBarProgress: 50}})
  mutation({
    variables: { accessToken: response.accessToken, email: response.profileObj.email},
    update: (store, data) => updateCacheAfterAuth(store, data, state)
  })
  .then((result) => {
   dispatch({payload: {loadingBarProgress: 100}})
   productionConsoleCheck("SUCCESS:", result)
   handleGAEvent("User Google Log In", 'User with email logged in through Google', '3rd party log in', state.email)
   confirm(result, state, dispatch, history)
  })

  .catch((error) => {
    dispatch({payload: {loadingBarProgress: 0}})
    alert.removeAll()
    alert.show()
    handleGAError('Google Log In Error', state.email, 'User with email logged in through Google')
    productionConsoleCheck('Google Log In Error: ', error)
  })
}

 export const facebookOnSuccess = (response, mutation, state, alert, dispatch, confirm, updateCacheAfterAuth, history) => {

  dispatch({payload: {loadingBarProgress: 10}})

  if(!response.accessToken || !response.email) {
    alert.removeAll()
    alert.show("Authentication failed")
    dispatch({payload: {loadingBarProgress: 0}})

  }
  dispatch({payload: {loadingBarProgress: 50}})
  mutation({
    variables: { accessToken: response.accessToken, email: response.email},
    update: (store, data) => updateCacheAfterAuth(store, data, state)
  })
  .then((result) => {
   dispatch({payload: {loadingBarProgress: 100}})
   productionConsoleCheck("SUCCESS:", result)
   handleGAEvent("User Facebook Log In", 'User logged in through Facebook', '3rd party log in', state.email)
   confirm(result, state, dispatch, history)
  })
  .catch((error) => {
    dispatch({payload: {loadingBarProgress: 0}})
    alert.removeAll()
    alert.show()
    handleGAError('Facebook Log In Error', state.email, 'User logged in through Facebook')
    productionConsoleCheck('Facebook Log In Error: ', error)
  })
 }

// export const spotifyOnSuccess = (response, mutation, state, alert, dispatch, confirm, updateCacheAfterAuth, history) => {
//    dispatch({payload: {loadingBarProgress: 10}})
//    if(!response.access_token) {
//      alert.removeAll()
//      alert.show("Authentication failed")
//      dispatch({payload: {loadingBarProgress: 0}})
//
//    }
//    dispatch({payload: {loadingBarProgress: 50}})
//    mutation({
//      variables: { accessToken: response.access_token},
//      update: (store, data) => updateCacheAfterAuth(store, data, state)
//    })
//    .then((result) => {
//     dispatch({payload: {loadingBarProgress: 100}})
//     productionConsoleCheck("SUCCESS:", result)
//     confirm(result, state, dispatch, history)
//    })
//    .catch((error) => {
//      dispatch({payload: {loadingBarProgress: 0}})
//      alert.removeAll()
//      alert.show()
//      handleGAError('Spotify Log In Error')
//      productionConsoleCheck('Spotify Log In Error: ', error)
//    })
// }
