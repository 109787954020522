import React, {useReducer} from 'react'
import PropTypes from 'prop-types'

import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';
import Chart from 'react-apexcharts'
import { isMobile } from "react-device-detect";

import { colors } from '../styles/defaultTheme';

const calcChartSize = (state, homepage) => {
  if(state.xSmallScreensize && !homepage) {
    return 200
  } else if(isMobile) {
    return 200
  } else return 260
}

const renderApexChart = (options, series, state, homepage) => {

  return (
    <Chart
      style={{
        margin: "0 auto",
        position: "relative",
        bottom: "0px"
      }}
      type="radar"
      options={options}
      series={series}
      width={calcChartSize(state, homepage)}
      height={calcChartSize(state, homepage)}
    />
  )
}

const formatSkills = (allSkills) => {
  let formattedSkills = []
  allSkills.map((skill, index) => {
     formattedSkills.push(skill.name)
  })
  return formattedSkills;
}


const formatSeries = (artistOneRatings, artistTwoRatings) => {
  const series = [{
    name: 'Yours',
    data: artistOneRatings,
  }, {
    name: 'Community',
    data: artistTwoRatings,
  }, ]

  return series
}

const formatOptions = () => {
  const options = {
    chart: {
      type: 'radar',
      dropShadow: {
        enabled: true,
        blur: 5,
        left: 5,
        top: 5
      },
      toolbar: {
        show: false
      }
    },
    stroke: {
      width: 2,
      curve: 'smooth',
    },
    fill: {
      opacity: .5
    },
    colors:[colors.carti, colors.frankOcean],
    markers: {
      size: 1
    },
    xaxis: {
      categories: ['Flow', 'Melodies', 'Originality', 'Ear',
      'Storytelling', 'Adlibs', 'Delivery', 'Wordplay', 'Influence', 'Catalog'],
      labels: {
       show: false,
       style: {
         colors: ["transparent", "transparent", "transparent", "transparent", "transparent", "transparent", "transparent", "transparent", "transparent", "transparent"],
         fontSize: "17px",
         fontFamily: 'Avenir-Roman',
         fontWeight: "500",
       }
     },
    },
    yaxis: {
      show: false,
      tickAmount: 10,
    },
    legend: {
      show: false
    },
    plotOptions: {
     radar: {
       polygons: {
         strokeColors: '#f1e3ce1a',
         fill: {
             colors: ['#f1e3ce1a', '#f1e3ce1a']
         }
       }
     }
   }
  }

  return options
}

const formatRatings = (skillAverages, allSkills) => {
  let numbers = []

  for(let i = 0; i < skillAverages.length; i++) {

    if(skillAverages[i] && skillAverages[i].average) {
      numbers.push(skillAverages[i].average)
    } else {
      numbers.push(0)
    }
  }

  return numbers
}

const renderDataSection = (originalArtist, compareArtist, allSkills, state, homepage) => {
  return (
    <div className="radar-chart__wrapper">
      {renderApexChart(formatOptions(), formatSeries(formatRatings(originalArtist.skillAverages), formatRatings(compareArtist.skillAverages)), state, homepage)}
      <div style={{position: "absolute", left: "50%", top: "0"}}>
        {renderNumbers(originalArtist, compareArtist, allSkills)}
      </div>
    </div>
  )
}


const renderNumbers = (originalArtist, compareArtist, allSkills) => {
  const ratingMatchups = structureMatchups(originalArtist, compareArtist, allSkills)

  return ratingMatchups.map((matchup, index) => {
    return (
      <div key={index} className={`rating-${index + 1} radar-chart__ratings-wrapper`}>
        <p className="radar-chart__ratings-text">
          <span className="radar-chart__rating original">{Math.round(matchup.originalArtistRating * 10)}</span>
          |
          <span className="radar-chart__rating comparison">{Math.round(matchup.compareArtistRating * 10)}</span>
        </p>
        <p className="radar-chart__rating-skill">{matchup.skill}</p>
      </div>
    )
  })
}

const structureMatchups = (originalArtist, compareArtist, allSkills) => {
  const matchups = []
  for(let i = 0; i < allSkills.length; i++) {
    const category = allSkills[i]
    const originalArtistRating = originalArtist.skillAverages.filter(averageObj => averageObj.skill.name === category)
    const compareArtistRating = compareArtist.skillAverages.filter(averageObj => averageObj.skill.name === category)

    let matchup = {}

    if(compareArtistRating.length > 0 && originalArtistRating.length > 0) {
      matchups.push({skill: category, originalArtistRating: originalArtistRating[0].average, compareArtistRating: compareArtistRating[0].average})
    }
  }
  return matchups
}


const RadarGraph = ({
  originalArtist,
  compareArtist,
  allSkills,
  state,
  homepage
}) => {
  return renderDataSection(originalArtist, compareArtist, formatSkills(allSkills), state, homepage)
}


export default RadarGraph;
