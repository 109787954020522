import React, { createRef, useCallback, useState, useEffect  } from 'react'
import { useMutation } from 'react-apollo-hooks';
import ReactGA from 'react-ga';

import Button from '../components/Button';
import Icon from '../components/Icon/Icon';
import useLongPress from "../components/useLongPress";

import rapchrLogo from '../images/BH_Filled_Secondary_Logo.png'
import fullLogo from '../images/full-logo.svg'
import cornerPiece from '../images/corner-piece.svg'
import loadingIcon from '../images/load-disk.png';
import flipIcon from '../images/flip.svg';


import { colors } from '../styles/defaultTheme';
import { formatUserName, productionConsoleCheck } from '../utils'

import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import { useScreenshot } from 'use-react-screenshot'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import useOnclickOutside from "react-cool-onclickoutside";
import { toPng, toJpeg } from 'html-to-image';
import { useAlert } from 'react-alert'

import {
  UPDATE_USER_SHARES_MUTATION
} from '../api/singleArtist'

import {
  userUpdateShares
} from '../mutations/singleArtist'


const printDocument = (userUpdateMutation, listTitle, currentUser, setImageDownloading) => {
  setImageDownloading(true)
  currentUser && userUpdateShares(userUpdateMutation, currentUser, "Download List Graphic", `Downloaded List Graphic`, "list share", true)
  const input = document.getElementById('divToPrint');
  domtoimage.toBlob(input)
  .then(function (blob) {
    setImageDownloading(false)
    saveAs(blob, "Rapchr_" + listTitle.split(" ").join("_") + '.png');
  })
  .catch(function (error) {
    setImageDownloading(false)
    productionConsoleCheck('oops, something went wrong!', error)
  });

}

const renderSkillRatings = (skillRatings, isMobile) => {

  return skillRatings.map((skillRating, index) => {
    return (
      <div key={index} className={`list-graphic__rating-wrapper${index === 0 ? " top-skill" : "" }`}>
        <p className="list-graphic__skill-name">{skillRating.skillName}</p>
        <p className="list-graphic__skill-rating">{skillRating.rating}</p>
        <CircularProgressbar
          style={{fontFamily: "Comfortaa"}}
          value={Math.round(skillRating.rating * 10)}
          strokeWidth={5}
          counterClockwise
        />
      </div>
    )
  })
}

const renderArtists = (artists, isMobile) => {
  return artists.map((artist, index) => {

    let artistUserRatings = artist.currentUserRatings;
    let sortedArtistSkillAverages = artistUserRatings ? artistUserRatings.sort((function(a, b) { return b.rating - a.rating;})) : []
    let topRatedSkills = sortedArtistSkillAverages ? sortedArtistSkillAverages.slice(0, 3) : []

    return (
      <div key={index} className="list-graphic__artist">
        <div
          style={{backgroundImage: `url(${artist.image})`}}
          className="list-graphic__artist__image"
        >
          <p className={`list-graphic__artist__name${artist.name.length > 13 ? " large" : ""}${isMobile ? " graphic-mobile" : ""}`}>{artist.name}</p>
        </div>
        <div className="list-graphic__artist__bg"></div>
        <div className="list-graphic__artist__bg__filled"></div>
        {artistUserRatings && <div className="list-graphic__skill-ratings">
          {renderSkillRatings(topRatedSkills, isMobile)}
        </div>}
      </div>
    )
  })
}

const handleGAEvent = (category, action, label, currentUser) => {
  if(process.env.REACT_APP_PRODUCTION) {
    ReactGA.event({
      userId: currentUser ? currentUser.email : "guest",
      category: category,
      action: action,
      label: label
    })
  }
}

const ListGraphic = ({
  artists,
  renderListGraphic,
  setRenderListGraphic,
  listTitle,
  currentUser,
  isMobile,
  smallScreensize
}) => {

  const [userUpdateMutation] = useMutation(UPDATE_USER_SHARES_MUTATION)
  const [image, takeScreenshot] = useScreenshot()
  const [graphicImage, setGraphicImage] = useState("")
  const [imageDownloading, setImageDownloading] = useState(false)

  const alert = useAlert();

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method

    // if(!noUser && !ratingGroupId && !showScreenshot && !appState.renderShareModal) {
    //   handleGraphicClose(artistSlug, dispatch, history, ratingGroupId)
    // }
  });


  const graphicRef = createRef(null)

  const getImage = () => {
    takeScreenshot(ref.current)
  }

  const defaultOptions = {
        shouldPreventDefault: true,
        delay: 100,
    };

  const graphicAction = useCallback(() => {
    const graphic = document.getElementById('divToPrint')
    if (graphicRef.current === null) {
      return
    }

    currentUser && userUpdateShares(userUpdateMutation, currentUser, "Download List Graphic", `Downloaded List Graphic`, "list share", true)
    setImageDownloading(true)
    toPng(graphic, { cacheBust: true, })
      .then((dataUrl) => {
        const link = document.createElement('a')
        const fileName = listTitle.split(" ").join("_")
        link.download = fileName
        link.href = dataUrl
        link.click()
        setImageDownloading(false)
      })
      .catch((err) => {
        alert.show("Error, please try again later.")
        setImageDownloading(false)
        productionConsoleCheck('Error with download!', err)
      })


  }, [graphicRef])

  return (
    <div
      className={`list-graphic__wrapper${isMobile ? " graphic-mobile" : ""}`}
    >
      <Icon
        width="20px"
        height="20px"
        color={"#fff"}
        type="close"
        handleclick={() => setRenderListGraphic(false)}
        customClass="list-graphic__close-icon"
      />
      {imageDownloading && <div className="loading__wrapper download-loading">
        <img className="loading__icon download-loading" alt="loading" src={loadingIcon} />
      </div>}
      {renderListGraphic && <div className="list-graphic__flip-wrapper">
        <p className="list-graphic__flip-msg">Flip device to view!</p>
        <p className="list-graphic__flip-sub-msg">Download on desktop</p>
        <img className="list-graphic__flip-icon" alt="flip" src={flipIcon} />
      </div>}
      <div
        className="list-graphic__graphic-wrapper"
      >
        <div ref={graphicRef}  id="divToPrint" className="list-graphic__container">
          <img className="list-graphic__corner-piece top-left" alt="corner piece" src={cornerPiece} />
          <img className="list-graphic__corner-piece top-right" alt="corner piece" src={cornerPiece} />
          <div className={`list-graphic__top-area${isMobile ? " graphic-mobile" : ""}`}>
            <h2 className={`list-graphic__user-name${isMobile ? " graphic-mobile" : ""}`}>My</h2>
            <img className={`list-graphic__logo${isMobile ? " graphic-mobile" : ""}`} alt="Rapchr" src={fullLogo} />
            <h2 className={`list-graphic__list-title${isMobile ? " graphic-mobile" : ""}`}>{listTitle}</h2>
          </div>
          <div className="list-graphic__artists__wrapper">
            {renderArtists(artists, isMobile)}
          </div>
        </div>

        {!smallScreensize && !isMobile && <div className="list-graphic__ctas">
          <Button
            handleclick={() => printDocument(userUpdateMutation, listTitle, currentUser, setImageDownloading)}
            // handleclick={() => getImage()}
            customClass="list-graphic__download-btn"
            gradient={true}
          >
            Download
          </Button>
        </div>}
      </div>
    </div>
  )
}


export default ListGraphic
