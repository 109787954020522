import React, { useReducer, useEffect } from 'react';
import { useQuery, useMutation } from 'react-apollo-hooks';

import {
  UPDATE_USER_MUTATION,
  USERS_CONNECTION_QUERY
} from '../api/homepage'

import {
  updateUserSkills
} from '../mutations/homepage'

import { handleArtistClick } from '../utils'


import Header from '../components/Header'
import TopSkillStep from '../components/TopSkillStep';
import RecommendedUser from '../components/RecommendedUser';
import Footer from '../components/Footer'
import ErrorPage from '../components/ErrorPage'

import userMsgIconDown from '../images/carrot-down.svg';
import userMsgIconUp from '../images/carrot-up.svg';
import hompeageMessage from '../images/best-skill-home-msg.svg';
import skillStepMessage from '../images/favorite-artists-msg.svg';
import mobileSkillStepMessage from '../images/other-users-skills-mobile.svg';
import loadingIcon from '../images/load-disk.png';
import { colors } from '../styles/defaultTheme';

import WindowSizeListener from 'react-window-size-listener'
import LoadingBar from 'react-top-loading-bar';
import { useAlert } from 'react-alert'

const handleUserScroll = (e, fetchMore, data, state, dispatch, setLoadingMore) => {
  const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
  if (bottom) {

    if(setLoadingMore) {
      setLoadingMore(true)
    } else {
      dispatch({payload: {loading: true}})
    }

    fetchMore({
      variables: {
        first: 20,
        cursor: data.usersConnection.pageInfo.endCursor
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult.usersConnection.edges;
        const pageInfo = fetchMoreResult.usersConnection.pageInfo;

        if(setLoadingMore) {
          setLoadingMore(false)
        } else {
          dispatch({payload: {loading: false}})
        }
        return newEdges.length
        ? {
          usersConnection: {
            __typename: prevResult.usersConnection.__typename,
            edges: [...prevResult.usersConnection.edges, ...newEdges],
            pageInfo
          }
        }
        : prevResult
      }
    })
  }
}

const onResize = (windowSize, state, dispatch) => {

  if(windowSize.windowWidth < "900" && !state.mediumScreensize) {
    dispatch({payload: {mediumScreensize: true, showUsers: false}})
  } else if(windowSize.windowWidth > "900" && state.mediumScreensize) {
    dispatch({payload: {mediumScreensize: false, showUsers: false}})
  }
  if(windowSize.windowWidth < "768" && !state.smallScreensize) {
    dispatch({payload: {smallScreensize: true, showUsers: false}})
  } else if(windowSize.windowWidth > "768" && state.smallScreensize) {
    dispatch({payload: {smallScreensize: false, showUsers: false}})
  }
}

const onLoaderFinished = (dispatch, alert) => {
  dispatch({payload: {loadingBarProgress: 0}})
}


const reducer = (state, action) => {
  switch (action.type) {
    default:
      return { ...state, ...action.payload };
  }
};

const initialState = () => ({
  error: false,
  skillIds: [],
  showUsers: false,
  smallScreensize: window.innerWidth <= 768,
  mediumScreensize: window.innerWidth <= 900,
  redirect: false,
  favArtistStep: false,
  loading: false
})

const UpdateSkills = ({
  currentUser,
  allSkills,
  match
}) => {

  const [userUpdateMutation] = useMutation(UPDATE_USER_MUTATION)
  const [state, dispatch] = useReducer(reducer, initialState())

  const alert = useAlert();

  const {
    data: recentUsers,
    loading: recentUsersLoading,
    error: recentUsersError,
    fetchMore
  } = useQuery(USERS_CONNECTION_QUERY, {
    variables: {
      first: 20,
    }
  })

  if(currentUser) {
    return (
      <div className="page">
        <LoadingBar
           progress={state.loadingBarProgress}
           height={state.loadingBarProgress === 0 ? 0 : 3}
           color={colors.carti} // not working for some reason, in index.css
           onLoaderFinished={() => onLoaderFinished(dispatch, alert)}
         />
        <WindowSizeListener onResize={(windowSize) => onResize(windowSize, state, dispatch)}/>
        <Header
          customClass={state.smallScreensize && state.showUsers && "blur"}
          allSkills={allSkills}
          currentUser={currentUser}
          smallScreensize={state.smallScreensize}
          onboarding={currentUser && (!currentUser.onboardedArtists || !currentUser.onboardedSkills)}
        />
        <div className="homepage__wrapper">
          <div style={{width: recentUsersError && "100%"}} className={`skill-step__left-hand${state.smallScreensize && state.showUsers ? " blur" : ""}`}>
            <TopSkillStep
              disableButtons={state.loadingBarProgress > 0}
              setShowUsers={() => dispatch({payload: {showUsers: !state.showUsers}})}
              smallScreensize={state.smallScreensize}
              skipPage={() => window.location.replace('/update-rappers')}
              updateUserSkills={(formattedSkillIds) => updateUserSkills(userUpdateMutation, formattedSkillIds, currentUser, alert, dispatch)}
              allSkills={allSkills}
              showUsers={state.showUsers}
              currentUser={currentUser}
              recentUsersLoading={recentUsersLoading}
              recentUsersError={recentUsersError}
            />
          </div>
          <img style={{display: recentUsersError && "none"}} src={skillStepMessage} className="skill-step__middle-msg" alt="Other users like" />

          {((state.smallScreensize && state.showUsers) || !state.smallScreensize) &&
          <div
            onScroll={(e) => handleUserScroll(e, fetchMore, recentUsers, state, dispatch)}
            style={{display: recentUsersError && "none"}}
            className="skill-step__right-hand"
          >
            <div onClick={() => dispatch({payload: {showUsers: !state.showUsers}})} className="skill-step__mobile-users__cta__top">
              <img src={mobileSkillStepMessage} className="skill-step__msg-mobile" alt="Other user's favorite skills" />
              <img alt={state.showUsers ? "close" : "open"} src={state.showUsers ? userMsgIconDown : userMsgIconUp} className="skill-step__msg-icon" />
            </div>
            <div className="skill-step__top-gradient"></div>
            <div
              className={`skill-step__users${recentUsersLoading || recentUsersError ? " users-loading" : ""}`}
            >
              {recentUsersError &&
                <div className="loading__wrapper">
                  <p>Error Loading Users</p>
                </div>
              }
              {recentUsersLoading &&
                <div className="loading__wrapper">
                  <img className="loading__icon" alt="loading" src={loadingIcon} />
                </div>
              }
              {recentUsers && recentUsers.usersConnection && recentUsers.usersConnection.edges.map((user, index) => {
                return (
                  <RecommendedUser
                    key={index}
                    user={user.node}
                    skills={true}
                    index={index}
                    currentUser={currentUser}
                    recentUsersLoading={recentUsersLoading}
                    onboarding={currentUser && (!currentUser.onboardedArtists || !currentUser.onboardedSkills)}
                    updatePage={true}
                    customClass={state.loading ? " loading-more" : ""}
                  />
                )
              })}
            </div>
            {state.loading && <div className="loading__wrapper user-profile__loading-wrapper right-hand">
              <img className="loading__icon" alt="loading" src={loadingIcon} />
            </div>}
            <div className="skill-step__bottom-gradient"></div>
            <div className="bump"></div>
          </div>}
        </div>
        <Footer />
      </div>
    )
  } else {
    return (
      <ErrorPage />
    )
  }
}


export default UpdateSkills;
