import React, { createRef, useCallback, useState, useEffect } from 'react'
import { useMutation, useQuery } from 'react-apollo-hooks';
import ReactGA from 'react-ga';

import Button from '../components/Button';
import Icon from '../components/Icon/Icon';
import useLongPress from "../components/useLongPress";
import GraphicImage from "../components/GraphicImage";

import rapchrLogo from '../images/BH_Filled_Secondary_Logo.svg'
import fullLogo from '../images/full-logo.svg'
import loadingIcon from '../images/load-disk.png';
import copyIcon from '../images/copy-icon.svg';
import { colors } from '../styles/defaultTheme';
import { formatUserName, productionConsoleCheck } from '../utils'

import { useAlert } from 'react-alert'
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon
} from "react-share";
import { useScreenshot } from 'use-react-screenshot'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Switch from "react-switch";
import useOnclickOutside from "react-cool-onclickoutside";
import { toPng, toJpeg } from 'html-to-image';

import {
  USER_RATING_QUERY,
  UPDATE_USER_SHARES_MUTATION,
  GRAPHIC_REC_QUERY
} from '../api/singleArtist'

import {
  userUpdateShares
} from '../mutations/singleArtist'


const abbreviateNumber = (num, fixed) => {
  if (num === null) { return null; } // terminate early
  if (num === 0) { return '0'; } // terminate early
  fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show
  var b = (num).toPrecision(2).split("e"), // get power
      k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
      c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3) ).toFixed(1 + fixed), // divide by power
      d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
      e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power
  return e;
}

const formatSocialTitle = (artist, currentUserRatings, state, userRated) => {
  if(state.userListChoice && !userRated) {
    return `${artist.name} ratings on @rapchr`
  }
  const ratings = currentUserRatings.ratings;
  const first = ratings[0]
  const second = ratings[1]
  const third = ratings[2]

  return `${first.skillName}: ${first.rating}\n${second.skillName}: ${third.rating}\n${third.skillName}: ${second.rating}...\nMy ${artist.name} ratings on @rapchr`
}

const renderActionItems = (
  state,
  currentUser,
  artist,
  currentUserRatings,
  alert,
  showVotes,
  setShowVotes,
  ratingGroupId,
  userRated,
  onSwitchToggle,
  onVoteClick,
  userUpdateMutation,
  isMobile,
  graphicAction,
  profilePage
) => {

  return (
    <div className={`rating-graphic__action-items`}>
      <div className={`rating-graphic__voting-section${!userRated ? " no-user" : ""}`}>
        {userRated && <label className={`rating-graphic__switch-wrapper ${isMobile ? " screenshot" : ""}`}>
          {currentUser && currentUser.userRatingCount < 4 && <span className="rating-graphic__switch-msg">{showVotes ? "Voting Enabled" : "Voting Disabled"}</span>}
          <Switch
            onChange={onSwitchToggle ? () => onSwitchToggle(!showVotes, setShowVotes, currentUserRatings) : () => {}}
            checked={showVotes}
            className={`rating-graphic__switch${showVotes ? " on" : " off"}`}
          />
        </label>}
        {showVotes && <div className={`rating-graphic__post-arrows${(!userRated) ? " no-user" : ""}`}>
          <Icon
            type='up-arrow'
            handleclick={(!currentUser || state.voting) && onVoteClick ? () => {} : (e) => onVoteClick(e, currentUserRatings, true)}
            color={currentUserRatings.currentUserVote && currentUserRatings.currentUserVote.positive ? "url(#paint0_linear)" : colors.beachHouseLight}
            width="17px"
            height="17px"
            customClass={`rating-graphic__up-arrow${!currentUser || state.voting ? " no-user" : ""}`}
          />
          {renderPostVotes(currentUserRatings, state)}
          <Icon
            type='down-arrow'
            handleclick={(!currentUser || state.voting) && onVoteClick ? () => {} : (e) => onVoteClick(e, currentUserRatings, false)}
            color={currentUserRatings.currentUserVote && currentUserRatings.currentUserVote.positive !== null && !currentUserRatings.currentUserVote.positive ? "url(#paint0_linear)" : colors.beachHouseLight}
            width="18px"
            height="18px"
            customClass={`rating-graphic__down-arrow${!currentUser || state.voting ? " no-user" : ""}`}
          />
        </div>}
      </div>
      {currentUser && currentUserRatings && currentUserRatings.user
        && (currentUserRatings.user.id === currentUser.id) && <div className={`rating-graphic__ctas`}>
        {!isMobile && <Button
          handleclick={() => graphicAction()}
          customClass="rating-graphic__download-btn"
          disabled={state.loadingBarProgress > 0}
          gradient={true}
        >
          Download
        </Button>}
        {!profilePage && <div className={`rating-graphic__social-btns${isMobile ? " screenshot" : ""}`}>
          <FacebookShareButton
            url={window.location.href}
            quote={state.userListChoice && !userRated ? `${artist.name} Rapchr Ratings #rapchr` : `My ${artist.name} Rapchr Ratings #rapchr`}
            imageUrl={artist.image}
            onClick={() => userUpdateShares(userUpdateMutation, currentUser, "Social Rating Graphic Share", `Share ${artist.name} Graphic to Facebook`, "graphic share", false, true)}
          >
            <FacebookIcon size={32}
              round />
          </FacebookShareButton >
          <TwitterShareButton
            url={window.location.href}
            title={formatSocialTitle(artist, currentUserRatings, state, userRated)}
            imageUrl={artist.image}
            onClick={() => userUpdateShares(userUpdateMutation, currentUser, "Social Rating Graphic Share", `Share ${artist.name} Graphic to Twitter`, "graphic share", false, true)}
          >
            <TwitterIcon size={32}
              round />
          </TwitterShareButton >
          <div className="rating-graphic__copy-icon__wrapper">
            <img
              className="rating-graphic__copy-icon__image"
              alt="copy graphic link"
              src={copyIcon}
              onClick={() => {alert.show("Url copied!");navigator.clipboard.writeText(window.location.href)}}
            />
          </div>
        </div>}
      </div>}
    </div>
  )
}


const renderPostVotes = (currentUserRatings, state) => {
  return (
    <p className="rating-graphic__vote-sum">{abbreviateNumber(parseInt(currentUserRatings.voteSum),0)}</p>
  )
}

const handleRecClick = (artist, state, dispatch, history) => {

  dispatch({payload: {
    originalArtist: artist,
    originalArtistId: artist.id,
    userArtistRatings: [],
    rateArtistView: false,
    exludes: [artist.id],
    compareExcludes: [artist.id],
    renderRatingPrompt: false,
    renderRatingGraphic: false,
    userListChoice: null,
    userListModal: false,
    compareListChoice: null
  }})
  history.replace('/rate/rapper/' + artist.slug)
}

const renderRecArtists = (artists, loading, error, state, dispatch, history) => {
  const recArtists = artists && artists.recArtists

  if(recArtists) {
    return (
      <div className="rating-graphic__rec-artists__wrapper">
        {recArtists.map((artist, index) => {
          return (
            <div key={index}
              onClick={() => handleRecClick(artist, state, dispatch, history)}
              className="rating-graphic__rec-artists__artist"
            >
              <div style={{backgroundImage: `url(${artist.smallImage ? artist.smallImage : artist.image})`}} className="rating-graphic__rec-artists__artist__image"></div>
              <p className="rating-graphic__rec-artists__artist__name">
                {artist.name}
              </p>
            </div>
          )
        })}
      </div>
    )
  }
}

const renderName = (name) => {
  if(name.length > 11) {
    return name.substring(0,10) + ".."
  } else if(name.length > 12) {
    return name.split(" ")[0]
  } else {
    return name
  }
}

const dataURItoBlob = (dataURI) => {
  var mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
  var binary = atob(dataURI.split(',')[1]);
  var array = [];
  for (var i = 0; i < binary.length; i++) {
     array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], {type: mime});
}

const handleGraphicClose = (artistSlug, dispatch, history, ratingGroupId, state, profilePage) => {
  dispatch({payload: {renderRatingGraphic: false, userListChoice: null}})
  if(!ratingGroupId && !profilePage) {
    history.push(`/rate/rapper/${state.originalArtist ? state.originalArtist.slug : artistSlug}`)
  }
}

const handleGraphicClick = (e, isMobile, graphicAction) => {
  if(isMobile) {
    graphicAction(true)
  }
}

const renderRatingVariables = (ratingGroupId, userId, userListChoice, artistSlug) => {
  if(userListChoice) {
    return { userId: userListChoice.user.id, ratingGroupId: userListChoice.id }
  } else if(ratingGroupId) {
    return { userId: userId, ratingGroupId: ratingGroupId }
  } else {
    return { slug: artistSlug, userId: userId}
  }
}

const formatUserRated = (userListChoice, ratingData, currentUser) => {
  let userRated = false
  if(userListChoice) {
    userRated = userListChoice.user.id == currentUser.id
  } else {
    userRated = ratingData && ratingData.userArtistRatings.user.id == currentUser.id
  }
  return userRated
}

const RatingGraphic = ({
  state,
  dispatch,
  onVoteClick,
  onSwitchToggle,
  history,
  userId,
  artistSlug,
  currentUser,
  ratingGroupId,
  noUser,
  artistName,
  isMobile,
  appState,
  artistImage,
  artistId,
  userListChoice,
  profilePage,
  postPage
}) => {

  const [image, takeScreenshot] = useScreenshot()
  const [userUpdateMutation] = useMutation(UPDATE_USER_SHARES_MUTATION)
  const [imageDownloading, setImageDownloading] = useState(false)
  profilePage = profilePage || postPage

  const alert = useAlert();

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method

    if(!noUser && !ratingGroupId && appState && !appState.renderShareModal) {
      handleGraphicClose(artistSlug, dispatch, history, ratingGroupId, state, profilePage)
    }
  });

  const graphicRef = createRef(null)

  const graphicAction = useCallback(() => {
    const graphic = document.getElementById('divToPrint')

    if(!isMobile) {
      userUpdateShares(userUpdateMutation, currentUser, "Download Rating Graphic", `Download ${artistName} Graphic`, "graphic share", true)
    }

    if(!isMobile) {
      setImageDownloading(true)
      toPng(graphic, { cacheBust: true, })
        .then((dataUrl) => {
          const link = document.createElement('a')
          const fileName = artistName.split(" ").join("_") + "_" + 'Rapchr_Ratings.png'
          link.download = fileName
          link.href = dataUrl
          link.click()
          setImageDownloading(false)
        })
        .catch((err) => {
          alert.show("Error, please try again later.")
          setImageDownloading(false)
          productionConsoleCheck('Error with download!', err)
        })
    }

  }, [graphicRef])

  const {
    data: ratingData,
    loading: ratingLoading,
    error: ratingError
  } = useQuery(USER_RATING_QUERY, {
   variables: renderRatingVariables(ratingGroupId, userId, userListChoice, artistSlug)
  });

  const {
    data: recArtists,
    loading: recArtistsLoading,
    error: recArtistsError
  } = useQuery(GRAPHIC_REC_QUERY, {
   variables: { artistId: artistId, limit: 6 }
  });

  const [showVotes, setShowVotes] = useState(ratingData && ratingData.userArtistRatings && ratingData.userArtistRatings.votesTurnedOn ? true : false)
  const [pageLoaded, setPageLoaded] = useState(false)


  useEffect(() => {
    if(!ratingLoading && ratingData && ratingData.userArtistRatings && !pageLoaded) {
      setShowVotes(ratingData.userArtistRatings.votesTurnedOn)
      setPageLoaded(true)
    }
    // toDataURL(artistImage)

  }, [ratingData])

  if(ratingLoading) {
    return (
      <div className="rating-graphic__wrapper">
        <Icon
          width="20px"
          height="20px"
          color={"#fff"}
          type="close"
          handleclick={ratingGroupId ? () => dispatch({payload: {renderRatingGraphic: false, userListChoice: null}}) : () => handleGraphicClose(artistSlug, dispatch, history, ratingGroupId, state, profilePage)}
          customClass="rating-graphic__close-icon"
        />
        <div
          className="rating-graphic__graphic-wrapper"
        >
          <div className="loading__wrapper">
            <img className="loading__icon" alt="loading" src={loadingIcon} />
          </div>
        </div>
      </div>
    )
  }

  if(ratingError) {
    return (
      <div  className="rating-graphic__wrapper">
        <Icon
          width="20px"
          height="20px"
          color={"#fff"}
          type="close"
          handleclick={ratingGroupId ? () => dispatch({payload: {renderRatingGraphic: false, userListChoice: null}}) : () => handleGraphicClose(artistSlug, dispatch, history, ratingGroupId, state, profilePage)}
          customClass="rating-graphic__close-icon"
        />
        <div
          className="rating-graphic__graphic-wrapper"
        >
          <div className="loading__wrapper">
            <p>Rating Doesn't Exist!</p>
          </div>
        </div>
      </div>
    )
  }

  const currentUserRatings = ratingData.userArtistRatings;
  let userRated = false
  if(currentUser) {
    userRated = formatUserRated(userListChoice, ratingData, currentUser)
  }
  const artist = ratingData.userArtistRatings.artist;


  return (
    <div className={`rating-graphic__wrapper`}>
      {currentUser && <Icon
        width="20px"
        height="20px"
        color={"#fff"}
        type="close"
        handleclick={() => handleGraphicClose(artist.slug, dispatch, history, ratingGroupId, state, profilePage)}
        customClass={`rating-graphic__close-icon`}
      />}
      {imageDownloading && <div className="loading__wrapper download-loading">
        <img className="loading__icon download-loading" alt="loading" src={loadingIcon} />
      </div>}
      <div
        className={`rating-graphic__graphic-wrapper`}
         ref={ref}
      >
        {isMobile && currentUser && currentUserRatings && userRated && <p className="rating-graphic__download-msg"><span>i</span> Download on desktop</p>}
        <GraphicImage
          artist={artist}
          currentUserRatings={currentUserRatings}
          userUpdateMutation={userUpdateMutation}
          currentUser={currentUser}
          artistName={artistName}
          isMobile={isMobile}
          artistImage={artistImage}
          logo={fullLogo}
        />
        {renderActionItems(state, currentUser, artist, currentUserRatings, alert, showVotes, setShowVotes, ratingGroupId, userRated, onSwitchToggle, onVoteClick, userUpdateMutation, isMobile, graphicAction, profilePage)}
        {state.smallScreensize && recArtists && currentUser && userRated && !profilePage && <p className="rating-graphic__rec-artists__msg">Rate Next</p>}
        {state.smallScreensize && recArtists && currentUser && userRated && renderRecArtists(recArtists, recArtistsLoading, recArtistsError, state, dispatch, history)}
      </div>
    </div>
  )
}


export default RatingGraphic
