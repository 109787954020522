import React from 'react';

const UserIcon = ({
  height,
  width,
  color,
  style,
  handleclick,
  backgroundcolor,
  ...rest
}) => (
 <svg { ...rest } onClick={handleclick} style={style} width={width} height={height}  viewBox="0 0 64 64" ><circle cx="32" cy="28" fill="transparent" r="11"/><path d="M32,9C19.297,9,9,19.297,9,32c0,12.703,10.297,23,23,23s23-10.297,23-23C55,19.297,44.703,9,32,9z M32,38  c-4.971,0-9-4.029-9-9c0-4.971,4.029-9,9-9s9,4.029,9,9C41,33.971,36.971,38,32,38z" fill={color}/><path d="M32,55c5.722,0,10.953-2.095,14.977-5.553C45.434,43.355,39.32,41,32,41s-13.434,2.355-14.977,8.447  C21.047,52.905,26.278,55,32,55z" fill={backgroundcolor}/></svg>

);

export default UserIcon;
