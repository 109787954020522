import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from "react-device-detect";

import Button from './Button'
import Icon from './Icon/Icon'

import { colors } from "../styles/defaultTheme"

const renderMessageModal = (currentSkill, setCurrentSkill, showModal, setShowModal, allSkills, howItWorks, parentDispatch, setTooltipModal) => {

  const currentSkillIndex = allSkills && allSkills.findIndex((dbSkill) => dbSkill.id === currentSkill.id);
  let nextSkill = {}
  if (currentSkillIndex > -1) {
    const nextSkillIndex = currentSkillIndex + 1
    nextSkill = allSkills[nextSkillIndex] ? allSkills[nextSkillIndex] : allSkills[0]
  } else {
    //handle error
  }
  if(showModal && !howItWorks) {
    return (
      <div className="tooltip__modal-wrapper">
        <div className="tooltip__modal-container">
          <Icon
            customClass="tooltip__exit-icon"
            color={isMobile ? colors.beachHouse : colors.beachHouseLight}
            type="close"
            width="18px"
            height="18px"
            handleclick={() => handleSetModal(false, setShowModal, parentDispatch, setTooltipModal)}
            style={{opacity: isMobile && "1"}}
          />
          <p className="tooltip__modal-title">{currentSkill.name}</p>
          <p className="tooltip__modal-message">{currentSkill.description}</p>
          <div className="tooltip__comp-wrapper">
            {renderCategories(currentSkill)}
          </div>
          <Button handleclick={() => setCurrentSkill(nextSkill)} customClass="tooltip__modal-button">Next</Button>
        </div>
      </div>
    )
  } else if(showModal && howItWorks) {
    return (
      <div className="tooltip__modal-wrapper">
        <div className="tooltip__modal-container">
          <Icon
            customClass="tooltip__exit-icon"
            color={isMobile ? colors.beachHouse : colors.beachHouseLight}
            type="close"
            width="18px"
            height="18px"
            handleclick={() => setShowModal(false)}
            style={{opacity: isMobile && "1"}}
          />
          <p className="tooltip__modal-title">
            How It Works
          </p>
          {renderHIWMessage(true)}
        </div>
      </div>
    )
  }
}

const renderCategories = (skill) => {
  return skill.categories && skill.categories.map((category, index) => {
    return <p className="tooltip__category" key={index}>{category}</p>
  })
}

const renderHIWMessage = (modal) => {
  return (
    <div>
      <ul className={`tooltip__message hiw${modal ? " modal" : ""}`}>
        <li>
        These averages represent the community's current thoughts on each rapper.
        </li>
        <li>Your newly submitted ratings for each artist replace your old ratings in the averages.
        </li>
        <li>
          Compare any rapper's skill sets to one another and learn what the community thinks they're great at.
        </li>
        <li>
          All of your previous ratings can be viewed in your profile.
        </li>
      </ul>
      <p className={`tooltip__message center hiw${modal ? " modal" : ""}`}>Expect many more features like this specific to hip-hop on the Rapchr app!</p>
    </div>
  )
}

const handleTipClick = (skill, setShowModal, showModal, setCurrentSkill, parentDispatch, setTooltipModal) => {
  setShowModal(!showModal);
  if(parentDispatch) {
    parentDispatch({payload: {tooltipModal: !showModal}})
  } else if(setTooltipModal) {
    setTooltipModal(!showModal)
  }
  if(skill) {
    setCurrentSkill(skill)
  }
}

const handleSetModal = (showModal, setShowModal, parentDispatch, setTooltipModal) => {
  setShowModal(showModal)
  if(parentDispatch) {
    parentDispatch({payload: {tooltipModal: showModal}})
  } else if(setTooltipModal) {
    setTooltipModal(showModal)
  }
}

const Tooltip = ({
  handleclick,
  style,
  customClass,
  smallScreensize,
  skill,
  allSkills,
  profile,
  howItWorks,
  parentDispatch,
  setTooltipModal
}) => {
  const [showMessage, setShowMessage] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentSkill, setCurrentSkill] = useState(skill)

  return (
    <div
      onClick={handleclick}
      className={customClass ? `${customClass} tooltip__wrapper` : "tooltip__wrapper"}
    >
      {renderMessageModal(currentSkill, setCurrentSkill, showModal, setShowModal, allSkills, howItWorks, parentDispatch, setTooltipModal)}
      <p
        style={style}
        onClick={smallScreensize ? () => handleSetModal(!showModal, setShowModal, parentDispatch, setTooltipModal) : () => {}}
        className={`tooltip__icon${profile ? " profile" : ""}`}
        onMouseEnter={!smallScreensize ? () => setShowMessage(true) : () => {}}
        onMouseLeave={!smallScreensize ? () => setShowMessage(false) : () => {}}
        onClick={smallScreensize ? () => handleTipClick(skill, setShowModal, showModal, setCurrentSkill, parentDispatch, setTooltipModal) : () => handleTipClick(skill, setShowMessage, showMessage, setCurrentSkill)}
      >
        ?
      </p>
      {showMessage && !howItWorks && <div className={`tooltip__message-wrapper${!skill.categories || skill.categories.length === 0 ? " no-comps" : ""}`}>
        <p className="tooltip__modal-title">{currentSkill.name}</p>
        <p className="tooltip__message">{skill.description}</p>
        <div className="tooltip__comp-wrapper">
          {renderCategories(skill)}
        </div>
      </div>}
      {showMessage && howItWorks && <div className={`tooltip__message-wrapper`}>
        <p className="tooltip__modal-title hiw">
          How It Works
        </p>
        {renderHIWMessage()}
      </div>}
    </div>
  )
}

export default Tooltip;
