import React, { Component } from 'react'
import Header from './Header'
import Footer from './Footer'

import { Helmet } from "react-helmet";
import WindowSizeListener from 'react-window-size-listener'

class ErrorPage extends Component {

  constructor(props) {
    super(props);
    this.state = { smallScreensize: false};
  }


  onResize(windowSize) {

    if(windowSize.windowWidth < "768" && !this.state.smallScreensize) {
      this.setState({smallScreensize: true})
    } else if(windowSize.windowWidth > "768" && this.state.smallScreensize) {
      this.setState({smallScreensize: false})
    }
  }


  render() {

    const currentUser = this.props.currentUser;

    return (
      <div className="error-page">
        <Helmet>
          <title>{this.props.noMatch ? "Page Not Found" : "Error"} | Rapchr</title>
          <meta property="og:description" content="Rate your favorite rappers! A social platform for music fans to explore and share their taste in hip-hop." />
          <meta name="robots" content="noindex" />
        </Helmet>
        <WindowSizeListener onResize={(windowSize) => this.onResize(windowSize)}/>
        <Header
          currentUser={currentUser}
          onboarding={currentUser && (!currentUser.onboardedArtists || !currentUser.onboardedSkills)}
          smallScreensize={this.state.smallScreensize}
          errorPage={true}
        />
        <div className="error-page__error-wrapper">
          <h4 className="error-page__error-title">404 Error</h4>
          <p className="error-page__error-msg">{this.props.noMatch ? "Page does not exist..." : "Something went wrong.."}</p>
          <a className="error-page__error-msg__link" href="/"><p className="error-page__error-msg">Homepage</p></a>
        </div>
        <Footer />
      </div>
    )
  }
}

export default ErrorPage
